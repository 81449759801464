import { NavigateBefore as NavigateBeforeIcon } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React from "react";
import { Link, matchPath, useLocation } from "react-router-dom";

export default function AppBar() {
  const { pathname } = useLocation();

  const reverseNavigation = [
    {
      path: "/contactFlows/:contactFlowId/callLists/:callListId",
      to: pathname.substr(0, pathname.lastIndexOf("/")),
    },
    {
      path: "/callLists/:callListsId",
      to: "/callLists",
    },
  ];

  const { to } = reverseNavigation.find(({ path }) =>
    matchPath(pathname, { path, exact: true })
  ) || { to: "/contactFlows" };

  return (
    <IconButton
      aria-label="back"
      color="inherit"
      component={Link}
      edge="start"
      to={to}
      size="large"
      sx={{
        mr: 2,
        ...(!matchPath(pathname, {
          path: reverseNavigation.map(({ path }) => path),
          exact: true,
        }) && { display: "none" }),
      }}
    >
      <NavigateBeforeIcon />
    </IconButton>
  );
}
