import {
  Box,
  Button,
  Card,
  CardContent, Grid, Theme, useTheme
} from "@mui/material";
import { Form } from "formik";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { Alert, Formik } from "../../components/formik";
import {
  TextFieldFormik
} from "../../components/inputs";
import { useContactCrm } from "../../hooks";
import {
  ICSSProperties,
  SmsSender
} from "../../types";
import { SmsSendersConfig } from "./SmsSendersRoutes";

const useStyles = (theme: Theme): ICSSProperties => ({
  root: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(5),
  },
  smsTemplatesLoading: {
    padding: theme.spacing(10),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& > div": {
      marginRight: theme.spacing(1),
    },
  },
});

const smsTemplateSchema: yup.Schema<Omit<SmsSender, "id" | "createdAt">> = yup
  .object({
    type: yup.string().matches(/^internal|external$/).required(),
    name: yup.string().required('Required'),
    externalId: yup.string(),
  })
  .required('Required');

const initialStatus = {
  submitted: false,
  errors: null,
};

export default function SmsSendersCreate(): JSX.Element {
  const theme = useTheme();
  const history = useHistory();
  const styles = useStyles(theme);

  // CRM
  const crm = useContactCrm();
  const [redirect, setRedirect] = useState<string>();

  const [savedValues, setSavedValues] = useState<Omit<SmsSender, "id" | "createdAt">>({
    type: 'external',
    name: "",
    externalId: undefined,
  });

  return (
    <Box sx={styles.root}>
      <Formik
        validationSchema={smsTemplateSchema}
        initialStatus={initialStatus}
        initialValues={savedValues}
        onSubmit={async (values, { setStatus }) => {
          setStatus(initialStatus);
          try {
            const res = await crm.createSmsSender(JSON.stringify({
              type: values.type,
              name: values.name,
              externalId: values?.externalId,
            }));
            if (!res) {
              throw new Error("Failed to create SMS Sender")
            }
            setStatus({ submitted: true, error: null });
            setSavedValues(values);
            history.push(SmsSendersConfig.path);
          } catch (err) {
            console.error(err);
            setStatus({ submitted: true, error: err });
          }
        }}
      >
        {({ values }) => (
          <Card>
            <CardContent>
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextFieldFormik label="Name" name="name" required />
                  </Grid>

                  <Grid item xs={6}>
                    <TextFieldFormik label="External ID" name="externalId" required={values.type === 'external'}/>
                  </Grid>


                  <Grid item xs={12}>
                    <Button color="primary" type="submit" variant="contained">
                      Create SMS Sender
                    </Button>
                    <Button href={SmsSendersConfig.path}>
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Form>
              <Alert
                successMessage="SMS Sender created successfully"
                errorMessage="Unable to create SMS Sender"
              />
            </CardContent>
          </Card>
        )}
      </Formik >
    </Box>
  );
};
