import { Close } from "@mui/icons-material";
import { Grid, IconButton } from "@mui/material";
import MuiDialogTitle, {
  DialogTitleProps as MuiDialogTitleProps,
} from "@mui/material/DialogTitle";
import React from "react";

type DialogTitleProps = MuiDialogTitleProps & {
  onClose?: () => void;
};

export default function DialogTitle({
  children,
  onClose: handleClose,
  ...props
}: DialogTitleProps) {
  return (
    <MuiDialogTitle
      component="div"
      {...props}
      sx={{
        margin: 0,
        p: 2,
        ...props.sx,
      }}
    >
      <Grid
        alignItems="center"
        container
        justifyContent="space-between"
        sx={{ width: "100%" }}
      >
        <Grid item xs>
          {children}
        </Grid>
        <Grid item xs="auto">
          {handleClose ? (
            <IconButton
              aria-label="close"
              onClick={handleClose}
              size="large"
              sx={{
                position: "absolute",
                right: (theme) => theme.spacing(1),
                top: (theme) => theme.spacing(1),
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Close />
            </IconButton>
          ) : null}
        </Grid>
      </Grid>
    </MuiDialogTitle>
  );
}
