import { CallList } from "./contact";
import { Module } from "./module";
import { SmsTemplateInstance } from "./sms";

export type ContactFlow = CallProfile | WebformProfile;

type ContactFlowCreateMethod = `${ContactFlowCreateMethods}`;
export enum ContactFlowCreateMethods {
  add = "add",
  replace = "replace",
}

export type ContactFlowItem = CallProfileItem | WebformProfileItem;

export type ContactFlowType = `${ContactFlowTypes}`;
export enum ContactFlowTypes {
  CALL = "CALL",
  WEBFORM = "WEBFORM",
}

export enum RunRates {
  asap = "asap",
  callsPerMinute = "callsPerMinute",
}

type RunRate = `${RunRates}`;

enum Gender {
  male = "male",
  female = "female",
}

export type Voice = {
  gender: Gender;
  id: string;
  languageCode: string;
  languageName: string;
  name: string;
};

export type PhoneNumber = {
  id: string;
  arn: string;
  phoneNumber: string;
  type: string;
  countryCode: string;
  contactFlow?: ContactFlow;
};

export type Queue = {
  id: string;
  arn: string;
  name: string;
  type: string;
};

/**
 * Hours of Operation
 */
export type FromTo = {
  from: string | null;
  to: string | null;
};

export type Hours = {
  sunday?: FromTo;
  monday?: FromTo;
  tuesday?: FromTo;
  wednesday?: FromTo;
  thursday?: FromTo;
  friday?: FromTo;
  saturday?: FromTo;
};

/**
 * Modules
 */
export type Script = Module[];

type InboundContactFlowModules = {
  during: Script;
  after: Script;
};

type InboundContactFlowModulesItem = {
  during: string;
  after: string;
};

export type OutboundContactFlowModules = {
  before: Script;
  during: Script;
  after: Script;
};

export type OutboundContactFlowModulesItem = {
  before: string;
  during: string;
  after: string;
};

/**
 * Call Profile
 */
type CallProfileOrItem<T extends Partial<ContactFlow | ContactFlowItem>> =
  T extends ContactFlow ? CallProfile : CallProfileItem;

export type CallProfile = {
  id: string;
  type?: ContactFlowTypes.CALL;
  name: string;
  description?: string;
  createMethod: ContactFlowCreateMethod;
  hours: Hours;
  runRate: RunRate;
  callsPerMinute?: number;
  sourcePhoneNumber: string;
  voice: Voice;
  voicemailMessage: string;
  outbound: OutboundContactFlowModules;
  inbound: InboundContactFlowModules;
  paused: boolean;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  deleted: boolean;
  deletedAt?: string;
  deletedBy?: string;
};

export type CallProfileItem = Omit<CallProfile, "outbound" | "inbound"> & {
  callLists: { items: CallList[] };
  inbound: OutboundContactFlowModulesItem;
  outbound: InboundContactFlowModulesItem;
};

export function isCallProfile<T extends Partial<ContactFlow | ContactFlowItem>>(
  contactFlow: Partial<ContactFlow | ContactFlowItem>
): contactFlow is CallProfileOrItem<T> {
  return contactFlow.type === ContactFlowTypes.CALL || !contactFlow.type;
}

/**
 * Webform Profile
 */
type WebformProfileOrItem<T extends Partial<ContactFlow | ContactFlowItem>> =
  T extends ContactFlow ? WebformProfile : WebformProfileItem;

export type WebformProfile = {
  id: string;
  type: ContactFlowTypes.WEBFORM;
  name: string;
  hours: Hours;
  description?: string;
  createMethod: ContactFlowCreateMethod;
  website: OutboundContactFlowModules;
  invitationSms?: SmsTemplateInstance;
  confirmationSms?: SmsTemplateInstance;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  deleted: boolean;
  deletedAt?: string;
  deletedBy?: string;
};

export type WebformProfileItem = Omit<WebformProfile, "website"> & {
  callLists: { items: CallList[] };
  website: OutboundContactFlowModulesItem;
};

export function isWebformProfile<
  T extends Partial<ContactFlow | ContactFlowItem>
>(
  contactFlow: Partial<ContactFlow | ContactFlowItem>
): contactFlow is WebformProfileOrItem<T> {
  return contactFlow.type === ContactFlowTypes.WEBFORM;
}