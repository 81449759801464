import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Dialog,
  Grid,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { format } from "date-fns";
import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { StateContext } from "../../../contexts";
import {
  AskModule,
  Ctr,
  isCallProfile,
  Module,
  ModuleTypes,
} from "../../../types";
import { DialogContent, DialogTitle } from "../../dialog";
import { TextField } from "../../inputs";

const PREFIX = "CTRDialog";

const classes = {
  heading: `${PREFIX}-heading`,
  secondaryHeading: `${PREFIX}-secondaryHeading`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.heading}`]: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },

  [`& .${classes.secondaryHeading}`]: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

type CtrDialogProps = {
  ctr?: Ctr;
  onClose: () => void;
  open: boolean;
};

export default function CTRDialog({ ctr, onClose, open }: CtrDialogProps) {
  const { contactFlowId } = useParams<{ contactFlowId: string }>();
  const { contactFlows } = useContext(StateContext);

  const contactFlow = contactFlows.find((contactFlow) => {
    return contactFlow.id === contactFlowId;
  });

  return (
    <StyledDialog maxWidth="md" onClose={onClose} open={open}>
      <DialogTitle onClose={onClose}>
        <Typography variant="h6" gutterBottom component="div">
          Call details
        </Typography>
      </DialogTitle>
      <DialogContent>
        {ctr && (
          <>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography className={classes.heading}>
                  Contact Summary
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  {ctr.Channel && (
                    <Grid item xs={12}>
                      <TextField
                        defaultValue={ctr.Channel}
                        InputProps={{ readOnly: true }}
                        label="Channel"
                        helperText="How the customer reached your contact center."
                      />
                    </Grid>
                  )}
                  <Grid container item spacing={2} xs={12}>
                    {ctr.InitiationTimestamp && (
                      <Grid item xs>
                        <TextField
                          defaultValue={format(
                            new Date(ctr.InitiationTimestamp),
                            "dd/MM/yyyy HH:mm:ss"
                          )}
                          InputProps={{ readOnly: true }}
                          label="Initiation Timestamp"
                          helperText="The date and time this contact was initiated."
                        />
                      </Grid>
                    )}
                    {ctr.DisconnectTimestamp && (
                      <Grid item xs>
                        <TextField
                          defaultValue={format(
                            new Date(ctr.DisconnectTimestamp),
                            "dd/MM/yyyy HH:mm:ss"
                          )}
                          InputProps={{ readOnly: true }}
                          label="Disconnect Timestamp"
                          helperText="The date and time that the customer endpoint disconnected from Amazon Connect."
                        />
                      </Grid>
                    )}
                  </Grid>
                  {ctr.CustomerEndpoint?.Address && (
                    <Grid item xs={12}>
                      <TextField
                        defaultValue={ctr.CustomerEndpoint.Address}
                        InputProps={{ readOnly: true }}
                        label="Customer Endpoint"
                        helperText="The customer endpoint."
                      />
                    </Grid>
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography className={classes.heading}>Connection</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  {ctr.ConnectedToSystemTimestamp && (
                    <Grid item xs={12}>
                      <TextField
                        defaultValue={format(
                          new Date(ctr.ConnectedToSystemTimestamp),
                          "dd/MM/yyyy HH:mm:ss"
                        )}
                        InputProps={{ readOnly: true }}
                        label="Connected To System Timestamp"
                        helperText="The date and time the customer endpoint connected to Amazon Connect."
                      />
                    </Grid>
                  )}
                  {ctr.CustomerEndpoint?.Address && (
                    <Grid item xs={12}>
                      <TextField
                        defaultValue={ctr.CustomerEndpoint.Address}
                        InputProps={{ readOnly: true }}
                        label="Customer Endpoint"
                        helperText="The customer endpoint."
                      />
                    </Grid>
                  )}
                  {ctr.SystemEndpoint?.Address && (
                    <Grid item xs={12}>
                      <TextField
                        defaultValue={ctr.SystemEndpoint.Address}
                        InputProps={{ readOnly: true }}
                        label="System Endpoint"
                        helperText="The system endpoint. For INBOUND, this is the phone number that the customer dialed. For OUTBOUND, this is the caller ID phone number that Amazon Connect used to dial the customer."
                      />
                    </Grid>
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography className={classes.heading}>Attributes</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {(() => {
                  const modules: Module[] =
                    contactFlow && isCallProfile(contactFlow)
                      ? JSON.parse(contactFlow?.outbound.during || "[]")
                      : [];

                  const questionModules: AskModule[] = modules.filter(
                    ({ type }) => type === ModuleTypes.Ask
                  ) as AskModule[];

                  return questionModules.length > 0 ? (
                    questionModules.map(({ attribute }) => (
                      <TextField
                        defaultValue={ctr.Attributes[attribute]}
                        InputProps={{ readOnly: true }}
                        key={attribute}
                        label={attribute}
                      />
                    ))
                  ) : (
                    <Alert severity="info">No contact attributes</Alert>
                  );
                })()}
              </AccordionDetails>
            </Accordion>
            {ctr.Agent && (
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography className={classes.heading}>Agent</Typography>
                  <Typography className={classes.secondaryHeading}>
                    Information about the agent that handled the contact
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid container item spacing={2} xs={12}>
                      {ctr.Agent.Username && (
                        <Grid item xs>
                          <TextField
                            defaultValue={ctr.Agent.Username}
                            InputProps={{ readOnly: true }}
                            label="Username"
                            helperText="The username of the agent."
                          />
                        </Grid>
                      )}
                      {ctr.Agent.RoutingProfile?.Name && (
                        <Grid item xs>
                          <TextField
                            defaultValue={ctr.Agent.RoutingProfile.Name}
                            InputProps={{ readOnly: true }}
                            label="Routing Profile"
                            helperText="The routing profile of the agent."
                          />
                        </Grid>
                      )}
                    </Grid>
                    {ctr.Agent.ConnectedToAgentTimestamp && (
                      <Grid item xs={12}>
                        <TextField
                          defaultValue={format(
                            new Date(ctr.Agent.ConnectedToAgentTimestamp),
                            "dd/MM/yyyy HH:mm:ss"
                          )}
                          InputProps={{ readOnly: true }}
                          label="Connected To Agent Timestamp"
                          helperText="The date and time the contact was connected to the agent."
                        />
                      </Grid>
                    )}
                    {ctr.Agent.AgentInteractionDuration && (
                      <Grid item xs={12}>
                        <TextField
                          defaultValue={ctr.Agent.AgentInteractionDuration}
                          InputProps={{ readOnly: true }}
                          label="Interaction Duration"
                          helperText="The time, in whole seconds, that an agent interacted with a customer."
                        />
                      </Grid>
                    )}
                    <Grid container item spacing={2} xs={12}>
                      {ctr.Agent.NumberOfHolds !== null && (
                        <Grid item xs>
                          <TextField
                            defaultValue={ctr.Agent.NumberOfHolds}
                            InputProps={{ readOnly: true }}
                            label="Number Of Holds"
                            helperText="The number of times the customer was put on hold while connected to the agent."
                          />
                        </Grid>
                      )}
                      {ctr.Agent.CustomerHoldDuration && (
                        <Grid item xs>
                          <TextField
                            defaultValue={ctr.Agent.CustomerHoldDuration}
                            InputProps={{ readOnly: true }}
                            label="Customer Hold Duration"
                            helperText="The time, in whole seconds, that the customer spent on hold while connected to the agent."
                          />
                        </Grid>
                      )}
                      {ctr.Agent.LongestHoldDuration && (
                        <Grid item xs>
                          <TextField
                            defaultValue={ctr.Agent.LongestHoldDuration}
                            InputProps={{ readOnly: true }}
                            label="Longest Hold Duration"
                            helperText="The longest time, in whole seconds, that the customer was put on hold by the agent."
                          />
                        </Grid>
                      )}
                      <Grid container item spacing={2} xs={12}>
                        {ctr.Agent.AfterContactWorkStartTimestamp && (
                          <Grid item xs>
                            <TextField
                              defaultValue={format(
                                new Date(
                                  ctr.Agent.AfterContactWorkStartTimestamp
                                ),
                                "dd/MM/yyyy HH:mm:ss"
                              )}
                              InputProps={{ readOnly: true }}
                              label="After Contact Work Start Timestamp"
                              helperText="The date and time when the agent started doing After Contact Work for the contact."
                            />
                          </Grid>
                        )}
                        {ctr.Agent.AfterContactWorkEndTimestamp && (
                          <Grid item xs>
                            <TextField
                              defaultValue={format(
                                new Date(
                                  ctr.Agent.AfterContactWorkEndTimestamp
                                ),
                                "dd/MM/yyyy HH:mm:ss"
                              )}
                              InputProps={{ readOnly: true }}
                              label="After Contact Work End Timestamp"
                              helperText="The date and time when the agent stopped doing After Contact Work for the contact."
                            />
                          </Grid>
                        )}
                        {ctr.Agent.AfterContactWorkDuration && (
                          <Grid item xs>
                            <TextField
                              defaultValue={ctr.Agent.AfterContactWorkDuration}
                              InputProps={{ readOnly: true }}
                              label="After Contact Work Duration"
                              helperText="The difference in time, in whole seconds, between After Contact Work Start Timestamp and After Contact Work End Timestamp."
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            )}
            {ctr.Queue && (
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography className={classes.heading}>Queue</Typography>
                  <Typography className={classes.secondaryHeading}>
                    If this contact was queued, this is information about the
                    queue
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    {ctr.Queue.DequeueTimestamp && (
                      <Grid item xs={12}>
                        <TextField
                          defaultValue={format(
                            new Date(ctr.Queue.DequeueTimestamp),
                            "dd/MM/yyyy HH:mm:ss"
                          )}
                          InputProps={{ readOnly: true }}
                          label="Dequeue Timestamp"
                          helperText="The date and time the contact was removed from the queue. Either the customer disconnected or the contact was connected to an agent."
                        />
                      </Grid>
                    )}
                    {ctr.Queue.Duration && (
                      <Grid item xs={12}>
                        <TextField
                          defaultValue={ctr.Queue.Duration}
                          InputProps={{ readOnly: true }}
                          label="Duration"
                          helperText="The difference in time, in whole seconds, between Enqueue Timestamp and Dequeue Timestamp."
                        />
                      </Grid>
                    )}
                    {ctr.Queue.EnqueueTimestamp && (
                      <Grid item xs={12}>
                        <TextField
                          defaultValue={format(
                            new Date(ctr.Queue.EnqueueTimestamp),
                            "dd/MM/yyyy HH:mm:ss"
                          )}
                          InputProps={{ readOnly: true }}
                          label="Enqueue Timestamp"
                          helperText="The date and time the contact was added to the queue."
                        />
                      </Grid>
                    )}
                    {ctr.Queue.Name && (
                      <Grid item xs={12}>
                        <TextField
                          defaultValue={ctr.Queue?.Name}
                          InputProps={{ readOnly: true }}
                          label="Name"
                          helperText="The name of the queue."
                        />
                      </Grid>
                    )}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            )}
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography className={classes.heading}>Other</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {ctr.AgentConnectionAttempts !== null && (
                  <Grid item xs={12}>
                    <TextField
                      defaultValue={ctr.AgentConnectionAttempts}
                      InputProps={{ readOnly: true }}
                      label="Agent Connection Attempts"
                      helperText="The number of times Amazon Connect attempted to connect this contact with an agent."
                    />
                  </Grid>
                )}
                {ctr.TransferCompletedTimestamp && (
                  <Grid item xs={12}>
                    <TextField
                      defaultValue={format(
                        new Date(ctr.TransferCompletedTimestamp),
                        "dd/MM/yyyy HH:mm:ss"
                      )}
                      InputProps={{ readOnly: true }}
                      label="Transfer Completed Timestamp"
                      helperText="If this contact was transferred out of Amazon Connect, the date and time the transfer endpoint was connected."
                    />
                  </Grid>
                )}
                {ctr.TransferredToEndpoint?.Address && (
                  <Grid item xs={12}>
                    <TextField
                      defaultValue={ctr.TransferredToEndpoint.Address}
                      InputProps={{ readOnly: true }}
                      label="Transferred To Endpoint"
                      helperText="If this contact was transferred out of Amazon Connect, the transfer endpoint."
                    />
                  </Grid>
                )}
              </AccordionDetails>
            </Accordion>
          </>
        )}
      </DialogContent>
    </StyledDialog>
  );
}
