export type Voicemail = {
  id: string;
  key: string;
  queue?: string;
  timestamp: string;
  customerEndpoint: string;
};

export type PhoneOutcome = `${PhoneOutcomes}`;
export enum PhoneOutcomes {
  callerReached = "callerReached",
  transferredToPhoneNumber = "transferredToPhoneNumber",
  transferredToQueue = "transferredToQueue",
  transferredToVoicemail = "transferredToVoicemail",
  voicemailLeft = "voicemailLeft",
  connected = "connected",
  hungUp = "hungUp",
  engaged = "engaged",
  callGuardian = "callGuardian",
  noAnswer = "noAnswer",
  invalidNumber = "invalidNumber",
  error = "error",
}

export type S3Object = {
  bucket: string;
  key: string;
  region: string;
};

export type Fields = {
  id: string;
  primaryPhone: string;
  secondaryPhone: string;
};

/**
 * Call List
 */
export type CallList = {
  id: string;
  csvFields: string[];
  fields: CallListFields;
  file: S3Object;
  filename: string;
  contactFlowId: string;
  processContacts: boolean;
  warnings: string[];
  errors: string[];
  createdBy: string;
  createdAt: string;
};

type CallListFields = {
  id: string;
  primaryPhone: string;
  secondaryPhone: string;
};

export type CallListItem = Omit<CallList, "errors" | "warnings"> & {
  errors: string;
  warnings: string;
  contacts: { items: Contact[] };
};

/**
 * Contact
 */
export type Attributes = {
  [name: string]: string;
};

export type PhoneStatus = `${PhoneStatuses}`;
export enum PhoneStatuses {
  callMade = "callMade",
  callingNow = "callingNow",
  callPending = "callPending",
  invalidNumber = "invalidNumber",
  error = "error",
}

export type Contact = {
  id: string;
  callListId: string;
  contactFlowId: string;
  evaluateAfter?: string;
  fileId: string;
  primaryCtr?: Ctr;
  primaryError?: string;
  primaryOutcome?: PhoneOutcome;
  primaryPhone?: string;
  primaryStatus?: PhoneStatus;
  primaryVoicemail?: Voicemail;
  secondaryCtr?: Ctr;
  secondaryError?: string;
  secondaryOutcome?: PhoneOutcome;
  secondaryPhone?: string;
  secondaryStatus?: PhoneStatus;
  secondaryVoicemail?: Voicemail;
  otherAttributes: Attributes;
  ctr?: Ctr;
  voicemail?: Voicemail;
};

export type ContactItem = Omit<
  Contact,
  "primaryCtr" | "secondaryCtr" | "ctr"
> & {
  primaryCtr: string;
  secondaryCtr: string;
  ctr: string;
};

export type Ctr = {
  Agent: CtrAgent;
  AgentConnectionAttempts: number;
  Attributes: Attributes;
  Channel: string;
  ConnectedToSystemTimestamp: string;
  CustomerEndpoint: CtrEndpoint;
  DisconnectTimestamp: string;
  InitiationTimestamp: string;
  Queue: CtrQueue;
  SystemEndpoint: CtrEndpoint;
  TransferCompletedTimestamp: string;
  TransferredToEndpoint: CtrEndpoint;
};

type CtrAgent = {
  AfterContactWorkDuration: string;
  AfterContactWorkEndTimestamp: string;
  AfterContactWorkStartTimestamp: string;
  AgentInteractionDuration: number;
  ConnectedToAgentTimestamp: string;
  CustomerHoldDuration: number;
  LongestHoldDuration: number;
  NumberOfHolds: number;
  RoutingProfile: CtrAgentRoutingProfile;
  Username: string;
};

type CtrAgentRoutingProfile = {
  Name: string;
};

type CtrEndpoint = {
  Address: string;
};

type CtrQueue = {
  DequeueTimestamp: string;
  Duration: number;
  EnqueueTimestamp: string;
  Name: string;
};