import {
  Settings as SettingsIcon
} from "@mui/icons-material";
import { Box, Chip, Grid, Theme, useTheme } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { useContactCrm } from "../../hooks";
import { ICSSProperties, SmsSender, SmsTemplate } from "../../types";

const useStyles = (theme: Theme): ICSSProperties => ({
  root: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(5),
  },
  smsTemplatesLoading: {
    padding: theme.spacing(10),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& > div": {
      marginRight: theme.spacing(1),
    },
  },
  smsTemplateLink: {
    fontWeight: 600,
    textDecoration: "none",
    color: theme.palette.text.primary,

    "& > .MuiChipRoot": {
      marginLeft: theme.spacing(1),
      fontSize: "0.7rem",
      borderRadius: "12px",
      height: "20px",

      "& > span": {
        padding: "0 6px",
      },
    },
  },
  tableCellManage: {
    "& .MuiChip-root": {
      marginRight: theme.spacing(1.5),
      "&:last-child": {
        marginRight: 0,
      },
    },
  },
  chipEdit: {
    backgroundColor: theme.palette.grey[800],
    color: "#fff",
    cursor: "pointer",
    "& .MuiChip-icon": {
      color: "#fff",
      marginRight: "-7px",
    },
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
    "& .MuiChip-label": {
      paddingRight: 0,
    }
  },
});

export default function SmsTemplatesPage(): JSX.Element {
  const theme = useTheme()
  const styles = useStyles(theme);

  // CRM
  const crm = useContactCrm();
  const [loadingTemplates, setLoadingTemplates] = useState(true);
  const [loadingSenders, setLoadingSenders] = useState(true);
  const [smsTemplates, setTemplates] = useState<SmsTemplate[]>();
  const [smsSenders, setSenders] = useState<SmsSender[]>();
  const [redirect, setRedirect] = useState<string>();

  useEffect(() => {
    const loadTemplates = async () => {
      if (crm) {
        setLoadingTemplates(true);
        setTemplates(await crm.listSmsTemplates());
        setLoadingTemplates(false);
      }
    };

    loadTemplates();
  }, [crm]);

  useEffect(() => {
    const loadSenders = async () => {
      if (crm) {
        setLoadingSenders(true);
        setSenders(await crm.listSmsSenders());
        setLoadingSenders(false);
      }
    };

    loadSenders();
  }, [crm]);

  const findSenderName = (id: string): string => {
    if (!smsSenders) return 'UNKNOWN'
    const sender = smsSenders.find(sender => sender.id === id)
    return sender?.name || 'UNKNOWN'
  }

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  if (loadingTemplates || loadingSenders) {
    return (
      <Box sx={styles.root}>
        <Box sx={styles.smsTemplatesLoading}>
          <CircularProgress size={20} />
          <span> Loading SMS Templates...</span>
        </Box>
      </Box>
    );
  } else if (!loadingTemplates && Array.isArray(smsTemplates)) {
    return (
      <Box sx={styles.root}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Sender</TableCell>
                    <TableCell>Updated By</TableCell>
                    <TableCell>Last Updated</TableCell>
                    <TableCell align="right">Manage</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {smsTemplates.map((smsTemplate) => (
                    <TableRow
                      key={smsTemplate.id}
                      sx={styles.smsTemplate}
                    >
                      <TableCell component="th" scope="row">
                        <Link
                          to={`/sms-templates/${smsTemplate.id}`}
                          style={styles.smsTemplateLink}
                        >
                          {smsTemplate.name}{" "}
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Link
                          to={`/sms-senders/${smsTemplate.senderId}`}
                          style={styles.smsTemplateLink}
                        >
                          {findSenderName(smsTemplate.senderId)}
                        </Link>
                      </TableCell>
                      <TableCell>{smsTemplate.updatedBy || ""}</TableCell>
                      <TableCell>
                        {smsTemplate.updatedAt ? format(
                          new Date(smsTemplate.updatedAt),
                          "dd/MM/yyyy HH:mm"
                        ) : ""}
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={styles.tableCellManage}
                      >
                        <Chip
                          icon={<SettingsIcon />}
                          sx={styles.chipEdit}
                          component={Link}
                          to={`/sms-templates/${smsTemplate.id}`}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>
    );
  }

  return <></>;
}
