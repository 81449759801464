import {Box, Grid, MenuItem, Theme, Typography, useTheme} from "@mui/material";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { orderBy } from "lodash";
import React, { useEffect, useState } from "react";
import useContactCrm from "../../../hooks/useContactCrm";
import {Campaign, ExportTemplate, ICSSProperties} from "../../../types";

const useStyles = (theme: Theme): ICSSProperties => ({
  root: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(5),
  },

  exportCustomers: {
    border: "1px solid #e3e3e3",
    padding: "20px 30px",
    marginTop: "20px",
    marginBottom: "20px",
    backgroundColor: "#ffffff",
    textAlign: "center",
    borderRadius: "3px",
    position: "relative",
  },

  exportControls: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
  },

  exportTemplateDropdown: {
    width: "300px",
    marginRight: "30px",

    "& .MuiSelect-select": {
      textAlign: "left",
    },
  },

  exportButton: {
    boxShadow: "none",
  },

  exportCustomersLoading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& > div": {
      marginRight: theme.spacing(1),
    },
  },

  exportOverlay: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    backgroundColor: "#ffffff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 10,
    "& > svg": {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
      fontSize: "2.2rem",
      color: "#f70",
    },
  },
});

type CampaignCustomersProps = {
  campaign: Campaign;
};

export default function CampaignCustomers({
  campaign,
}: CampaignCustomersProps) {
  const theme = useTheme()
  const styles = useStyles(theme);

  const campaignId = campaign.id;
  const exportEnabled = Object.keys(campaign.uploads).length > 0;

  // This is the proper check, however we don't know when an upload has completed (async)
  // const exportEnabled = campaign?.uploads ?
  //     Object.values(campaign.uploads).filter(i => i.status === 'COMPLETED').length > 0 :
  //     false;

  const [exportTemplate, setExportTemplate] = useState("default");
  const [loadingExportTemplates, setLoadingExportTemplates] = useState(false);
  const [allExportTemplates, setAllExportTemplates] = useState<
    ExportTemplate[]
  >([]);

  const [exporting, setExporting] = useState(false);
  const [, setExportFile] = useState(null);
  const [exportError, setExportError] = useState(false);

  // CRM
  const crm = useContactCrm();

  const getAllExportTemplates = async () => {
    setLoadingExportTemplates(true);
    const tmpExportTemplates = await crm.listExportTemplates();
    const exportTemplates = orderBy(tmpExportTemplates, ["name"], ["asc"]);
    setAllExportTemplates(exportTemplates);
    setLoadingExportTemplates(false);
  };

  useEffect(() => {
    if (crm && campaignId) {
      getAllExportTemplates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crm, campaignId]);

  const exportCustomers = async () => {
    setExportError(false);
    setExporting(true);
    const exportFile = await crm.getCampaignCustomersDownloadFile(
      campaignId,
      exportTemplate
    );

    if (exportFile?.corruptedRecords) {
      window.alert(`Export excludes ${exportFile.corruptedRecords} records which were corrupted, please contact support`)
    }

    if (exportFile?.url) {
      setExportFile(exportFile);
      window.location.replace(exportFile.url);
    } else {
      setExportError(true);
    }

    setExporting(false);
  };

  const handleExportTemplateChange = (e: SelectChangeEvent<unknown>) => {
    setExportTemplate(e.target.value as string);
  };

  return (
    <Grid item xs={12}>
      <Typography component="h5" variant="h5">
        Customer Exports
      </Typography>

      <Box sx={styles.exportCustomers}>
        {!exportEnabled && (
          <Box sx={styles.exportOverlay}>
            <p>
              Please upload a list of customers
              <br />
              before exporting
            </p>
          </Box>
        )}

        {exporting && (
          <Box sx={styles.exportCustomersLoading}>
            <CircularProgress size={20} />
            <span>Generating file ...</span>
          </Box>
        )}

        {/*{!exporting && exportFile?.url &&*/}
        {/*    <Box>*/}
        {/*        Download has started ...*/}
        {/*    </Box>*/}
        {/*}*/}

        {!exporting && exportError && (
          <Box>An error has occurred, please contact support ...</Box>
        )}

        {!exporting && !exportError && (
          <Box sx={styles.exportControls}>
            <FormControl size={"small"} variant="outlined">
              <InputLabel>Export template</InputLabel>
              <Select
                label="Export template"
                sx={styles.exportTemplateDropdown}
                onChange={handleExportTemplateChange}
                value={exportTemplate}
              >
                {loadingExportTemplates && (
                  <MenuItem value="" disabled>
                    Loading ...
                  </MenuItem>
                )}
                {!loadingExportTemplates && (
                  <MenuItem value={"default"}>Default</MenuItem>
                )}
                {!loadingExportTemplates &&
                  allExportTemplates &&
                  allExportTemplates.map((i) => (
                    <MenuItem
                      value={i.exportTemplateId}
                      key={i.exportTemplateId}
                    >
                      {i.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            <Button
              color="primary"
              variant="contained"
              component="button"
              type="submit"
              size="medium"
              onClick={exportCustomers}
              sx={styles.exportButton}
            >
              Export
            </Button>
          </Box>
        )}
      </Box>
    </Grid>
  );
}
