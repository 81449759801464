import { Grid } from "@mui/material";
import React from "react";
import { Say } from ".";
import {
  ChipFieldFormik,
  TextFieldFormik,
} from "../../../../components/inputs";

type TransferToVoicemailProps = {
  audioPromptArn: string;
  name: string;
  text: string;
};

export default function TransferToVoicemail({
  audioPromptArn,
  name,
  text,
}: TransferToVoicemailProps) {
  return (
    <>
      <Say audioPromptArn={audioPromptArn} name={name} text={text} />
      <Grid item xs>
        <ChipFieldFormik name={`${name}.emailTo`} label="Email to" />
      </Grid>
      <Grid item xs>
        <TextFieldFormik name={`${name}.emailSubject`} label="Email subject" />
      </Grid>
    </>
  );
}
