export type Strategy = {
  id: string;
  name: string;
  description?: string;
  type: StrategyType;
  payload: StrategyPayload;
  updatedWhen: string;
  updatedBy: string;
  deleted?: boolean;
}

type StrategyPayload = {
  name: string;
  config: StrategyPayloadConfig[];
};

export enum StrategyPayloadConfigTypes {
  CallProfile = "CALL_PROFILE",
  WebProfile = "WEB_PROFILE",
}

type StrategyPayloadConfig = {
  name: string;
  label: string;
  type: StrategyPayloadConfigTypes;
};

enum StrategyTypes {
  Static = "static",
  StateMachine = "state-machine",
}

type StrategyType = `${StrategyTypes}`;