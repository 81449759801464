import { AppBar as MuiAppBar, Grid, Toolbar, Typography } from "@mui/material";
import React from "react";
import { matchPath, useLocation } from "react-router-dom";
import { Menu, ReverseNavigation } from ".";
import { drawerWidth } from "../drawer";
import { SpeedDial, SpeedDialSms, SpeedDialWebform, Tabs } from "./tabs";
import { Title } from "./title";

export default function AppBar() {
  const { pathname } = useLocation();
  const isPath = (path: string): boolean => {
    return matchPath(pathname, { path }) !== null;
  }

  const speedDial = () => {
    switch (true) {
      case isPath("/webformProfiles"):
        return <SpeedDialWebform />

      case isPath("/sms-templates"):
      case isPath("/sms-senders"):
        return <SpeedDialSms />

      default:
        return <SpeedDial />
    }
  }

  // contact flows
  const isCampaignsPath = isPath("/campaigns");
  const isExportTemplatesPath = isPath("/export-templates");
  if (isCampaignsPath || isExportTemplatesPath) {
    return (
      <MuiAppBar
        position="fixed"
        color="secondary"
        sx={{
          marginLeft: drawerWidth,
          width: `calc(100% - ${drawerWidth}px)`,
          height: "96px",
          justifyContent: "center",
        }}
      >
        <Toolbar variant="dense">
          <Grid alignItems="center" container>
            <Grid item xs>
              <Typography variant="h6">
                {isCampaignsPath ? "Campaigns" : "Export Templates"}
              </Typography>
            </Grid>
            <Grid item xs="auto">
              <Menu />
            </Grid>
          </Grid>
        </Toolbar>
      </MuiAppBar>
    );
  }

  // default button
  return (
    <MuiAppBar
      position="fixed"
      color="secondary"
      sx={{
        boxShadow:
          matchPath(pathname, { path: "/contactFlows/:contactFlowId" }) &&
          "none",
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
      }}
    >
      <Toolbar variant="dense">
        <Grid alignItems="center" container>
          <Grid item xs="auto">
            <ReverseNavigation />
          </Grid>
          <Grid item xs>
            <Title />
          </Grid>
          <Grid item xs="auto">
            <Menu />
          </Grid>
        </Grid>
      </Toolbar>
      <Tabs>
        {speedDial()}
      </Tabs>
    </MuiAppBar>
  );
}
