import {
  DynamicForm as DynamicFormIcon,
} from "@mui/icons-material";
import {
  SpeedDial as MuiSpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
} from "@mui/material";
import React, { useState } from "react";
import { useFeatures } from "../../../hooks";
import { CreateWebformProfileDialog } from "./create-webform-profile-dialog";

export default function SpeedDial() {
  const features = useFeatures();
  const [openSpeedDial, setOpenSpeedDial] = useState(false);
  const [openCreateWebformProfileDialog, setOpenCreateWebformProfileDialog] =
    useState(false);

  const handleOpenSpeedDial = () => setOpenSpeedDial(true);
  const handleCloseSpeedDial = () => setOpenSpeedDial(false);

  const handleOpenCreateWebformProfileDialog = () =>
    setOpenCreateWebformProfileDialog(true);
  const handleCloseCreateWebformProfileDialog = () =>
    setOpenCreateWebformProfileDialog(false);

  const actions = [
    ...(features.webForms
      ? [
          {
            icon: <DynamicFormIcon />,
            tooltipTitle: "Web profile",
            onClick: handleOpenCreateWebformProfileDialog,
          },
        ]
      : []),
  ];

  return (
    <>
      <MuiSpeedDial
        ariaLabel="SpeedDial"
        direction="down"
        FabProps={{
          color: "primary",
        }}
        icon={<SpeedDialIcon />}
        onClose={handleCloseSpeedDial}
        onOpen={handleOpenSpeedDial}
        open={openSpeedDial}
        sx={{
          position: "absolute",
          top: 20,
          l: 2,
        }}
      >
        {actions.map(({ icon, tooltipTitle, onClick }, index) => (
          <SpeedDialAction
            key={index}
            icon={icon}
            tooltipTitle={tooltipTitle}
            onClick={onClick}
          />
        ))}
      </MuiSpeedDial>
      <CreateWebformProfileDialog
        open={openCreateWebformProfileDialog}
        onClose={handleCloseCreateWebformProfileDialog}
      />
    </>
  );
}
