import { DeleteForever as DeleteForeverIcon } from "@mui/icons-material";
import { Grid, IconButton, MenuItem, Typography } from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";
import { SelectFormik } from "../../../components/inputs";
import {
  isAskModule,
  isAttributeModule,
  isCallExternalApiModule,
  isDecisionModule,
  isEndCallModule,
  isSayModule,
  isSendSMSModule,
  isTransferToPhoneNumberModule,
  isTransferToQueueModule,
  isTransferToVoicemailModule,
  Module,
  ModuleTypes,
} from "../../../types";
import {
  Ask,
  Attribute,
  CallExternalApi,
  Decision,
  EndCall,
  Say,
  SendSMS,
  TransferToPhoneNumber,
  TransferToQueue,
  TransferToVoicemail,
} from "./modules";

type SelectModuleTypeProps = {
  moduleTypes: string[];
  name: string;
  readOnly?: boolean;
};

export function SelectModuleType({
  moduleTypes,
  name,
  readOnly,
}: SelectModuleTypeProps) {
  const readableModuleTypes: {
    [moduleType: string]: string;
  } = {
    Say: "Say",
    Ask: "Ask",
    Decision: "Decision",
    Attribute: "Attribute",
    SendSMS: "Send SMS",
    CallExternalApi: "Call external API",
    TransferToQueue: "Transfer to queue",
    TransferToPhoneNumber: "Transfer to phone number",
    TransferToVoicemail: "Transfer to voicemail",
    EndCall: "End",
  };

  return (
    <SelectFormik name={`${name}.type`} label="Type" inputProps={{ readOnly }}>
      {moduleTypes.map((moduleType, index) => (
        <MenuItem key={index} value={moduleType}>
          {readableModuleTypes[moduleType]}
        </MenuItem>
      ))}
    </SelectFormik>
  );
}

const processDelete = (
  items: Module[],
  prefix: string | null,
  remove: string
) => {
  let index = 1;

  const itemIndex = items.findIndex((x) => x.id === remove);
  if (itemIndex > -1) {
    items.splice(itemIndex, 1);
  }

  for (const item of items) {
    item.id = prefix ? `${prefix}.${index}` : `${index}`;
    item.type === ModuleTypes.Decision &&
      processDelete(item.modules, item.id, remove);
    index++;
  }
};

type ContactFlowModulesFormInnerProps = {
  allModules?: Module[];
  modules: Module[];
  moduleTypes: string[];
  name: string;
  setValues?: Dispatch<SetStateAction<Module[]>>;
};

export default function ContactFlowModulesFormInner({
  allModules,
  modules,
  moduleTypes,
  name,
  setValues,
}: ContactFlowModulesFormInnerProps) {
  return (
    <>
      {modules?.map((module, index) => {
        const getModuleContent = (module: Module) => {
          if (isSayModule(module)) {
            return (
              <Say
                audioPromptArn={module.audioPromptArn}
                name={`${name}.${index}`}
                text={module.text}
              />
            );
          }
          if (isAskModule(module)) {
            return (
              <Ask
                audioPromptArn={module.audioPromptArn}
                name={`${name}.${index}`}
                text={module.text}
              />
            );
          }
          if (isDecisionModule(module)) {
            if (allModules && setValues) {
              return (
                <Decision
                  allModules={allModules}
                  moduleTypes={moduleTypes}
                  name={`${name}.${index}`}
                  setValues={setValues}
                />
              );
            }
          }
          if (isAttributeModule(module)) {
            return <Attribute name={`${name}.${index}`} />;
          }
          if (isSendSMSModule(module)) {
            return <SendSMS name={`${name}.${index}`} />;
          }
          if (isCallExternalApiModule(module)) {
            return <CallExternalApi name={`${name}.${index}`} />;
          }
          if (isTransferToQueueModule(module)) {
            return <TransferToQueue name={`${name}.${index}`} />;
          }
          if (isTransferToPhoneNumberModule(module)) {
            return <TransferToPhoneNumber name={`${name}.${index}`} />;
          }
          if (isTransferToVoicemailModule(module)) {
            return (
              <TransferToVoicemail
                audioPromptArn={module.audioPromptArn}
                name={`${name}.${index}`}
                text={module.text}
              />
            );
          }
          if (isEndCallModule(module)) {
            return <EndCall name={`${name}.${index}`} />;
          }

          return <Grid item xs />;
        };

        const required =
          module?.type === "EndCall" &&
          index === modules.length - 1 &&
          module.id === modules.length.toString();

        return (
          <Grid
            container
            spacing={1}
            key={index}
            alignItems="flex-start"
            item
            xs={12}
          >
            <Grid item xs="auto">
              <Typography>{module.id}</Typography>
            </Grid>
            {module?.type !== "Decision" && (
              <Grid item xs={2}>
                <SelectModuleType
                  moduleTypes={moduleTypes}
                  name={`${name}.${index}`}
                  readOnly={required}
                />
              </Grid>
            )}
            {getModuleContent(module)}
            {!required && (
              <Grid item xs="auto">
                <IconButton
                  onClick={() => {
                    processDelete(allModules ?? modules, null, module.id);
                    setValues && setValues(allModules ?? modules);
                  }}
                  size="large"
                >
                  <DeleteForeverIcon />
                </IconButton>
              </Grid>
            )}
          </Grid>
        );
      })}
    </>
  );
}
