import _ from "lodash";
import ContactCrmService from "../services/ContactCrmService";
import { Campaign, ExportTemplate, SmsSender, SmsTemplate, Strategy } from "../types";

const parseResponse = async (response: Response) => {
  const text = await response.text();
  return JSON.parse(text || "{}");
};

export default class ContactCrm {
  #service: ContactCrmService;

  constructor(service: ContactCrmService) {
    this.#service = service;
  }

  async createCampaign(body: string) {
    const response = await this.#service.createCampaign(body);
    return await parseResponse(response);
  }

  async createExportTemplate(body: string) {
    const response = await this.#service.createExportTemplate(body);
    if (response.status === 200) {
      return response.data;
    }
    console.error("Failed to create export template:", response);
  }

  async createSmsTemplate(body: string) {
    const response = await this.#service.createSmsTemplate(body);
    if (response.status === 200) {
      return response.data;
    }
    console.error("Failed to create sms template:", response);

  }

  async createSmsSender(body: string) {
    const response = await this.#service.createSmsSender(body);
    if (response.status === 200) {
      return response.data;
    }
    console.error("Failed to create sms sender:", response);

  }

  async deleteCampaign(id: string) {
    const response = await this.#service.deleteCampaign(id);
    return await parseResponse(response);
  }

  async deleteExportTemplate(id: string) {
    const response = await this.#service.deleteExportTemplate(id);
    console.log("deleteExportTemplate:", response);
    return response.status === 200;
  }

  async deleteSmsTemplate(id: string) {
    const response = await this.#service.deleteSmsTemplate(id);
    console.log("deleteSmsTemplate:", response);
    return response.status === 200;
  }

  async deleteSmsSender(id: string) {
    const response = await this.#service.deleteSmsSender(id);
    console.log("deleteSmsSender:", response);
    return response.status === 200;
  }

  async getCampaign(id: string): Promise<Campaign> {
    const response = await this.#service.getCampaign(id);
    return await parseResponse(response);
  }

  async getCampaignCustomersDownloadFile(
    campaignId: string,
    exportTemplateId = "default"
  ) {
    const response = await this.#service.getCampaignCustomersDownloadFile(
      campaignId,
      exportTemplateId
    );
    if (response.status === 200) {
      return response.data;
    }
    console.error("Failed to get customers download link:", response);
  }

  async getExportTemplate(id: string): Promise<ExportTemplate | undefined> {
    const response = await this.#service.getExportTemplate(id);
    if (response.status === 200) {
      return response.data;
    }
    console.error("Failed to fetch the export template:", response);
  }

  async getStrategy(id: string): Promise<Strategy> {
    const response = await this.#service.getStrategy(id);
    return await parseResponse(response);
  }

  async getSmsTemplate(id: string): Promise<SmsTemplate> {
    const response = await this.#service.getSmsTemplate(id);
    return await parseResponse(response);
  }

  async getSmsSender(id: string): Promise<SmsSender> {
    const response = await this.#service.getSmsSender(id);
    return await parseResponse(response);
  }

  async listCampaignMetrics(campaignId: string) {
    const response = await this.#service.listCampaignMetrics(campaignId);
    if (response.status === 200) {
      return response.data;
    }
    console.error("Failed to fetch the campaign metrics:", response);
  }

  async listCampaigns(): Promise<Campaign[]> {
    const response = await this.#service.listCampaigns();
    return await parseResponse(response);
  }

  async listCampaignUploads(id: string) {
    const response = await this.#service.listCampaignUploads(id);
    return await parseResponse(response);
  }

  async listExportTemplates() {
    const response = await this.#service.listExportTemplates();
    if (response.status === 200) {
      return response.data;
    }
    console.error("Failed to fetch the export template:", response);
  }

  async listStrategies(): Promise<Strategy[]> {
    const response = await this.#service.listStrategies();
    return await parseResponse(response);
  }

  async listSmsTemplates(): Promise<SmsTemplate[]> {
    const response = await this.#service.listSmsTemplates();
    const templates: SmsTemplate[] = await parseResponse(response);
    return _.sortBy(templates, ['name'])
  }

  async listSmsSenders(): Promise<SmsSender[]> {
    const response = await this.#service.listSmsSenders();
    return await parseResponse(response);
  }

  async updateCampaign(id: string, body: string) {
    try {
      // No content is returned, success or error is based on status code
      await this.#service.updateCampaign(id, body);
      return true;
    } catch (err) {
      return false;
    }
  }

  async updateExportTemplate(id: string, body: string) {
    const response = await this.#service.updateExportTemplate(id, body);
    console.log("updateExportTemplate:", response);
    return response.status === 200;
  }

  async updateStrategy(id: string, body: string) {
    try {
      // No content is returned, success or error is based on status code
      await this.#service.updateStrategy(id, body);
      return true;
    } catch (err) {
      return false;
    }
  }

  async updateSmsTemplate(id: string, body: string) {
    try {
      // No content is returned, success or error is based on status code
      await this.#service.updateSmsTemplate(id, body);
      return true;
    } catch (err) {
      return false;
    }
  }

  async updateSmsSender(id: string, body: string) {
    try {
      // No content is returned, success or error is based on status code
      await this.#service.updateSmsSender(id, body);
      return true;
    } catch (err) {
      return false;
    }
  }
}
