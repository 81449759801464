import {
  GetApp as GetAppIcon
} from '@mui/icons-material';
import React from "react";
import { Route, Switch } from "react-router-dom";
import ExportTemplates from "./ExportTemplates";

export const ExportTemplatesConfig = {
  title: 'Export Templates',
  icon: <GetAppIcon />,
  path: '/export-templates'
}

export default function ExportTemplatesRoutes() {
  const routes = [
    {
      component: ExportTemplates,
      path: [`${ExportTemplatesConfig.path}/:exportTemplateId`],
    },
    {
      component: ExportTemplates,
      path: ExportTemplatesConfig.path,
    },
  ];

  return (
    <Switch>
      {routes.map(({ component, path }, index) => (
        <Route component={component} key={index} path={path} />
      ))}
    </Switch>
  );
}
