import {
  Api as ApiIcon
} from '@mui/icons-material';
import React from 'react';
export { default as ExternalAPis } from "./ExternalApis";

export const ExternalApisConfig = {
  title: 'External APIs',
  icon: <ApiIcon />,
  path: '/external-apis'
}