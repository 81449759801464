import {
  Button,
  Dialog,
  DialogActions,
  DialogContentText, Theme, useTheme,
} from "@mui/material";
import React from "react";
import { DialogContent, DialogTitle } from "../../../components/dialog";
import {ICSSProperties} from "../../../types";

const useStyles = (theme: Theme): ICSSProperties => ({
  campaignName: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    textAlign: "center",
    fontWeight: "800",
    fontSize: "1.4rem",
  },

  mainContent: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
  },

  campaignAction: {
    textAlign: "center",
    fontSize: "1rem",
  },

  deactivateText: {
    color: theme.palette.error.main,
    fontWeight: "600",
  },

  importantMessage: {
    textAlign: "center",
    fontSize: "0.8rem",
    marginTop: theme.spacing(4),
  },
});

type CampaignDeleteDialogProps = {
  name: string;
  clickYes?: () => void;
  clickNo?: () => void;
  clickClose?: () => void;
};

export default function CampaignDeleteDialog(props: CampaignDeleteDialogProps) {
  const theme = useTheme()
  const styles = useStyles(theme);
  
  const name = props.name;
  const clickYes = props.clickYes || (() => {});
  const clickNo = props.clickNo || (() => {});
  const clickClose = props.clickClose || (() => {});

  return (
    <Dialog fullWidth open={true} onClose={clickClose}>
      <DialogTitle onClose={clickClose}>Disable campaign</DialogTitle>
      <DialogContent sx={styles.mainContent}>
        <DialogContentText sx={styles.campaignAction}>
          You are about to{" "}
          <span style={styles.deactivateText}>DISABLE</span> the campaign:
        </DialogContentText>
        <DialogContentText sx={styles.campaignName}>
          {name}
        </DialogContentText>
        <DialogContentText sx={styles.campaignAction}>
          Are you sure you want to proceed?
        </DialogContentText>
        <DialogContentText sx={styles.importantMessage}>
          To protect against undesired data loss, the campaign and all contents
          will be set as disabled and not permanently deleted. Pending customers
          will not be processed. Disabled campaigns must be manually restored.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={clickNo} type="button">
          No
        </Button>
        <Button
          color="primary"
          onClick={() => {
            clickYes();
            clickClose();
          }}
          type="button"
          variant="contained"
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
