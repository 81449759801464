import { Grid, MenuItem } from "@mui/material";
import React, { useMemo } from "react";
import { SelectFormik, TextFieldFormik } from "../../../../components/inputs";
import { matchPath, useLocation } from "react-router-dom";

type EndCallProps = { name: string };

export default function EndCall({ name }: EndCallProps) {
  const { pathname } = useLocation();
  const webformProfile = useMemo(
    () => matchPath(pathname, "/webformProfiles"),
    [pathname]
  );
  return (
    <>
      {webformProfile && (
        <Grid item xs>
          <TextFieldFormik name={`${name}.text`} label="Message" />
        </Grid>
      )}
      <Grid item xs>
        <SelectFormik
          name={`${name}._sysCrmCustomerComplete`}
          label="Customer Complete"
        >
          <MenuItem value="true">Yes</MenuItem>
          <MenuItem value="false">No</MenuItem>
        </SelectFormik>
      </Grid>
    </>
  );
}
