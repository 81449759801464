import { useQuery } from "@apollo/client";
import { Alert, Box, Grid } from "@mui/material";
import { gql } from "@apollo/client";
import React, { useContext } from "react";
import { StateContext } from "../../contexts";
import PhoneNumbersForm from "./PhoneNumbersForm";

export const LIST_PHONE_NUMBERS = gql`
  query ListPhoneNumbers(
    $filter: ModelPhoneNumberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPhoneNumbers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        phoneNumber
        contactFlow {
          id
        }
      }
      nextToken
    }
  }
`;

export default function PhoneNumbers() {
  const {
    error: listPhoneNumbersError,
    loading: listPhoneNumbersLoading,
    data: listPhoneNumbersData,
  } = useQuery(LIST_PHONE_NUMBERS);
  const { contactFlows } = useContext(StateContext);

  let child;
  // TODO: Loading state.
  if (listPhoneNumbersLoading) child = <></>;
  else if (listPhoneNumbersData && contactFlows)
    child = (
      <PhoneNumbersForm
        phoneNumbers={listPhoneNumbersData?.listPhoneNumbers.items}
        contactFlows={contactFlows?.filter(
          (contactFlow) => !contactFlow.deleted
        )}
      />
    );
  else {
    console.error(listPhoneNumbersError);
    return <Alert severity="error">Unable to list phone numbers.</Alert>;
  }

  return (
    <Box
      sx={{
        p: 3,
        pt: 5,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Alert severity="info">
            To receive calls in the Automated Dialler, attach phone numbers to a
            call profile.
            <br />
            To claim a phone number or use an existing phone number with the
            Automated Dialler or to release a phone number, please contact
            support.
          </Alert>
        </Grid>
        <Grid item xs={12}>
          {child}
        </Grid>
      </Grid>
    </Box>
  );
}
