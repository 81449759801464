import { Tab, Tabs } from "@mui/material";
import { sortBy } from "lodash";
import React from "react";
import { Link, matchPath, useLocation, useParams } from "react-router-dom";
import { Route } from "../../../types";

export default function WebformProfileTabs() {
  const { contactFlowId } = useParams<{ contactFlowId: string }>();
  const { pathname } = useLocation();

  const routes: Route[] = [
    {
      href: `/webformProfiles/${contactFlowId}/website`,
      listIndex: 0,
      path: "/webformProfiles/:contactFlowId/website",
      text: "Website",
    },
    {
      href: `/webformProfiles/${contactFlowId}/settings`,
      listIndex: 1,
      path: "/webformProfiles/:contactFlowId/settings",
      text: "Settings",
    },
  ];

  const matchedPath = routes.find(({ path }) => matchPath(pathname, { path }));

  return (
    <Tabs
      aria-label="webform profile tabs"
      sx={{
        marginLeft: "56px",
      }}
      textColor="inherit"
      value={matchedPath?.listIndex}
    >
      {sortBy(routes, "listIndex").map(({ text, href }, index) => (
        <Tab component={Link} key={index} label={text} to={href} />
      ))}
    </Tabs>
  );
}
