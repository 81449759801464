import { useQuery } from "@apollo/client";
import { format } from "date-fns";
import React from "react";
import { useParams } from "react-router-dom";
import { GET_CALL_LIST_CREATED_AT } from ".";

export default function ContactsByCallListTitle() {
  const { callListId } = useParams<{ callListId: string }>();
  const { error, data } = useQuery(GET_CALL_LIST_CREATED_AT, {
    variables: { callListId },
  });

  if (error) console.error(error);

  return (
    <>
      {format(
        new Date(data?.getCallList.createdAt || null),
        "dd/MM/yyyy HH:mm"
      )}
    </>
  );
}
