/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_appsync_graphqlEndpoint": "https://iizr4yhnarc6nd3ky4ycwkpvl4.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://do16wlyto4.execute-api.eu-west-2.amazonaws.com/uat",
            "region": "eu-west-2"
        },
        {
            "name": "ADService",
            "endpoint": "https://k0a0o9w6q6.execute-api.eu-west-2.amazonaws.com/uat",
            "region": "eu-west-2"
        }
    ],
    "aws_cognito_identity_pool_id": "eu-west-2:35489cf8-44f0-4deb-9345-a30f480100de",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_RK9XjK23U",
    "aws_user_pools_web_client_id": "a3v5v1gm4s3hvgcevq3diu0ef",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "arcusautodialer905b717fee454c63a1f7006838364986103914-uat",
    "aws_user_files_s3_bucket_region": "eu-west-2",
    "predictions": {
        "convert": {
            "speechGenerator": {
                "region": "eu-west-2",
                "proxy": false,
                "defaults": {
                    "VoiceId": "Amy",
                    "LanguageCode": "en-GB"
                }
            }
        }
    }
};


export default awsmobile;
