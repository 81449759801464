import { CognitoUserAmplify } from "@aws-amplify/ui";
import { intersection } from "lodash";

export default function userAuthorised(
  user: CognitoUserAmplify,
  groups: string[]
) {
  return (
    intersection(
      user.getSignInUserSession()?.getAccessToken().payload["cognito:groups"] ||
        [],
      groups
    ).length > 0
  );
}
