import React from "react";
import { Route, Switch } from "react-router-dom";
import { ContactsTable } from "../components/contacts-table";
import { CallListsTable } from "../components/call-lists-table";

export default function CallListsSwitch() {
  const routes = [
    {
      component: ContactsTable,
      path: "/callLists/:callListId",
    },
    {
      component: CallListsTable,
      path: "/callLists",
    },
  ];

  return (
    <Switch>
      {routes.map(({ component, path }, index) => (
        <Route component={component} key={index} path={path} />
      ))}
    </Switch>
  );
}
