import React, { createContext, ReactNode } from "react";
import { Features } from "../types";

const features: Features = JSON.parse(process.env.REACT_APP_FEATURES ?? "{}");

export const FeaturesContext = createContext<Features>(features);

type FeaturesProviderProps = {
  children: ReactNode;
};

export default function FeaturesProvider({ children }: FeaturesProviderProps) {
  return (
    <FeaturesContext.Provider value={features}>
      {children}
    </FeaturesContext.Provider>
  );
}
