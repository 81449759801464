import { gql, useQuery } from "@apollo/client";
import { Alert, Grid, MenuItem } from "@mui/material";
import React from "react";
import { SelectFormik } from "../../../../components/inputs";
import { listExternalApis as LIST_EXTERNAL_APIS } from "../../../../graphql/queries";
import { ExternalApi } from "../../../../types";

type CallExternalApiProps = {
  name: string;
};

export default function CallExternalApi({ name }: CallExternalApiProps) {
  const { error, data } = useQuery<{
    listExternalApis: { items: ExternalApi[] };
  }>(gql(LIST_EXTERNAL_APIS));

  return (
    <Grid item xs>
      {data ? (
        <SelectFormik name={`${name}.externalApiId`} label="External API">
          {data.listExternalApis.items.map(({ id, name }, index) => (
            <MenuItem key={index} value={id}>
              {name}
            </MenuItem>
          ))}
        </SelectFormik>
      ) : error ? (
        <Alert severity="error">Unable to load external APIs</Alert>
      ) : (
        <></>
      )}
    </Grid>
  );
}
