import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { Refresh as RefreshIcon } from "@mui/icons-material";
import React, { useCallback, useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import useContactCrm from "../../../hooks/useContactCrm";
import { CallMetricsTable } from "./CallMetricsTable";
import { Box } from "@mui/material";
import { ICSSProperties } from "../../../types";
import { SmsMetricsTable } from "./SmsMetricsTable";

const useStyles = (): ICSSProperties => ({
  container: {
    border: "1px solid #e3e3e3",
    padding: "20px 30px",
    marginTop: "20px",
    marginBottom: "20px",
    backgroundColor: "#ffffff",
    textAlign: "center",
    borderRadius: "3px",
    position: "relative",
    minHeight: "155px",
  }
});

export interface CampaignMetricsProps {
  campaignId: string | undefined;
}

export default function CampaignMetrics({ campaignId }: CampaignMetricsProps) {
  const styles = useStyles();

  const [loading, setLoading] = useState(false);
  const [metrics, setMetrics] = useState({});

  const crm = useContactCrm();

  const refreshMetrics = useCallback(
    async (campaignId) => {
      setLoading(true);
      try {
        const results = await crm.listCampaignMetrics(campaignId);
        setMetrics(results);
      } finally {
        setLoading(false);
      }
    },
    [crm]
  );

  useEffect(() => {
    if (!campaignId) {
      return;
    }

    refreshMetrics(campaignId);
  }, [refreshMetrics, campaignId]);

  return (
    <Grid item xs={12}>
      <Grid item xs={12}>
        <Typography component="h5" variant="h5">
          Call Metrics&nbsp;
          <IconButton
            aria-label="refresh"
            color="primary"
            onClick={() => refreshMetrics(campaignId)}
            size="large">
            <RefreshIcon />
          </IconButton>
        </Typography>

        <Box sx={styles.container}>
          {loading && <CircularProgress size={30} />}
          {!loading && <CallMetricsTable metrics={metrics} />}
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Typography component="h5" variant="h5">
          SMS Metrics&nbsp;
          <IconButton
            aria-label="refresh"
            color="primary"
            onClick={() => refreshMetrics(campaignId)}
            size="large">
            <RefreshIcon />
          </IconButton>
        </Typography>

        <Box sx={styles.container}>
          {loading && <CircularProgress size={30} />}
          {!loading && <SmsMetricsTable metrics={metrics} />}
        </Box>
      </Grid>
    </Grid>
  );
}
