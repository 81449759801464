import { Snackbar } from "@mui/material";
import { Predictions } from "aws-amplify";
import React, { createContext, ReactNode, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AudioPlayer } from "../components/audio-player";

export const AudioPlayerContext = createContext<{
  clearAudio: () => void;
  playFromUrl: (str: string) => void;
  textToSpeech: (text: string, voiceId?: string) => void;
}>({
  clearAudio: () => {},
  playFromUrl: (str: string) => {},
  textToSpeech: (text: string) => {},
});

type AudioPlayerProviderProps = {
  children: ReactNode;
};

export default function AudioPlayerProvider({
  children,
}: AudioPlayerProviderProps) {
  const history = useHistory();
  const [src, setSrc] = useState<string>();

  const clearAudio = () => setSrc(undefined);

  useEffect(() => history.listen(() => clearAudio()), [history]);

  return (
    <AudioPlayerContext.Provider
      value={{
        // TODO: Voice id is an enum of voice ids.
        textToSpeech: (text: string, voiceId?: string) => {
          Predictions.convert({
            textToSpeech: {
              source: {
                text,
              },
              voiceId,
            },
          })
            .then((result) => {
              setSrc(result.speech.url);
            })
            .catch((err) => console.error({ err }));
        },
        playFromUrl: setSrc,
        clearAudio,
      }}
    >
      {children}
      <Snackbar open={Boolean(src)} key={src}>
        <AudioPlayer src={src} />
      </Snackbar>
    </AudioPlayerContext.Provider>
  );
}
