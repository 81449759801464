import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fe418d", // Bright Pink
    },
    secondary: {
      main: "#202124", // Rich Grey
    },
    text: {
      primary: "#333333",
      secondary: "rgba(51, 51, 51, 0.87)",
    },
    background: {
      default: "#f6f5f8", // Wash
    },
  },
  typography: {
    fontFamily: "'Poppins', 'Helvetica', 'Arial', sans-serif",
  },
});

export default theme;
