import { gql, useQuery } from "@apollo/client";
import { Alert, Grid, MenuItem } from "@mui/material";
import { sortBy } from "lodash";
import React, { useContext } from "react";
import { SelectFormik } from "../../../../components/inputs";
import { StateContext } from "../../../../contexts";
import { Queue } from "../../../../types";

export const LIST_QUEUES = gql`
  query ListQueues($limit: Int, $nextToken: String) {
    listQueues(
      filter: { types: { eq: ["STANDARD"] } }
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
      }
      nextToken
    }
  }
`;

type TransferToQueueProps = {
  name: string;
};

export default function TransferToQueue({ name }: TransferToQueueProps) {
  const { error, data } = useQuery<{ listQueues: { items: Queue[] } }>(
    LIST_QUEUES
  );
  const { contactFlows } = useContext(StateContext);

  if (error) {
    console.error(error);
  }

  return (
    <Grid container item spacing={2} xs>
      {data ? (
        <>
          <Grid item xs>
            <SelectFormik label="Queue name" name={`${name}.queueId`}>
              {data?.listQueues.items.map(({ id, name }, index) => (
                <MenuItem key={index} value={id}>
                  {name}
                </MenuItem>
              ))}
            </SelectFormik>
          </Grid>
          <Grid item xs>
            <SelectFormik label="Script" name={`${name}.scriptId`}>
              {sortBy(
                contactFlows?.filter((contactFlow) => !contactFlow.deleted),
                "name"
              )?.map((contactFlow, index) => (
                <MenuItem key={index} value={contactFlow.id}>
                  {contactFlow.name}
                </MenuItem>
              ))}
            </SelectFormik>
          </Grid>
        </>
      ) : error ? (
        <Grid item xs>
          <Alert severity="error">Unable to list queues</Alert>
        </Grid>
      ) : (
        <></>
      )}
    </Grid>
  );
}
