import { Chip, Grid, Theme, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useContactCrm } from "../../hooks";
import CampaignCustomers from "./sections/CampaignCustomers";
import CampaignCustomerUploads from "./sections/CampaignCustomerUploads";
import CampaignMetrics from "./sections/CampaignMetrics";

import { gql } from "@apollo/client";
import {
  DeleteForever as DeleteForeverIcon,
  Pause as PauseIcon,
  PlayArrow as PlayArrowIcon,
  Settings as SettingsIcon,
} from "@mui/icons-material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { format } from "date-fns";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { client } from "../../contexts";
import { updateContactFlow as UPDATE_CONTACT_FLOW } from "../../graphql/mutations";
import ContactCrm from "../../lib/ContactCrm";
import { Campaign, ContactFlow, ICSSProperties } from "../../types";
import CampaignCreateEditDialog from "./dialogs/CampaignCreateEditDialog";
import CampaignDeleteDialog from "./dialogs/CampaignDeleteDialog";
import CampaignPauseStartDialog from "./dialogs/CampaignPauseStartDialog";
import CampaignStrategy from "./sections/CampaignStrategy";

const useStyles = (theme: Theme): ICSSProperties => ({
  root: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(5),
  },

  campaignHeader: {
    marginBottom: theme.spacing(3),
  },

  campaignHeaderTitle: {
    display: "flex",
    alignItems: "center",

    "& > .MuiChipRoot": {
      marginLeft: theme.spacing(2),
    },
  },

  campaignHeaderSubtitle: {
    marginBottom: theme.spacing(1.5),
  },

  campaignHeaderCaption: {
    fontStyle: "italic",
  },

  headerButtons: {
    "& .MuiButtonBase-root": {
      marginRight: theme.spacing(1.5),
      "&:last-child": {
        marginRight: 0,
      },
    },
  },

  chipPaused: {
    backgroundColor: "rgb(244, 67, 54)",
    color: "#fff",
    "& .MuiChip-icon": {
      color: "#fff",
      marginRight: "-7px",
    },
    "&:hover": {
      backgroundColor: "rgb(227,58,45)",
    },
  },

  chipStarted: {
    backgroundColor: "rgb(76, 175, 80)",
    color: "#fff",
    "& .MuiChip-icon": {
      color: "#fff",
      marginRight: "-7px",
    },
    "&:hover": {
      backgroundColor: "rgb(68,164,72)",
    },
  },

  chipDelete: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    "& .MuiChip-icon": {
      color: "#fff",
      marginRight: "-7px",
    },
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
    "& .MuiChip-label": {
      paddingRight: 0,
    },
  },

  chipEdit: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    "& .MuiChip-icon": {
      color: "#fff",
      marginRight: "-7px",
    },
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
    "& .MuiChip-label": {
      paddingRight: 0,
    },
  },

  campaignLoading: {
    padding: theme.spacing(10),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& > div": {
      marginRight: theme.spacing(1),
    },
  },
});

export default function CampaignSettings(
  props: RouteComponentProps<{ campaignId: string }>
): JSX.Element {
  const theme = useTheme();
  const styles = useStyles(theme);

  const campaignId = props.match.params.campaignId;

  // CRM
  const crm = useContactCrm();

  // Redirect
  const [redirect, setRedirect] = useState<string>();

  const [loadingCampaign, setLoadingCampaign] = useState(true);
  const [campaign, setCampaign] = useState<Campaign>();
  const [openPauseStartCampaignDialog, setOpenPauseStartCampaignDialog] =
    useState(false);
  const [openEditCampaignDialog, setOpenEditCampaignDialog] = useState(false);
  const [openDeleteCampaignDialog, setOpenDeleteCampaignDialog] =
    useState(false);

  const getCampaign = async (crm: ContactCrm, campaignId: string) => {
    const campaign = await crm.getCampaign(campaignId);
    campaign.uploads = await crm.listCampaignUploads(campaignId);
    return campaign;
  };

  const silentReloadCampaign = async (crm: ContactCrm, campaignId: string) => {
    const campaign = await getCampaign(crm, campaignId);
    setCampaign(campaign);
  };

  const loadCampaign = async (crm: ContactCrm, campaignId: string) => {
    setLoadingCampaign(true);
    const campaign = await getCampaign(crm, campaignId);
    setCampaign(campaign);
    setLoadingCampaign(false);
  };

  useEffect(() => {
    if (crm && campaignId) {
      loadCampaign(crm, campaignId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crm, campaignId]);

  const editCampaign = async (data: Partial<Campaign>) => {
    setLoadingCampaign(true);

    await crm.updateCampaign(
      campaignId,
      JSON.stringify({
        name: data.name,
        description: data.description,
      })
    );

    const campaign = await getCampaign(crm, campaignId);
    setCampaign(campaign);
    setLoadingCampaign(false);
  };

  const pauseStartCampaign = async (campaignId: string, paused: boolean) => {
    setLoadingCampaign(true);
    const updatedCampaign = await crm.updateCampaign(
      campaignId,
      JSON.stringify({ paused })
    );

    if (updatedCampaign) {
      const contactFlows = [
        ...new Set(Object.values(campaign?.strategyConfig ?? {})),
      ];

      for (const cfid of contactFlows) {
        const result = await client.mutate<{ updateContactFlow: ContactFlow }>({
          mutation: gql(UPDATE_CONTACT_FLOW),
          variables: { input: { id: cfid, paused } },
        });

        if (result.errors || !result?.data?.updateContactFlow?.id) {
          console.error(result.errors);
          throw new Error(`Pause/resume call profile failed - id: ${cfid}`);
        }
      }
    }

    setCampaign(await getCampaign(crm, campaignId));
    setLoadingCampaign(false);
  };

  const deleteCampaign = async (campaignId: string) => {
    setLoadingCampaign(true);
    await crm.deleteCampaign(campaignId);
    setRedirect(`/campaigns`);
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  if (loadingCampaign) {
    return (
      <Box sx={styles.campaignLoading}>
        <CircularProgress size={20} />
        <span>Loading campaign ...</span>
      </Box>
    );
  } else if (!loadingCampaign && campaign) {
    return (
      <Box sx={styles.root}>
        {openEditCampaignDialog && (
          <CampaignCreateEditDialog
            name={campaign.name}
            description={campaign.description}
            mode="edit"
            clickYes={(data) => editCampaign(data)}
            clickNo={() => setOpenEditCampaignDialog(false)}
            clickClose={() => setOpenEditCampaignDialog(false)}
          />
        )}

        {openPauseStartCampaignDialog && (
          <CampaignPauseStartDialog
            name={campaign.name}
            action={campaign.paused ? "start" : "pause"}
            clickYes={() => {
              pauseStartCampaign(campaignId, !campaign.paused);
            }}
            clickNo={() => setOpenPauseStartCampaignDialog(false)}
            clickClose={() => setOpenPauseStartCampaignDialog(false)}
          />
        )}

        {openDeleteCampaignDialog && (
          <CampaignDeleteDialog
            name={campaign.name}
            clickYes={() => deleteCampaign(campaign.id)}
            clickNo={() => setOpenDeleteCampaignDialog(false)}
            clickClose={() => setOpenDeleteCampaignDialog(false)}
          />
        )}

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box sx={styles.campaignHeader}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography
                  component="h4"
                  variant="h4"
                  sx={styles.campaignHeaderTitle}
                >
                  {campaign.name}{" "}
                  {campaign.paused && (
                    <Chip label={"PAUSED"} sx={styles.chipPaused} />
                  )}
                  {!campaign.paused && (
                    <Chip label={"RUNNING"} sx={styles.chipStarted} />
                  )}
                </Typography>

                <Box sx={styles.headerButtons}>
                  {campaign.paused && (
                    <Chip
                      icon={<PlayArrowIcon />}
                      label={"START"}
                      sx={styles.chipStarted}
                      onClick={() => {
                        setOpenPauseStartCampaignDialog(true);
                      }}
                    />
                  )}

                  {!campaign.paused && (
                    <Chip
                      icon={<PauseIcon />}
                      label={"PAUSE"}
                      sx={styles.chipPaused}
                      onClick={() => {
                        setOpenPauseStartCampaignDialog(true);
                      }}
                    />
                  )}

                  <Chip
                    icon={<DeleteForeverIcon />}
                    sx={styles.chipDelete}
                    onClick={() => {
                      setOpenDeleteCampaignDialog(true);
                    }}
                  />

                  <Chip
                    icon={<SettingsIcon />}
                    sx={styles.chipEdit}
                    onClick={() => {
                      setOpenEditCampaignDialog(true);
                    }}
                  />
                </Box>
              </Box>

              {campaign.description && (
                <Typography
                  component="p"
                  variant="body1"
                  sx={styles.campaignHeaderSubtitle}
                >
                  {campaign.description}
                </Typography>
              )}
              <Typography
                component="p"
                variant="caption"
                sx={styles.campaignHeaderCaption}
              >
                Last updated{" "}
                {format(new Date(campaign.updatedWhen), "dd/MM/yyyy HH:mm")} by{" "}
                {campaign.updatedBy}
              </Typography>
            </Box>
          </Grid>

          <CampaignStrategy
            campaign={campaign}
            triggerReloadCampaign={() => silentReloadCampaign(crm, campaignId)}
          />
          <CampaignCustomerUploads
            campaign={campaign}
            triggerReloadCampaign={() => silentReloadCampaign(crm, campaignId)}
          />
          <CampaignCustomers campaign={campaign} />
          <CampaignMetrics campaignId={campaignId} />
        </Grid>
      </Box>
    );
  }

  return <></>;
}
