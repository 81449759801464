import { Auth } from "aws-amplify";
import axios from "axios";
import { HttpMethod, HttpMethods } from "../lib/Types.crm";

export default class ContactCrmService {
  #defaultHeaders = async () => ({
    Accept: "application/json",
    Authorization: await this.#idToken(),
  });

  #getHeaders = async (
    method: HttpMethod,
    body?: string
  ): Promise<{ [key: string]: string }> => {
    const additionalHeaders: { [key: string]: string } = {};

    switch (method) {
      case HttpMethods.Put:
      case HttpMethods.Post:
      case HttpMethods.Delete: {
        if (body) {
          additionalHeaders["Content-Length"] = body.length.toString();
          additionalHeaders["Content-Type"] = "application/json";
        }
        break;
      }
      default:
        break;
    }

    return Object.assign({}, await this.#defaultHeaders(), additionalHeaders);
  };

  #idToken = async () => {
    const currentSession = await Auth.currentSession();

    return currentSession.getIdToken().getJwtToken();
  };

  #url = () => {
    const result = process.env?.REACT_APP_CONTACT_CRM_URL;
    if (!result) {
      throw new Error("Contact CRM URL not configured");
    }

    if (!result.startsWith("https://")) {
      throw new Error("Contact CRM URL must use SSL transport security");
    }

    return result;
  };

  async createCampaign(body: string) {
    const params = {
      method: HttpMethods.Post,
      headers: await this.#getHeaders(HttpMethods.Post, body),
      body,
    };

    const url = `${this.#url()}/campaign`;
    const response = await fetch(url, params);

    if (!response.ok) {
      console.error("Failed to create campaign", response);
    }

    return response;
  }

  async createExportTemplate(body: string) {
    const url = `${this.#url()}/export/template`;
    const headers = await this.#getHeaders(HttpMethods.Post, body);

    try {
      return await axios.post(url, body, { headers });
    } catch (err) {
      console.log(err);
      return {
        status: 500,
        data: {},
      };
    }
  }

  async createSmsTemplate(body: string) {
    const url = `${this.#url()}/sms-templates`;
    const headers = await this.#getHeaders(HttpMethods.Post, body);

    try {
      return await axios.post(url, body, { headers });
    } catch (err) {
      console.log(err);
      return {
        status: 500,
        data: {},
      };
    }
  }

  async createSmsSender(body: string) {
    const url = `${this.#url()}/sms-senders`;
    const headers = await this.#getHeaders(HttpMethods.Post, body);

    try {
      return await axios.post(url, body, { headers });
    } catch (err) {
      console.log(err);
      return {
        status: 500,
        data: {},
      };
    }
  }

  async deleteCampaign(id: string) {
    const params = {
      method: HttpMethods.Delete,
      headers: await this.#getHeaders(HttpMethods.Delete),
    };

    const url = `${this.#url()}/campaign/${id}`;
    const response = await fetch(url, params);

    if (!response.ok) {
      console.error("Failed to delete campaign", response);
    }

    return response;
  }

  async deleteExportTemplate(id: string) {
    const url = `${this.#url()}/export/template/${id}`;
    const headers = await this.#getHeaders(HttpMethods.Delete);

    try {
      return await axios.delete(url, { headers });
    } catch (err) {
      console.log(err);
      return {
        status: 500,
        data: {},
      };
    }
  }

  async deleteSmsTemplate(id: string) {
    const url = `${this.#url()}/sms-templates/${id}`;
    const headers = await this.#getHeaders(HttpMethods.Delete);

    try {
      return await axios.delete(url, { headers });
    } catch (err) {
      console.log(err);
      return {
        status: 500,
        data: {},
      };
    }
  }

  async deleteSmsSender(id: string) {
    const url = `${this.#url()}/sms-senders/${id}`;
    const headers = await this.#getHeaders(HttpMethods.Delete);

    try {
      return await axios.delete(url, { headers });
    } catch (err) {
      console.log(err);
      return {
        status: 500,
        data: {},
      };
    }
  }

  async getCampaign(id: string) {
    const params = {
      method: HttpMethods.Get,
      headers: await this.#getHeaders(HttpMethods.Get),
    };

    const url = `${this.#url()}/campaign/${id}`;
    const response = await fetch(url, params);

    if (!response.ok) {
      console.error(`Failed to get campaign ${id}`, response);
    }

    return response;
  }

  async getCampaignCustomersDownloadFile(
    campaignId: string,
    exportTemplateId = "default"
  ) {
    const config = {
      headers: {
        ...(await this.#getHeaders(HttpMethods.Get)),
        Accept: "text/csv",
      },
    };

    let url = `${this.#url()}/campaign/${campaignId}/customers?download=true`;

    if (exportTemplateId && exportTemplateId !== "default") {
      url += `&exportTemplateId=${exportTemplateId}`;
    }

    return await axios.get(url, config);
  }

  async getExportTemplate(id: string) {
    const url = `${this.#url()}/export/template/${id}`;
    const config = {
      headers: await this.#getHeaders(HttpMethods.Get),
    };
    return await axios.get(url, config);
  }

  async getStrategy(id: string) {
    const params = {
      method: HttpMethods.Get,
      headers: await this.#getHeaders(HttpMethods.Get),
    };

    const url = `${this.#url()}/strategies/${id}`;
    const response = await fetch(url, params);

    if (!response.ok) {
      console.error(`Failed to get strategy ${id}`, response);
    }

    return response;
  }

  async getSmsTemplate(id: string) {
    const params = {
      method: HttpMethods.Get,
      headers: await this.#getHeaders(HttpMethods.Get),
    };

    const url = `${this.#url()}/sms-templates/${id}`;
    const response = await fetch(url, params);

    if (!response.ok) {
      console.error(`Failed to get sms template ${id}`, response);
    }

    return response;
  }

  async getSmsSender(id: string) {
    const params = {
      method: HttpMethods.Get,
      headers: await this.#getHeaders(HttpMethods.Get),
    };

    const url = `${this.#url()}/sms-senders/${id}`;
    const response = await fetch(url, params);

    if (!response.ok) {
      console.error(`Failed to get sms sender ${id}`, response);
    }

    return response;
  }

  async listCampaignMetrics(campaignId: string) {
    const url = `${this.#url()}/metrics/campaign/${campaignId}`;
    const config = {
      headers: await this.#getHeaders(HttpMethods.Get),
    };
    return await axios.get(url, config);
  }

  async listCampaigns() {
    const params = {
      method: HttpMethods.Get,
      headers: await this.#getHeaders(HttpMethods.Get),
    };

    const url = `${this.#url()}/campaigns`;
    const response = await fetch(url, params);

    if (!response.ok) {
      console.error("Failed to get campaigns", response);
    }

    return response;
  }

  async listCampaignUploads(id: string) {
    const params = {
      method: HttpMethods.Get,
      headers: await this.#getHeaders(HttpMethods.Get),
    };

    const url = `${this.#url()}/campaign/${id}/uploads`;
    const response = await fetch(url, params);

    if (!response.ok) {
      console.error("Failed to get campaign uploads", response);
    }

    return response;
  }

  async listExportTemplates() {
    const url = `${this.#url()}/export/templates`;
    const config = {
      headers: await this.#getHeaders(HttpMethods.Get),
    };
    return await axios.get(url, config);
  }

  async listStrategies() {
    const method = HttpMethods.Get;
    const params = {
      method,
      headers: await this.#getHeaders(method),
    };
    const url = `${this.#url()}/strategies`;

    const response = await fetch(url, params);

    if (!response.ok) {
      console.error("Failed to get campaign strategies", response);
    }

    return response;
  }

  async listSmsTemplates() {
    const method = HttpMethods.Get;
    const params = {
      method,
      headers: await this.#getHeaders(method),
    };
    const url = `${this.#url()}/sms-templates`;

    const response = await fetch(url, params);

    if (!response.ok) {
      console.error("Failed to get sms templates", response);
    }

    return response;
  }

  async listSmsSenders() {
    const method = HttpMethods.Get;
    const params = {
      method,
      headers: await this.#getHeaders(method),
    };
    const url = `${this.#url()}/sms-senders`;

    const response = await fetch(url, params);

    if (!response.ok) {
      console.error("Failed to get sms senders", response);
    }

    return response;
  }

  async updateCampaign(id: string, body: string) {
    const method = HttpMethods.Put;
    const params = {
      method,
      headers: await this.#getHeaders(method, body),
      body,
    }
    const url = `${this.#url()}/campaign/${id}`;
    
    const response = await fetch(url, params);

    if (!response.ok || response.status !== 200) {
      console.error("Failed to update campaign", response);
      throw new Error("Failed");
    }

    return response;
  }

  async updateExportTemplate(id: string, body: string) {
    const url = `${this.#url()}/export/template/${id}`;
    const headers = await this.#getHeaders(HttpMethods.Put, body);

    try {
      return await axios.put(url, body, { headers });
    } catch (err) {
      console.log(err);
      return {
        status: 500,
        data: {},
      };
    }
  }

  async updateStrategy(id: string, body: string) {
    const params = {
      method: HttpMethods.Put,
      headers: await this.#getHeaders(HttpMethods.Put, body),
      body,
    };

    const url = `${this.#url()}/strategies/${id}`;
    const response = await fetch(url, params);

    if (!response.ok || response.status !== 200) {
      console.error("Failed to update strategy", response);
      throw new Error("Failed");
    }

    return response;
  }

  async updateSmsTemplate(id: string, body: string) {
    const params = {
      method: HttpMethods.Put,
      headers: await this.#getHeaders(HttpMethods.Put, body),
      body,
    };

    const url = `${this.#url()}/sms-templates/${id}`;
    const response = await fetch(url, params);

    if (!response.ok || response.status !== 200) {
      console.error("Failed to update sms template", response);
      throw new Error("Failed");
    }

    return response;
  }

  async updateSmsSender(id: string, body: string) {
    const params = {
      method: HttpMethods.Put,
      headers: await this.#getHeaders(HttpMethods.Put, body),
      body,
    };

    const url = `${this.#url()}/sms-senders/${id}`;
    const response = await fetch(url, params);

    if (!response.ok || response.status !== 200) {
      console.error("Failed to update sms sender", response);
      throw new Error("Failed");
    }

    return response;
  }
}
