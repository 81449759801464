import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { Auth } from "aws-amplify";
import { AuthOptions, AUTH_TYPE, createAuthLink } from "aws-appsync-auth-link";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";
import React, { ReactNode } from "react";
import awsExports from "../aws-exports";

// aws_cognito_identity_pool_id: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
// aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
// aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,

const auth: AuthOptions = {
    type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    jwtToken: async () => {
      const currentSession = await Auth.currentSession();
      const jwt = currentSession.getIdToken().getJwtToken();
      return jwt;
    },
  },
  region = awsExports.aws_appsync_region,
  url = awsExports.aws_appsync_graphqlEndpoint;

const httpLink = new HttpLink({ uri: url });

const link = ApolloLink.from([
  createAuthLink({ url, region, auth }),
  createSubscriptionHandshakeLink({ url, region, auth }, httpLink),
]);

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link,
});

export default function AWSAppSyncClientProvider({
  children,
}: {
  children: ReactNode;
}) {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
