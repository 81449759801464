import {
  Close as CloseIcon,
  Pause as PauseIcon,
  PlayArrow as PlayArrowIcon,
} from "@mui/icons-material";
import { IconButton, Paper, PaperProps, Slider } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useAudioPlayer } from "../../hooks";

function secondsToTimestamp(seconds: number) {
  if (seconds === 0 || seconds === Infinity) return "00:00";
  return new Date(seconds * 1000).toISOString().substr(14, 5);
}

type AudioPlayerProps = PaperProps & {
  src?: string;
};

export default forwardRef<HTMLDivElement | null, AudioPlayerProps>(
  function AudioPlayer({ src, ...props }, ref) {
    const { clearAudio } = useAudioPlayer();
    const player = useRef<HTMLAudioElement>(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [position, setPosition] = useState(0);
    const [duration, setDuration] = useState(0);
    const theme = useTheme();

    useEffect(() => {
      const current = player.current;
      function onDurationChange() {
        current?.duration && setDuration(current.duration);
      }
      function onEnded() {
        setIsPlaying(false);
      }
      function onTimeUpdate() {
        current?.currentTime && setPosition(current?.currentTime);
      }
      function onPlay() {
        setIsPlaying(true);
      }
      function onPause() {
        setIsPlaying(false);
      }
      current?.addEventListener("durationchange", onDurationChange);
      current?.addEventListener("ended", onEnded);
      current?.addEventListener("timeupdate", onTimeUpdate);
      current?.addEventListener("play", onPlay);
      current?.addEventListener("pause", onPause);
      return () => {
        current?.removeEventListener("durationchange", onDurationChange);
        current?.removeEventListener("ended", onEnded);
        current?.removeEventListener("timeupdate", onTimeUpdate);
        current?.removeEventListener("play", onPlay);
        current?.removeEventListener("pause", onPause);
      };
    }, []);

    useEffect(() => {
      if (src) {
        player.current?.play();
      }
    }, [src]);

    return (
      <Paper
        {...props}
        elevation={3}
        ref={ref}
        sx={{
          backgroundColor: theme.palette.grey[800],
          color: "#fff",
          padding: "20px 15px",
          width: "380px",
        }}
      >
        <div
          style={{
            alignItems: "center",
            display: "flex",
          }}
        >
          <audio ref={player} src={src} />
          <IconButton
            color="inherit"
            onClick={() => {
              isPlaying ? player.current?.pause() : player.current?.play();
            }}
            size="small"
          >
            {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
          </IconButton>
          <Slider
            color="primary"
            max={duration}
            onChange={(_, newValue) => {
              if (player.current?.currentTime)
                player.current.currentTime =
                  typeof newValue === "number" ? newValue : newValue[1];
            }}
            step={0.01}
            sx={{
              flexGrow: 1,
              margin: "0 15px",
            }}
            value={position}
          />
          <IconButton
            color="inherit"
            onClick={() => {
              clearAudio();
            }}
            size="small"
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div
          style={{
            fontSize: "12px",
            textAlign: "center",
          }}
        >
          {secondsToTimestamp(position)} / {secondsToTimestamp(duration)}
        </div>
      </Paper>
    );
  }
);
