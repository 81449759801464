import { gql, useQuery } from "@apollo/client";
import { Alert, useTheme } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { toSentenceCase } from "../../../utils/to-sentence-case";
import { HorizontalBar } from "../../charts";

const GET_CONTACT_PHONE_STATUSES_COUNT_BY_CALL_LIST_ID = gql`
  query GetContactsPhoneStatusesCountByCallListId($id: ID!) {
    getContactsPhoneStatusesCountByCallListId(id: $id) {
      callMade
      callingNow
      callPending
      invalidNumber
      error
    }
  }
`;

export default function ContactsPhoneStatusesCountByCallListIdChart() {
  const { callListId } = useParams<{ callListId: string }>();
  const { error, loading, data } = useQuery(
    GET_CONTACT_PHONE_STATUSES_COUNT_BY_CALL_LIST_ID,
    {
      variables: { id: callListId },
    }
  );
  const theme = useTheme();

  // TODO: Loading state
  if (loading) return <></>;
  else if (data) {
    const phoneStatuses = Object.keys(
      data.getContactsPhoneStatusesCountByCallListId
    ).filter((phoneStatus) => phoneStatus !== "__typename");
    return (
      <HorizontalBar
        data={{
          datasets: [
            {
              data: phoneStatuses.map(
                (phoneStatus) =>
                  data?.getContactsPhoneStatusesCountByCallListId[
                    phoneStatus
                  ] || 0
              ),
              backgroundColor: phoneStatuses.map((phoneStatus) => {
                switch (phoneStatus) {
                  case "callMade":
                  case "callingNow":
                  case "callPending":
                    return theme.palette.info.main;
                  case "invalidNumber":
                  case "error":
                    return theme.palette.error.main;
                  default:
                    return undefined;
                }
              }),
            },
          ],
          labels: phoneStatuses.map((phoneStatus) =>
            toSentenceCase(phoneStatus)
          ),
        }}
        title="Status"
      />
    );
  } else {
    console.error(error);
    return (
      <Alert severity="error">Unable to load contacts phone statuses</Alert>
    );
  }
}
