import {
  Settings as SettingsIcon
} from "@mui/icons-material";
import { Box, Chip, Grid, Theme, useTheme } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { useContactCrm } from "../../hooks";
import { ICSSProperties, Strategy } from "../../types";
import { StrategiesConfig } from "./StrategiesRoutes";

const useStyles = (theme: Theme): ICSSProperties => ({
  root: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(5),
  },
  strategiesLoading: {
    padding: theme.spacing(10),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& > div": {
      marginRight: theme.spacing(1),
    },
  },
  strategyLink: {
    fontWeight: 600,
    textDecoration: "none",
    color: theme.palette.text.primary,

    "& > .MuiChipRoot": {
      marginLeft: theme.spacing(1),
      fontSize: "0.7rem",
      borderRadius: "12px",
      height: "20px",

      "& > span": {
        padding: "0 6px",
      },
    },
  },
  tableCellManage: {
    "& .MuiChip-root": {
      marginRight: theme.spacing(1.5),
      "&:last-child": {
        marginRight: 0,
      },
    },
  },
  chipEdit: {
    backgroundColor: theme.palette.grey[800],
    color: "#fff",
    cursor: "pointer",
    "& .MuiChip-icon": {
      color: "#fff",
      marginRight: "-7px",
    },
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
    "& .MuiChip-label": {
      paddingRight: 0,
    }
  },
  campaign: {}
});

export default function StrategiesPage(): JSX.Element {
  const theme = useTheme()
  const styles = useStyles(theme);

  // CRM
  const crm = useContactCrm();
  const [loadingStrategies, setLoadingStrategies] = useState(true);
  const [strategies, setStrategies] = useState<Strategy[]>();
  const [redirect, setRedirect] = useState<string>();

  useEffect(() => {
    const loadStrategies = async () => {
      if (crm) {
        setLoadingStrategies(true);
        setStrategies(await crm.listStrategies());
        setLoadingStrategies(false);
      }
    };

    loadStrategies();
  }, [crm]);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  if (loadingStrategies) {
    return (
      <Box sx={styles.root}>
        <Box sx={styles.strategiesLoading}>
          <CircularProgress size={20} />
          <span>Loading strategies...</span>
        </Box>
      </Box>
    );
  } else if (!loadingStrategies && Array.isArray(strategies)) {
    return (
      <Box sx={styles.root}>
        <Grid container spacing={2}>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Updated By</TableCell>
                      <TableCell>Last Updated</TableCell>
                      <TableCell align="right">Manage</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {strategies.map((strategy) => (
                      <TableRow
                        key={strategy.id}
                        sx={styles.strategy}
                      >
                        <TableCell component="th" scope="row">
                          <Link
                            to={`${StrategiesConfig.path}/${strategy.id}`}
                            style={styles.strategyLink}
                          >
                            {strategy.name}{" "}
                          </Link>
                        </TableCell>
                        <TableCell>{strategy.updatedBy}</TableCell>
                        <TableCell>
                          {format(
                            new Date(strategy.updatedWhen),
                            "dd/MM/yyyy HH:mm"
                          )}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={styles.tableCellManage}
                        >
                          <Chip
                            icon={<SettingsIcon />}
                            sx={styles.chipEdit}
                            component={Link}
                            to={`${StrategiesConfig.path}/${strategy.id}`}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
        </Grid>
      </Box>
    );
  }

  return <></>;
}
