import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { StateContext } from "../../../contexts";

export default function ContactFlowTitle() {
  const { contactFlowId } = useParams<{ contactFlowId: string }>();
  const { contactFlows } = useContext(StateContext);

  const contactFlow = contactFlows.find((contactFlow) => {
    return contactFlow.id === contactFlowId;
  });

  return <>{contactFlow?.name}</>;
}
