import {
  Button,
  Dialog,
  DialogActions,
  DialogContentText
} from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import { DialogContent, DialogTitle } from "../../../components/dialog";
import { useContactCrm } from "../../../hooks";
import { SmsSender } from "../../../types";
import { SmsSendersConfig } from "../SmsSendersRoutes";

type ConfirmDeleteDialogProps = {
  open: boolean;
  senderToDelete: Pick<SmsSender, "id" | "name">;
  onClose: () => void;
};

export default function ConfirmDeleteDialog({
  open,
  senderToDelete: { id, name },
  onClose,
}: ConfirmDeleteDialogProps) {
  const crm = useContactCrm();
  const history = useHistory();

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle onClose={onClose}>
        Are you sure you want to delete the following SMS Sender?
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{name}</DialogContentText>
        <DialogContentText>
          Please note that you will need to update any relevant SMS Templates.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} type="button">
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={async () => {
            try {
              const res = await crm.deleteSmsSender(id);
              if (!res) {
                throw new Error("Failed to delete SMS Sender");
              }
              window.alert("SMS Sender deleted Succefully");
              history.push(SmsSendersConfig.path);
              onClose();
            } catch (err) {
              window.alert("Failed to delete SMS Sender");
              console.error(err);
            }
          }}
          type="button"
          variant="contained"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
