import { useLazyQuery, useSubscription } from "@apollo/client";
import { Edit as EditIcon } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { gql } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { CreateUpdateExternalApiDialog } from "../../components";
import { listExternalApis as LIST_EXTERNAL_APIS } from "../../graphql/queries";
import {
  onCreateExternalApi as ON_CREATE_EXTERNAL_API,
  onUpdateExternalApi as ON_UPDATE_EXTERNAL_API,
} from "../../graphql/subscriptions";
import { ExternalApiItem } from "../../types";

export default function ExternalApis() {
  const [externalApiToUpdate, setExternalApiToUpdate] =
    useState<ExternalApiItem>();
  const [items, setItems] = useState<ExternalApiItem[]>([]);
  const [nextToken, setNextToken] = useState(null);
  const [nextTokens, setNextTokens] = useState([nextToken]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const [listExternalApis, { loading, data }] = useLazyQuery(
    gql(LIST_EXTERNAL_APIS),
    {
      variables: {
        limit: pageSize,
        nextToken: nextTokens[page],
      },
    }
  );

  useEffect(() => {
    listExternalApis();
  }, [listExternalApis]);

  useEffect(() => {
    if (data) {
      setItems(data?.listExternalApis.items);
      setNextToken(data?.listExternalApis.nextToken);
    }
  }, [data]);

  useSubscription(gql(ON_CREATE_EXTERNAL_API), {
    onSubscriptionData: () => listExternalApis,
    shouldResubscribe: true,
  });

  useSubscription<{
    onUpdateExternalApi: ExternalApiItem;
  }>(gql(ON_UPDATE_EXTERNAL_API), {
    onSubscriptionData: (options) => {
      if (options.subscriptionData.error)
        console.error(options.subscriptionData.error);
      else if (
        items.some(
          (item) =>
            item.id === options.subscriptionData.data?.onUpdateExternalApi?.id
        )
      )
        setItems((items) =>
          items.map((item) =>
            item.id === options.subscriptionData.data?.onUpdateExternalApi?.id
              ? {
                  ...item,
                  ...options.subscriptionData.data?.onUpdateExternalApi,
                }
              : item
          )
        );
    },
    shouldResubscribe: true,
  });

  const handleClose = () => setExternalApiToUpdate(undefined);

  const handlePageChange = (paramPage: number) => {
    if (paramPage > page)
      setNextTokens((nextTokens) => [...nextTokens, nextToken]);
    else if (paramPage < page)
      setNextTokens((nextTokens) => {
        nextTokens.pop();
        return nextTokens;
      });
    setPage(paramPage);
  };

  const handlePageSizeChange = (pageSize: number) => {
    const nextToken = null;
    setNextToken(nextToken);
    setNextTokens([nextToken]);

    setPage(0);
    setPageSize(pageSize);
  };

  return (
    <>
      <DataGrid
        columns={[
          {
            field: "name",
            flex: 1,
            headerName: "Name",
          },
          {
            field: "url",
            flex: 1,
            headerName: "URL",
          },
          {
            field: "authentication",
            headerName: "Authentication",
            width: 134,
          },
          {
            field: "method",
            headerName: "Method",
            width: 86,
          },
          {
            field: "actions",
            headerName: "Actions",
            renderCell: ({ row }) => (
              <Tooltip title="Edit external API">
                <IconButton
                  onClick={() => setExternalApiToUpdate(row)}
                  size="large"
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            ),
            width: 85,
          },
        ]}
        disableSelectionOnClick
        loading={loading}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        page={page}
        pageSize={pageSize}
        pagination
        paginationMode="server"
        rowCount={
          items.length < pageSize && !nextToken
            ? (nextTokens.length - 1) * pageSize + items.length
            : (nextTokens.length + 1) * pageSize
        }
        rows={items}
        rowsPerPageOptions={[25, 50, 100]}
        sortingOrder={["asc", "desc"]}
      />
      {externalApiToUpdate && (
        <CreateUpdateExternalApiDialog
          externalApi={externalApiToUpdate}
          onClose={handleClose}
          open={Boolean(externalApiToUpdate)}
        />
      )}
    </>
  );
}
