import { AlertColor } from "@mui/material";
import React from "react";
import { PhoneStatus as PhoneStatusEnum, PhoneStatuses } from "../../types";
import { toSentenceCase } from "../../utils/to-sentence-case";
import { Chip } from "../chip";

type PhoneStatusProps = {
  status: PhoneStatusEnum;
};

export default function PhoneStatus({ status }: PhoneStatusProps): JSX.Element {
  const label = toSentenceCase(status) || "";
  let severity: AlertColor;

  switch (status) {
    case PhoneStatuses.invalidNumber:
    case PhoneStatuses.error:
      severity = "error";
      break;
    case PhoneStatuses.callPending:
    case PhoneStatuses.callingNow:
      severity = "info";
      break;
    case PhoneStatuses.callMade:
      severity = "success";
      break;
    default:
      return <>{label}</>;
  }

  return <Chip label={label} severity={severity} />;
}
