import { gql } from "@apollo/client";
import {
  Assignment as AssignmentIcon,
  CallSplit as CallSplitIcon,
  CloudUpload as CloudUploadIcon,
  DynamicForm as DynamicFormIcon,
  PhoneForwarded as PhoneForwardedIcon,
  Settings as SettingsIcon
} from "@mui/icons-material";
import { CssBaseline, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { orderBy, sortBy } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { CallListsSwitch } from ".";
import { ContactsTable } from "../components/contacts-table";
import { StateContext } from "../contexts";
import { isRouteVisible } from "../helpers";
import { useContactCrm, useFeatures } from "../hooks";
import { Campaign, ContactFlowTypes, Route as RouteType } from "../types";
import { AppBar } from "./app-bar";
import CampaignsRoutes from "./campaigns/CampaignsRoutes";
import { ContactFlowsSwitch } from "./contact-flows";
import { Drawer } from "./drawer";
import ExportTemplatesRoutes, { ExportTemplatesConfig } from "./export-templates/ExportTemplatesRoutes";
import { ExternalAPis, ExternalApisConfig } from "./external-apis";
import { PhoneNumbers, PhoneNumbersConfig } from "./phone-numbers";
import { SmsSendersConfig, SmsSendersRoutes } from "./sms-senders/SmsSendersRoutes";
import { SmsTemplatesConfig, SmsTemplatesRoutes } from "./sms-templates/SmsTemplatesRoutes";
import StrategiesRoutes, { StrategiesConfig } from "./strategies/StrategiesRoutes";

const PREFIX = "LIST_CONTACT_FLOW_NAMES";

const classes = {
  content: `${PREFIX}-content`,
  root: `${PREFIX}-root`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.content}`]: {
    backgroundColor: theme.palette.background.default,
    flexGrow: 1,
    // 96px is the height of the app bar.
    marginTop: "96px",
    minHeight: window.innerHeight - 96,
  },

  [`&.${classes.root}`]: {
    display: "flex",
  },
}));

export const LIST_CONTACT_FLOW_NAMES = gql`
  query ListContactFlows {
    listContactFlows(filter: { deleted: { eq: false } }) {
      items {
        id
        name
      }
      nextToken
    }
  }
`;

const disabledComponenet = () => (<Typography align="center" variant="h3" color="error" marginTop={5}>Feature Disabled</Typography>)
const renderRoute = (route: RouteType, index: number): JSX.Element => {
  return <Route
    key={index}
    path={route.path}
    exact={route.exact ?? false}
    component={isRouteVisible(route) ? route.component : disabledComponenet}
  />
}

export default function View() {
  const features = useFeatures();
  const { pathname } = useLocation();
  const { contactFlows } = useContext(StateContext);

  const crm = useContactCrm();
  const [campaigns, setCampaigns] = useState<Campaign[]>();

  const loadCampaigns = async () => {
    const activeCampaigns = (await crm.listCampaigns()).filter(
      (i) => !i.deleted
    );
    const sortedCampaigns = orderBy(
      activeCampaigns,
      [(c) => c.name.toLowerCase()],
      ["asc"]
    );
    setCampaigns(sortedCampaigns);
  };

  useEffect(() => {
    (async () => {
      if (crm) {
        loadCampaigns();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crm]);

  useEffect(() => {
    (async () => {
      if (crm && pathname.startsWith("/campaigns")) {
        loadCampaigns();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crm, pathname]);

  const settingsRoutes: RouteType[] = [
    {
      listIndex: 0,
      component: PhoneNumbers,
      href: PhoneNumbersConfig.path,
      icon: PhoneNumbersConfig.icon,
      path: PhoneNumbersConfig.path,
      text: PhoneNumbersConfig.title,
    },
    {
      listIndex: 1,
      component: StrategiesRoutes,
      condition: features.strategies,
      href: StrategiesConfig.path,
      icon: StrategiesConfig.icon,
      path: StrategiesConfig.path,
      text: StrategiesConfig.title,
    },
    {
      listIndex: 2,
      component: ExportTemplatesRoutes,
      href: ExportTemplatesConfig.path,
      icon: ExportTemplatesConfig.icon,
      path: ExportTemplatesConfig.path,
      text: ExportTemplatesConfig.title,
    },
    {
      listIndex: 3,
      component: ExternalAPis,
      href: ExternalApisConfig.path,
      icon: ExternalApisConfig.icon,
      path: ExternalApisConfig.path,
      text: ExternalApisConfig.title,
    },
    {
      listIndex: 4,
      component: SmsSendersRoutes,
      condition: features.sms,
      href: SmsSendersConfig.path,
      icon: SmsSendersConfig.icon,
      path: SmsSendersConfig.path,
      text: SmsSendersConfig.title,
    },
    {
      listIndex: 5,
      component: SmsTemplatesRoutes,
      condition: features.sms,
      href: SmsTemplatesConfig.path,
      icon: SmsTemplatesConfig.icon,
      path: SmsTemplatesConfig.path,
      text: SmsTemplatesConfig.title,
    },
  ];

  const routes: RouteType[] = [
    {
      component: ContactsTable,
      href: "/contacts",
      icon: <PhoneForwardedIcon />,
      listIndex: 0,
      path: ["/", "/contacts"],
      exact: true,
      text: "Call status",
    },
    {
      component: CampaignsRoutes,
      href: "/campaigns",
      icon: <AssignmentIcon />,
      listIndex: 1,
      path: "/campaigns/:campaignId?",
      routes: (campaigns ?? []).map(({ id, name }) => ({
        href: `/campaigns/${id}`,
        icon: <AssignmentIcon />,
        path: `/campaigns/${id}`,
        text: name,
      })),
      text: "Campaigns",
    },
    {
      component: ContactFlowsSwitch,
      href: "/contactFlows",
      icon: <CallSplitIcon />,
      listIndex: 2,
      path: "/contactFlows/:contactFlowId?",
      routes: sortBy(
        contactFlows?.filter(
          (contactFlow) =>
            !contactFlow.deleted &&
            (!contactFlow.type || contactFlow.type === ContactFlowTypes.CALL)
        ),
        "name"
      )?.map(({ id, name }) => ({
        href: `/contactFlows/${id}`,
        icon: <CallSplitIcon />,
        path: `/contactFlows/${id}`,
        text: name,
      })),
      text: "Call profiles",
    },
    {
      component: ContactFlowsSwitch,
      condition: features.webForms,
      href: "/webformProfiles",
      icon: <DynamicFormIcon />,
      listIndex: 3,
      path: "/webformProfiles/:webformId?",
      routes: sortBy(
        contactFlows?.filter(
          (contactFlow) =>
            !contactFlow.deleted &&
            contactFlow.type === ContactFlowTypes.WEBFORM
        ),
        "name"
      )?.map(({ id, name }) => ({
        href: `/webformProfiles/${id}/website`,
        icon: <DynamicFormIcon />,
        path: `/webformProfiles/${id}/website`,
        text: name,
      })),
      text: "Webform profiles",
    },
    {
      component: CallListsSwitch,
      href: "/callLists",
      icon: <CloudUploadIcon />,
      listIndex: 4,
      path: "/callLists",
      text: "All call lists",
    },
    {
      listIndex: 5,
      component: () => (
        <Switch>
          {settingsRoutes.map(renderRoute)}
        </Switch>
      ),
      href: PhoneNumbersConfig.path,
      icon: <SettingsIcon />,
      text: "Settings",
      routes: settingsRoutes.filter(isRouteVisible),
    }
  ];

  return (
    <Root className={classes.root}>
      <CssBaseline />
      <AppBar />
      <Drawer routes={routes} />
      <main className={classes.content}>
        <Switch>
          {routes.map(renderRoute)}
        </Switch>
      </main>
    </Root>
  );
}
