export default [
  {
    gender: "female",
    id: "Nicole",
    languageCode: "en-AU",
    languageName: "English, Australian",
    name: "Nicole",
  },
  {
    gender: "male",
    id: "Russell",
    languageCode: "en-AU",
    languageName: "English, Australian",
    name: "Russell",
  },
  {
    gender: "female",
    id: "Emma",
    languageCode: "en-GB",
    languageName: "English, British",
    name: "Emma",
  },
  {
    gender: "male",
    id: "Brian",
    languageCode: "en-GB",
    languageName: "English, British",
    name: "Brian",
  },
  {
    gender: "female",
    id: "Amy",
    languageCode: "en-GB",
    languageName: "English, British",
    name: "Amy",
  },
  {
    gender: "female",
    id: "Raveena",
    languageCode: "en-IN",
    languageName: "English, Indian",
    name: "Raveena",
  },
  {
    gender: "female",
    id: "Aditi",
    languageCode: "en-IN",
    languageName: "English, Indian",
    name: "Aditi",
  },
  {
    gender: "female",
    id: "Joanna",
    languageCode: "en-US",
    languageName: "English, US",
    name: "Joanna",
  },
  {
    gender: "female",
    id: "Kendra",
    languageCode: "en-US",
    languageName: "English, US",
    name: "Kendra",
  },
  {
    gender: "female",
    id: "Kimberly",
    languageCode: "en-US",
    languageName: "English, US",
    name: "Kimberly",
  },
  {
    gender: "female",
    id: "Salli",
    languageCode: "en-US",
    languageName: "English, US",
    name: "Salli",
  },
  {
    gender: "male",
    id: "Joey",
    languageCode: "en-US",
    languageName: "English, US",
    name: "Joey",
  },
  {
    gender: "male",
    id: "Matthew",
    languageCode: "en-US",
    languageName: "English, US",
    name: "Matthew",
  },
  {
    gender: "male",
    id: "Geraint",
    languageCode: "en-GB-WLS",
    languageName: "English, Welsh",
    name: "Geraint",
  },
] as const;
