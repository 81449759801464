import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Say } from ".";
import { SelectFormik } from "../../../../components/inputs/Select";

import { FactCheck } from "@mui/icons-material";
import { useField } from "formik";
import { TextFieldFormik } from "../../../../components/inputs";

export const intents = [
  {
    value: "YesNo",
    userFriendly: "Yes/No",
  },
  {
    value: "Number",
    userFriendly: "Number",
  },
  {
    value: "Date",
    userFriendly: "Date",
  },
  {
    value: "OpenEnded",
    userFriendly: "Open-ended (Manual & Web)",
  },
];

type AskProps = {
  audioPromptArn: string;
  name: string;
  text: string;
};

const modalStyle: React.CSSProperties = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "1rem",
  padding: "2rem",
  background: "#fff",
};

export default function Ask({ audioPromptArn, name, text }: AskProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [hasEditValidation, setHasEditValidation] = useState(false);
  const [minLength, setMinLength] = useState("");
  const [maxLength, setMaxLength] = useState("500");

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleMinLengthChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    // Validate that min length is a whole number and not higher than max length
    if (
      Number.isInteger(Number(value)) &&
      (maxLength === "" || parseInt(value) <= parseInt(maxLength))
    ) {
      setMinLength(value);
    }
  };

  const handleMaxLengthChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    // Validate that max length is a whole number and not lower than min length
    if (
      Number.isInteger(Number(value)) &&
      (minLength === "" || parseInt(value) >= parseInt(minLength))
    ) {
      setMaxLength(value);
    }
  };

  const handleSubmit = () => {
    // Perform your desired action with the min and max lengths
    handleClose();
  };
  return (
    <>
      <Say audioPromptArn={audioPromptArn} name={name} text={text} />
      <Grid item xs={2}>
        <SelectFormik
          name={`${name}.intent`}
          label="Expected answer"
          setHasEditValidation={setHasEditValidation}
        >
          {intents.map((intent, index) => (
            <MenuItem key={index} value={intent.value}>
              {intent.userFriendly}
            </MenuItem>
          ))}
        </SelectFormik>
      </Grid>
      <Grid item xs={2}>
        <TextFieldFormik label="Store answer as" name={`${name}.attribute`} />
      </Grid>
      {hasEditValidation && (
        <Grid item>
          <IconButton size="large" onClick={handleOpen}>
            <FactCheck />
          </IconButton>
        </Grid>
      )}
      <Modal open={isOpen} onClose={handleClose}>
        <div className="modal" style={modalStyle}>
          <TextFieldFormik
            name={`${name}.validation.minLength`}
            label="Minimum Length"
            type="number"
            // value={minLength}
            onChange={handleMinLengthChange}
          />
          <TextFieldFormik
            name={`${name}.validation.maxLength`}
            label="Maximum Length"
            type="number"
            // value={maxLength}
            onChange={handleMaxLengthChange}
          />
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </div>
      </Modal>
    </>
  );
}
