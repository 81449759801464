import { Grid } from "@mui/material";
import React from "react";
import {
  ContactsPhoneOutcomesCountByCallListIdChart,
  ContactsPhoneStatusesCountByCallListIdChart
} from ".";

export default function ContactsTableToolbar() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <ContactsPhoneStatusesCountByCallListIdChart />
      </Grid>
      <Grid item xs={6}>
        <ContactsPhoneOutcomesCountByCallListIdChart />
      </Grid>
    </Grid>
  );
}
