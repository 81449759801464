import React from "react";
import { Route, Switch } from "react-router-dom";
import { CallListsTable } from "../../components/call-lists-table";
import { ContactsTable } from "../../components/contacts-table";
import { ContactFlowModules } from "./contact-flow-modules";
import ContactFlows from "./ContactFlows";
import { UpdateContactFlowSettings } from "./update-contact-flow-settings";

export default function ContactFlowsSwitch() {
  const routes = [
    {
      component: UpdateContactFlowSettings,
      path: [
        "/contactFlows/:contactFlowId/settings",
        "/webformProfiles/:contactFlowId/settings",
      ],
    },
    {
      component: ContactsTable,
      path: [
        "/contactFlows/:contactFlowId/callLists/:callListId",
      ],
    },
    // Inbound and outbound need to be seperate so the app rerenders.
    {
      component: ContactFlowModules,
      path: "/contactFlows/:contactFlowId/inbound",
    },
    {
      component: ContactFlowModules,
      path: "/contactFlows/:contactFlowId/outbound",
    },
    {
      component: ContactFlowModules,
      path: [
          "/webformProfiles/:contactFlowId",
          "/webformProfiles/:contactFlowId/website"
      ],
    },
    {
      component: CallListsTable,
      path: [
        "/contactFlows/:contactFlowId/callLists",
      ],
    },
    {
      component: ContactsTable,
      path: [
        "/contactFlows/:contactFlowId",
        "/contactFlows/:contactFlowId/contacts",
      ],
    },
    {
      component: ContactFlows,
      path: ["/contactFlows", "/webformProfiles"],
    },
  ];

  return (
    <Switch>
      {routes.map(({ component, path }, index) => (
        <Route component={component} key={index} path={path} />
      ))}
    </Switch>
  );
}
