import {
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  FormControlLabel,
} from "@mui/material";
import { useField } from "formik";
import React from "react";

type CheckboxProps = MuiCheckboxProps & {
  label: string;
};

export default function Checkbox({ label, ...props }: CheckboxProps) {
  return (
    <FormControlLabel control={<MuiCheckbox {...props} />} label={label} />
  );
}

type CheckboxFormikProps = CheckboxProps & {
  name: string;
};

export function CheckboxFormik(props: CheckboxFormikProps) {
  const [field, { value }, { setValue }] = useField(props.name);
  return (
    <Checkbox
      {...props}
      {...field}
      checked={Boolean(value)}
      onChange={(event) => setValue(event.target.checked)}
    />
  );
}
