/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateVoicemail = /* GraphQL */ `
  subscription OnCreateVoicemail {
    onCreateVoicemail {
      id
      key
      queue
      timestamp
      customerEndpoint
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCallList = /* GraphQL */ `
  subscription OnCreateCallList {
    onCreateCallList {
      id
      csvFields
      fields {
        id
        primaryPhone
        secondaryPhone
      }
      file {
        bucket
        key
        region
      }
      filename
      contactFlowId
      processContacts
      warnings
      errors
      createdBy
      createdAt
      updatedAt
      contacts {
        items {
          id
          callListId
          contactFlowId
          evaluateAfter
          fileId
          primaryCtr
          primaryError
          primaryOutcome
          primaryPhone
          primaryStatus
          secondaryCtr
          secondaryError
          secondaryOutcome
          secondaryPhone
          secondaryStatus
          otherAttributes
          ctr
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateCallList = /* GraphQL */ `
  subscription OnUpdateCallList {
    onUpdateCallList {
      id
      csvFields
      fields {
        id
        primaryPhone
        secondaryPhone
      }
      file {
        bucket
        key
        region
      }
      filename
      contactFlowId
      processContacts
      warnings
      errors
      createdBy
      createdAt
      updatedAt
      contacts {
        items {
          id
          callListId
          contactFlowId
          evaluateAfter
          fileId
          primaryCtr
          primaryError
          primaryOutcome
          primaryPhone
          primaryStatus
          secondaryCtr
          secondaryError
          secondaryOutcome
          secondaryPhone
          secondaryStatus
          otherAttributes
          ctr
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateContact = /* GraphQL */ `
  subscription OnCreateContact {
    onCreateContact {
      id
      callListId
      contactFlowId
      evaluateAfter
      fileId
      primaryCtr
      primaryError
      primaryOutcome
      primaryPhone
      primaryStatus
      primaryVoicemail {
        id
        key
        queue
        timestamp
        customerEndpoint
        createdAt
        updatedAt
      }
      secondaryCtr
      secondaryError
      secondaryOutcome
      secondaryPhone
      secondaryStatus
      secondaryVoicemail {
        id
        key
        queue
        timestamp
        customerEndpoint
        createdAt
        updatedAt
      }
      otherAttributes
      ctr
      voicemail {
        id
        key
        queue
        timestamp
        customerEndpoint
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateContact = /* GraphQL */ `
  subscription OnUpdateContact {
    onUpdateContact {
      id
      callListId
      contactFlowId
      evaluateAfter
      fileId
      primaryCtr
      primaryError
      primaryOutcome
      primaryPhone
      primaryStatus
      primaryVoicemail {
        id
        key
        queue
        timestamp
        customerEndpoint
        createdAt
        updatedAt
      }
      secondaryCtr
      secondaryError
      secondaryOutcome
      secondaryPhone
      secondaryStatus
      secondaryVoicemail {
        id
        key
        queue
        timestamp
        customerEndpoint
        createdAt
        updatedAt
      }
      otherAttributes
      ctr
      voicemail {
        id
        key
        queue
        timestamp
        customerEndpoint
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateContactFlow = /* GraphQL */ `
  subscription OnCreateContactFlow {
    onCreateContactFlow {
      id
      type
      name
      description
      hours {
        sunday {
          from
          to
        }
        monday {
          from
          to
        }
        tuesday {
          from
          to
        }
        wednesday {
          from
          to
        }
        thursday {
          from
          to
        }
        friday {
          from
          to
        }
        saturday {
          from
          to
        }
      }
      runRate
      callsPerMinute
      sourcePhoneNumber
      voice {
        gender
        id
        languageCode
        languageName
        name
      }
      voicemailMessage
      inbound {
        during
        after
      }
      outbound {
        before
        during
        after
      }
      website {
        before
        during
        after
      }
      invitationSms {
        templateId
        attributes
      }
      confirmationSms {
        templateId
        attributes
      }
      paused
      createdBy
      updatedBy
      deleted
      deletedAt
      deletedBy
      createdAt
      updatedAt
      callLists {
        items {
          id
          csvFields
          filename
          contactFlowId
          processContacts
          warnings
          errors
          createdBy
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateContactFlow = /* GraphQL */ `
  subscription OnUpdateContactFlow {
    onUpdateContactFlow {
      id
      type
      name
      description
      hours {
        sunday {
          from
          to
        }
        monday {
          from
          to
        }
        tuesday {
          from
          to
        }
        wednesday {
          from
          to
        }
        thursday {
          from
          to
        }
        friday {
          from
          to
        }
        saturday {
          from
          to
        }
      }
      runRate
      callsPerMinute
      sourcePhoneNumber
      voice {
        gender
        id
        languageCode
        languageName
        name
      }
      voicemailMessage
      inbound {
        during
        after
      }
      outbound {
        before
        during
        after
      }
      website {
        before
        during
        after
      }
      invitationSms {
        templateId
        attributes
      }
      confirmationSms {
        templateId
        attributes
      }
      paused
      createdBy
      updatedBy
      deleted
      deletedAt
      deletedBy
      createdAt
      updatedAt
      callLists {
        items {
          id
          csvFields
          filename
          contactFlowId
          processContacts
          warnings
          errors
          createdBy
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateExternalApi = /* GraphQL */ `
  subscription OnCreateExternalApi {
    onCreateExternalApi {
      id
      apiKey {
        header
        apiKey
      }
      authentication
      method
      name
      payload
      url
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateExternalApi = /* GraphQL */ `
  subscription OnUpdateExternalApi {
    onUpdateExternalApi {
      id
      apiKey {
        header
        apiKey
      }
      authentication
      method
      name
      payload
      url
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePhoneNumber = /* GraphQL */ `
  subscription OnCreatePhoneNumber {
    onCreatePhoneNumber {
      id
      arn
      phoneNumber
      type
      countryCode
      createdAt
      updatedAt
      contactFlow {
        id
        type
        name
        description
        runRate
        callsPerMinute
        sourcePhoneNumber
        voice {
          gender
          id
          languageCode
          languageName
          name
        }
        voicemailMessage
        inbound {
          during
          after
        }
        outbound {
          before
          during
          after
        }
        website {
          before
          during
          after
        }
        invitationSms {
          templateId
          attributes
        }
        confirmationSms {
          templateId
          attributes
        }
        paused
        createdBy
        updatedBy
        deleted
        deletedAt
        deletedBy
        createdAt
        updatedAt
        callLists {
          nextToken
        }
      }
    }
  }
`;
export const onUpdatePhoneNumber = /* GraphQL */ `
  subscription OnUpdatePhoneNumber {
    onUpdatePhoneNumber {
      id
      arn
      phoneNumber
      type
      countryCode
      createdAt
      updatedAt
      contactFlow {
        id
        type
        name
        description
        runRate
        callsPerMinute
        sourcePhoneNumber
        voice {
          gender
          id
          languageCode
          languageName
          name
        }
        voicemailMessage
        inbound {
          during
          after
        }
        outbound {
          before
          during
          after
        }
        website {
          before
          during
          after
        }
        invitationSms {
          templateId
          attributes
        }
        confirmationSms {
          templateId
          attributes
        }
        paused
        createdBy
        updatedBy
        deleted
        deletedAt
        deletedBy
        createdAt
        updatedAt
        callLists {
          nextToken
        }
      }
    }
  }
`;
export const onDeletePhoneNumber = /* GraphQL */ `
  subscription OnDeletePhoneNumber {
    onDeletePhoneNumber {
      id
      arn
      phoneNumber
      type
      countryCode
      createdAt
      updatedAt
      contactFlow {
        id
        type
        name
        description
        runRate
        callsPerMinute
        sourcePhoneNumber
        voice {
          gender
          id
          languageCode
          languageName
          name
        }
        voicemailMessage
        inbound {
          during
          after
        }
        outbound {
          before
          during
          after
        }
        website {
          before
          during
          after
        }
        invitationSms {
          templateId
          attributes
        }
        confirmationSms {
          templateId
          attributes
        }
        paused
        createdBy
        updatedBy
        deleted
        deletedAt
        deletedBy
        createdAt
        updatedAt
        callLists {
          nextToken
        }
      }
    }
  }
`;
