import {
  Add as AddIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  MenuItem,
} from "@mui/material";
import { FieldArray, useFormikContext } from "formik";
import React, {
  Dispatch,
  SetStateAction,
  SyntheticEvent,
  useMemo,
  useState,
} from "react";
import { matchPath, useLocation } from "react-router-dom";
import { ContactFlowModulesFormInner } from "..";
import { getFieldValue } from "../../../../components/formik";
import { SelectFormik, TextFieldFormik } from "../../../../components/inputs";
import { DecisionModule, Module } from "../../../../types";
import { toSentenceCase } from "../../../../utils/to-sentence-case";
import { SelectModuleType } from "../ContactFlowModulesFormInner";
import nameToElement from "./nameToElement";

export const conditions = [
  "Equals",
  "DoesNotEqual",
  "IsLessThan",
  "IsLessOrEqualTo",
  "IsGreaterThan",
  "IsGreaterOrEqualTo",
  "StartsWith",
  "EndsWith",
  "Contains",
];

type DecisionProps = {
  allModules: Module[];
  moduleTypes: string[];
  name: string;
  setValues: Dispatch<SetStateAction<Module[]>>;
};

export default function Decision({
  allModules,
  moduleTypes,
  name,
  setValues,
}: DecisionProps) {
  const { values, setFieldValue } = useFormikContext<DecisionModule>();
  const { pathname } = useLocation();
  const [expanded, setExpanded] = useState(true);

  const element = nameToElement(name, values);

  const handleChange = (
    event: SyntheticEvent<Element, Event>,
    expanded: boolean
  ) => setExpanded(expanded);

  const callProfile = useMemo(
    () => matchPath(pathname, "/contactFlows"),
    [pathname]
  );

  const check = useMemo(() => {
    // If webform profile && prevent infinite render loop.
    if (!callProfile && getFieldValue(`${name}.check`, values) !== "Attribute")
      setFieldValue(`${name}.check`, "Attribute");

    return callProfile ? getFieldValue(`${name}.check`, values) : "Attribute";
  }, [callProfile, name, setFieldValue, values]);

  return (
    <Grid item xs>
      <Accordion expanded={expanded} onChange={handleChange}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="decision-content"
          id="decision-header"
        >
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <SelectModuleType moduleTypes={moduleTypes} name={name} />
            </Grid>
            {callProfile && (
              <Grid item xs>
                <SelectFormik name={`${name}.check`} label="Check">
                  <MenuItem value="Attribute">Attribute</MenuItem>
                  <MenuItem value="HoursOfOperation">
                    Hours of operation
                  </MenuItem>
                </SelectFormik>
              </Grid>
            )}
            {check === "Attribute" ? (
              <>
                <Grid item xs>
                  <TextFieldFormik
                    name={`${name}.attribute`}
                    label="Attribute"
                  />
                </Grid>
                <Grid item xs>
                  <SelectFormik name={`${name}.condition`} label="Condition">
                    {conditions.map((condition, index) => (
                      <MenuItem key={index} value={condition}>
                        {toSentenceCase(condition)}
                      </MenuItem>
                    ))}
                  </SelectFormik>
                </Grid>
                <Grid item xs>
                  <TextFieldFormik name={`${name}.value`} label="Value" />
                </Grid>
              </>
            ) : check === "HoursOfOperation" ? (
              <Grid item xs>
                <SelectFormik name={`${name}.condition`} label="Condition">
                  <MenuItem value="InHours">In hours</MenuItem>
                  <MenuItem value="OutOfHours">Out of hours</MenuItem>
                </SelectFormik>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs>
              <FieldArray
                name={`${name}.modules`}
                render={({ push }) => (
                  <Grid container item spacing={2} xs={12} alignItems="center">
                    <ContactFlowModulesFormInner
                      allModules={allModules}
                      modules={element.modules}
                      moduleTypes={moduleTypes}
                      name={`${name}.modules`}
                      setValues={setValues}
                    />
                    <Grid item xs="auto">
                      <Button
                        onClick={() =>
                          push({
                            id: `${element.id}.${element.modules.length + 1}`,
                          })
                        }
                        startIcon={<AddIcon />}
                      >
                        Add New
                      </Button>
                    </Grid>
                  </Grid>
                )}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}
