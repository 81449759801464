import {
  ApolloQueryResult,
  gql,
  useLazyQuery,
  useSubscription,
} from "@apollo/client";
import {
  ContactPhone as ContactPhoneIcon,
  GetApp as DownloadIcon,
  Phone as PhoneIcon,
  Voicemail as VoicemailIcon,
} from "@mui/icons-material";
import { Badge, Button, Grid, IconButton, Tooltip } from "@mui/material";
import {
  DataGrid,
  GridSortModel,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import { Storage } from "aws-amplify";
import { format } from "date-fns";
import downloadjs from "downloadjs";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { client } from "../../contexts";
import {
  getCallList as GET_CALL_LIST,
  getContactFlow as GET_CONTACT_FLOW,
  listContacts as LIST_CONTACTS,
  listContactsByCallListIdByFileId as LIST_CONTACTS_BY_CALL_LIST_ID_FILE_ID,
  listContactsByContactFlowId as LIST_CONTACTS_BY_CONTACT_FLOW_ID,
} from "../../graphql/queries";
import {
  onUpdateCallList as ON_UPDATE_CALL_LIST,
  onUpdateContact as ON_UPDATE_CONTACT,
} from "../../graphql/subscriptions";
import { useAudioPlayer } from "../../hooks";
import {
  Attributes,
  CallList,
  Contact,
  ContactFlow,
  ContactItem,
  Ctr,
  isAskModule,
  isAttributeModule,
  isCallProfile,
  isDecisionModule,
  Module,
  PhoneOutcome as PhoneOutcomeType,
  PhoneOutcomes,
  PhoneStatus as PhoneStatusType,
  PhoneStatuses,
  Voicemail,
} from "../../types";
import { toSentenceCase } from "../../utils/to-sentence-case";
import { ContactsTableToolbar } from "./contacts-table-toolbar";
import { CTRDialog } from "./ctr-dialog";
import PhoneOutcome from "./PhoneOutcome";
import PhoneStatus from "./PhoneStatus";

const downloadData = async (callListId: string, contactFlowId: string) => {
  const data = await getFullData(callListId, contactFlowId);
  const contactAttributes = await getContactAttributes(callListId);
  const parsedData = parseRows(contactAttributes, data);
  const csv = generateCSV(contactAttributes, parsedData);
  downloadjs(csv, "export.csv", "text/csv");
};

const getFullData = async (callListId: string, contactFlowId: string) => {
  const contactsData = [];

  let nextToken = null;

  if (callListId) {
    do {
      const result: ApolloQueryResult<{
        listContactsByCallListIdByFileId: {
          items: ContactItem[];
          nextToken?: string;
        };
      }> = await client.query({
        query: gql(LIST_CONTACTS_BY_CALL_LIST_ID_FILE_ID),
        variables: { callListId, nextToken },
      });

      nextToken = result.data.listContactsByCallListIdByFileId.nextToken;
      contactsData.push(...result.data.listContactsByCallListIdByFileId.items);
    } while (nextToken);
  } else if (contactFlowId) {
    do {
      const result: ApolloQueryResult<{
        listContactsByContactFlowId: {
          items: ContactItem[];
          nextToken?: string;
        };
      }> = await client.query({
        query: gql(LIST_CONTACTS_BY_CONTACT_FLOW_ID),
        variables: { contactFlowId, nextToken },
      });

      nextToken = result.data.listContactsByContactFlowId.nextToken;
      contactsData.push(...result.data.listContactsByContactFlowId.items);
    } while (nextToken);
  } else {
    do {
      const result: ApolloQueryResult<{
        listContacts: {
          items: ContactItem[];
          nextToken?: string;
        };
      }> = await client.query({
        query: gql(LIST_CONTACTS),
        variables: { nextToken },
      });

      nextToken = result.data.listContacts.nextToken;
      contactsData.push(...result.data.listContacts.items);
    } while (nextToken);
  }

  return contactsData;
};

const parseRows = (
  contactAttributes: string[],
  contacts: ContactItem[]
): ContactRow[] => {
  const contactAttributeValues = (ctr: string) => {
    return contactAttributes.reduce<Attributes>((result, attributeName) => {
      const attributeValue = JSON.parse(ctr ?? "{}")?.Attributes?.[
        attributeName.replace(/\s+/g, "")
      ];
      const key = attributeName === "id" ? "_id" : attributeName;
      result[key] = attributeValue;
      return result;
    }, {});
  };

  // Return a separate row for the primary and secondary phone.
  return contacts
    .map((contact) => {
      const rows: ContactRow[] = [];

      if (contact.primaryPhone) {
        rows.push({
          ...contactAttributeValues(contact.primaryCtr ?? contact.ctr),
          ...contact,
          ctr: contact.primaryCtr ?? contact.ctr,
          error: contact.primaryError,
          // Material UI's DataGrid component expects each row to have a unique id.
          id: `${contact.id}${contact.primaryPhone}`,
          outcome: contact.primaryOutcome,
          phone: contact.primaryPhone,
          status: contact.primaryStatus,
          voicemail: contact.primaryVoicemail ?? contact.voicemail,
        });
      }
      if (
        contact.secondaryPhone &&
        // ["callGuardian", "engaged", "error", "invalidNumber", "noAnswer"].some(
        //   (statusOrOutcome) =>
        //     [contact.primaryStatus, contact.primaryOutcome].includes(
        //       statusOrOutcome
        //     )
        [
          PhoneStatuses.error,
          PhoneStatuses.invalidNumber,
          PhoneOutcomes.callGuardian,
          PhoneOutcomes.engaged,
          PhoneOutcomes.noAnswer,
        ].some((statusOrOutcome) =>
          [contact.primaryStatus, contact.primaryOutcome].includes(
            statusOrOutcome
          )
        )
      ) {
        rows.push({
          ...contactAttributeValues(contact.secondaryCtr),
          ...contact,
          ctr: contact.secondaryCtr,
          error: contact.secondaryError,
          // Material UI's DataGrid component expects each row to have a unique id.
          id: `${contact.id}${contact.secondaryPhone}`,
          outcome: contact.secondaryOutcome,
          phone: contact.secondaryPhone,
          status: contact.secondaryStatus,
          voicemail: contact.secondaryVoicemail,
        });
      }

      return rows;
    })
    .flat();
};

type ContactRow = Omit<ContactItem, "ctr"> & {
  ctr: string;
  error?: string;
  phone: string;
  status?: PhoneStatusType;
  initiationTimestamp?: string;
  outcome?: PhoneOutcomeType;
  voicemail?: Voicemail;
  [attributeName: string]: Attributes | string | Voicemail | undefined;
};

const generateCSV = (contactAttributes: string[], data: ContactRow[]) => {
  let csv = "";

  // header
  csv += '"ID","Phone","Status","Initial Timestamp","Outcome"';

  contactAttributes.forEach((attribute) => {
    csv += `,"${attribute}"`;
  });

  data.forEach((row) => {
    csv += "\r\n";

    csv += '"';
    csv += row.fileId;
    csv += '","';
    csv += parsePhoneNumberFromString(
      row.phone || "",
      "GB"
    )?.formatInternational();
    csv += '","';
    csv += row.status ? toSentenceCase(row.status) : "";
    csv += '","';
    csv += row.initiationTimestamp
      ? format(new Date(row.initiationTimestamp), "dd/MM/yyyy HH:mm:ss")
      : "";
    csv += '","';
    csv += row.outcome ? toSentenceCase(row.outcome) : "";
    csv += '"';

    contactAttributes.forEach((attribute) => {
      csv += ',"';
      csv += row[attribute] ? row[attribute] : "";
      csv += '"';
    });
  });

  return csv;
};

async function getContactAttributes(callListId: string): Promise<string[]> {
  if (!callListId) return [];

  const { data: getCallListData, errors: getCallListErrors } =
    await client.query<{ getCallList: CallList }>({
      query: gql(GET_CALL_LIST),
      variables: {
        id: callListId,
      },
    });

  if (getCallListErrors) console.error(getCallListErrors);

  const callList = getCallListData?.getCallList;

  const contactFlowId = callList?.contactFlowId;
  const csvFields = callList?.csvFields || [];

  const { data: getContactFlowData, errors: getContactFlowErrors } =
    await client.query<{
      getContactFlow: ContactFlow;
    }>({
      query: gql(GET_CONTACT_FLOW),
      variables: {
        id: contactFlowId,
      },
    });

  if (getContactFlowErrors) console.error(getContactFlowErrors);

  const outbound =
    isCallProfile(getContactFlowData.getContactFlow) &&
    JSON.parse(getContactFlowData.getContactFlow.outbound.during);

  return [...csvFields, ...getContactFlowAttributes(outbound)];
}

function getContactFlowAttributes(contactFlow: Module[]) {
  return contactFlow.reduce<string[]>((previousValue, currentValue) => {
    if (
      (isAskModule(currentValue) || isAttributeModule(currentValue)) &&
      !previousValue.includes(currentValue.attribute)
    ) {
      previousValue.push(currentValue.attribute);
    } else if (isDecisionModule(currentValue)) {
      const decisionAttributes = getContactFlowAttributes(currentValue.modules);

      if (decisionAttributes.length > 0) {
        previousValue = [...new Set([...previousValue, ...decisionAttributes])];
      }
    }
    return previousValue;
  }, []);
}

export default function ContactsTable() {
  const { playFromUrl } = useAudioPlayer();
  const { callListId, contactFlowId } = useParams<{
    callListId: string;
    contactFlowId: string;
  }>();
  const [contactAttributes, setContactAttributes] = useState<string[]>([]);
  const [ctr, setCtr] = useState<Ctr>();
  const [items, setItems] = useState<ContactItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [nextToken, setNextToken] = useState<string>();
  const [nextTokens, setNextTokens] = useState([nextToken]);
  const [openCtrDialog, setOpenCtrDialog] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [rows, setRows] = useState<ContactRow[]>([]);
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: "fileId", sort: "asc" },
  ]);
  const [listContacts] = useLazyQuery<{
    listContacts: { items: ContactItem[]; nextToken?: string };
  }>(gql(LIST_CONTACTS));
  const [listContactsByContactFlowId] = useLazyQuery<{
    listContactsByContactFlowId: { items: ContactItem[]; nextToken?: string };
  }>(gql(LIST_CONTACTS_BY_CONTACT_FLOW_ID));
  const [listContactsByCallListIdByFileId] = useLazyQuery<{
    listContactsByCallListIdByFileId: {
      items: ContactItem[];
      nextToken?: string;
    };
  }>(gql(LIST_CONTACTS_BY_CALL_LIST_ID_FILE_ID), { fetchPolicy: "no-cache" });

  const listContactsCallback = useCallback(() => {
    (async () => {
      setLoading(true);

      const variables = {
        limit: pageSize,
        nextToken: nextTokens[page],
      };

      if (callListId) {
        const { data } = await listContactsByCallListIdByFileId({
          variables: {
            ...variables,
            callListId,
            sortDirection: sortModel[0].sort?.toUpperCase(),
          },
        });

        setItems(data?.listContactsByCallListIdByFileId.items ?? []);
        setNextToken(data?.listContactsByCallListIdByFileId.nextToken);
      } else if (contactFlowId) {
        const { data } = await listContactsByContactFlowId({
          variables: {
            ...variables,
            contactFlowId,
          },
        });

        setItems(data?.listContactsByContactFlowId.items ?? []);
        setNextToken(data?.listContactsByContactFlowId.nextToken);
      } else {
        const { data } = await listContacts({ variables });

        setItems(data?.listContacts.items ?? []);
        setNextToken(data?.listContacts.nextToken);
      }

      setLoading(false);
    })();
  }, [
    callListId,
    contactFlowId,
    listContacts,
    listContactsByContactFlowId,
    listContactsByCallListIdByFileId,
    nextTokens,
    page,
    pageSize,
    sortModel,
  ]);

  useEffect(() => {
    listContactsCallback();
  }, [listContactsCallback]);

  useEffect(() => {
    (async () => {
      const contactAttributes = await getContactAttributes(callListId);
      setContactAttributes(contactAttributes);
    })();
  }, [callListId]);

  useEffect(() => {
    const parsedRows = parseRows(contactAttributes, items);
    setRows(parsedRows);
  }, [contactAttributes, items]);

  useSubscription<{
    onUpdateContact: ContactItem;
  }>(gql(ON_UPDATE_CONTACT), {
    onSubscriptionData: (options) => {
      if (options.subscriptionData.error)
        console.error(options.subscriptionData.error);
      else if (
        items.some(
          (item) =>
            item.id === options.subscriptionData.data?.onUpdateContact.id
        )
      ) {
        setItems((items) =>
          items.map((item) =>
            item.id === options.subscriptionData.data?.onUpdateContact?.id
              ? { ...item, ...options.subscriptionData.data?.onUpdateContact }
              : item
          )
        );
      }
    },
    shouldResubscribe: true,
  });

  useSubscription<{
    onUpdateCallList: Contact;
  }>(gql(ON_UPDATE_CALL_LIST), {
    onSubscriptionData: (options) => {
      if (options.subscriptionData.error)
        console.error(options.subscriptionData.error);
      else if (
        callListId === options.subscriptionData.data?.onUpdateCallList.id
      ) {
        listContactsCallback();
      }
    },
    shouldResubscribe: true,
  });

  const handleClose = () => setOpenCtrDialog(false);

  const handlePageChange = (pageParam: number) => {
    if (pageParam > page) {
      setNextTokens((nextTokens) => {
        nextTokens.push(nextToken);
        return nextTokens;
      });
    } else if (pageParam < page) {
      setNextTokens((nextTokens) => {
        nextTokens.pop();
        return nextTokens;
      });
    }
    setPage(pageParam);
    listContactsCallback();
  };

  const handlePageSizeChange = (pageSize: number) => {
    const nextToken = undefined;
    setNextToken(nextToken);
    setNextTokens([nextToken]);

    setPage(0);
    setPageSize(pageSize);

    listContactsCallback();
  };

  const handleSortModelChange = (sortModel: GridSortModel) => {
    if (JSON.stringify(sortModel) !== JSON.stringify(sortModel)) {
      const nextToken = undefined;
      setNextToken(nextToken);
      setNextTokens([nextToken]);
      setPage(1);
      setSortModel(sortModel);
    }
  };

  const ExportToolbar = () => {
    return (
      <GridToolbarContainer style={{ justifyContent: "flex-end" }}>
        <Button
          color="primary"
          onClick={(e) => {
            e.preventDefault();
            downloadData(callListId, contactFlowId);
          }}
          startIcon={<DownloadIcon />}
          variant="contained"
        >
          Export
        </Button>
      </GridToolbarContainer>
    );
  };

  return (
    <>
      {callListId && <ContactsTableToolbar />}
      <DataGrid
        components={{
          Toolbar: ExportToolbar,
        }}
        columns={[
          {
            field: "fileId",
            headerName: "Id",
          },
          {
            field: "phone",
            headerName: "Phone",
            renderCell: ({
              row: {
                phone,
                primaryOutcome,
                primaryPhone,
                primaryStatus,
                secondaryPhone,
              },
            }) => {
              return (
                <Grid container spacing={1}>
                  <Grid item xs="auto">
                    {parsePhoneNumberFromString(
                      phone || "",
                      "GB"
                    )?.formatInternational()}
                  </Grid>
                  <Grid item xs="auto">
                    {secondaryPhone &&
                    [
                      PhoneOutcomes.callGuardian,
                      PhoneOutcomes.engaged,
                      PhoneOutcomes.error,
                      PhoneOutcomes.invalidNumber,
                      PhoneOutcomes.noAnswer,
                    ].some((statusOrOutcome) =>
                      [primaryStatus, primaryOutcome].includes(statusOrOutcome)
                    ) ? (
                      <Badge
                        badgeContent={phone === primaryPhone ? 1 : 2}
                        color={phone === primaryPhone ? "primary" : "secondary"}
                      >
                        <PhoneIcon />
                      </Badge>
                    ) : null}
                  </Grid>
                </Grid>
              );
            },
            sortable: false,
            width: 200,
          },
          {
            field: "status",
            headerName: "Status",
            renderCell: ({ value }) => <PhoneStatus status={value} />,
            sortable: false,
            width: 180,
          },
          {
            field: "initiationTimestamp",
            headerName: "Initiation timestamp",
            renderCell: ({ value }) =>
              value && format(new Date(value), "dd/MM/yyyy HH:mm:ss"),
            width: 193,
          },
          {
            field: "outcome",
            headerName: "Outcome",
            renderCell: ({ row: { error, outcome } }) => (
              <PhoneOutcome error={error} outcome={outcome} />
            ),
            sortable: false,
            width: 276,
          },
          ...contactAttributes.map((attribute) => ({
            field: attribute === "id" ? "_id" : attribute,
            headerName: attribute,
          })),
          {
            field: "actions",
            headerName: "Actions",
            renderCell: ({ row: { ctr, voicemail } }) => (
              <>
                {ctr && (
                  <Tooltip title="View call details">
                    <IconButton
                      onClick={() => {
                        setCtr(JSON.parse(ctr));
                        setOpenCtrDialog(true);
                      }}
                      size="large"
                    >
                      <ContactPhoneIcon />
                    </IconButton>
                  </Tooltip>
                )}
                {voicemail && (
                  <Tooltip title="Listen to voicemail">
                    <IconButton
                      onClick={() => {
                        Storage.get(voicemail.key, {
                          level: "public",
                        })
                          .then((result) => playFromUrl(result as string))
                          .catch((error) => console.error(error)); // TODO: Display error to user.
                      }}
                      size="large"
                    >
                      <VoicemailIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            ),
            sortable: false,
            width: 135,
          },
        ]}
        disableSelectionOnClick
        loading={loading}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        onSortModelChange={handleSortModelChange}
        page={page}
        pageSize={pageSize}
        pagination
        paginationMode="server"
        rowCount={
          rows?.length < pageSize && !nextToken
            ? (nextTokens.length - 1) * pageSize + rows?.length
            : (nextTokens.length + 1) * pageSize
        }
        rows={rows}
        rowsPerPageOptions={[25, 50, 100]}
        sortingMode={callListId ? "server" : "client"}
        sortingOrder={["asc", "desc"]}
        sortModel={sortModel}
      />
      <CTRDialog open={openCtrDialog} onClose={handleClose} ctr={ctr} />
    </>
  );
}
