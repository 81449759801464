/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createContactFlow = /* GraphQL */ `
  mutation CreateContactFlow(
    $input: CreateContactFlowInput!
    $condition: ModelContactFlowConditionInput
  ) {
    createContactFlow(input: $input, condition: $condition) {
      id
      type
      name
      description
      hours {
        sunday {
          from
          to
        }
        monday {
          from
          to
        }
        tuesday {
          from
          to
        }
        wednesday {
          from
          to
        }
        thursday {
          from
          to
        }
        friday {
          from
          to
        }
        saturday {
          from
          to
        }
      }
      runRate
      callsPerMinute
      sourcePhoneNumber
      voice {
        gender
        id
        languageCode
        languageName
        name
      }
      voicemailMessage
      inbound {
        during
        after
      }
      outbound {
        before
        during
        after
      }
      website {
        before
        during
        after
      }
      invitationSms {
        templateId
        attributes
      }
      confirmationSms {
        templateId
        attributes
      }
      paused
      createdBy
      updatedBy
      deleted
      deletedAt
      deletedBy
      createdAt
      updatedAt
      callLists {
        items {
          id
          csvFields
          filename
          contactFlowId
          processContacts
          warnings
          errors
          createdBy
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateContactFlow = /* GraphQL */ `
  mutation UpdateContactFlow(
    $input: UpdateContactFlowInput!
    $condition: ModelContactFlowConditionInput
  ) {
    updateContactFlow(input: $input, condition: $condition) {
      id
      type
      name
      description
      hours {
        sunday {
          from
          to
        }
        monday {
          from
          to
        }
        tuesday {
          from
          to
        }
        wednesday {
          from
          to
        }
        thursday {
          from
          to
        }
        friday {
          from
          to
        }
        saturday {
          from
          to
        }
      }
      runRate
      callsPerMinute
      sourcePhoneNumber
      voice {
        gender
        id
        languageCode
        languageName
        name
      }
      voicemailMessage
      inbound {
        during
        after
      }
      outbound {
        before
        during
        after
      }
      website {
        before
        during
        after
      }
      invitationSms {
        templateId
        attributes
      }
      confirmationSms {
        templateId
        attributes
      }
      paused
      createdBy
      updatedBy
      deleted
      deletedAt
      deletedBy
      createdAt
      updatedAt
      callLists {
        items {
          id
          csvFields
          filename
          contactFlowId
          processContacts
          warnings
          errors
          createdBy
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createExternalApi = /* GraphQL */ `
  mutation CreateExternalApi(
    $input: CreateExternalApiInput!
    $condition: ModelExternalApiConditionInput
  ) {
    createExternalApi(input: $input, condition: $condition) {
      id
      apiKey {
        header
        apiKey
      }
      authentication
      method
      name
      payload
      url
      createdAt
      updatedAt
    }
  }
`;
export const updateExternalApi = /* GraphQL */ `
  mutation UpdateExternalApi(
    $input: UpdateExternalApiInput!
    $condition: ModelExternalApiConditionInput
  ) {
    updateExternalApi(input: $input, condition: $condition) {
      id
      apiKey {
        header
        apiKey
      }
      authentication
      method
      name
      payload
      url
      createdAt
      updatedAt
    }
  }
`;
export const updatePhoneNumber = /* GraphQL */ `
  mutation UpdatePhoneNumber(
    $input: UpdatePhoneNumberInput!
    $condition: ModelPhoneNumberConditionInput
  ) {
    updatePhoneNumber(input: $input, condition: $condition) {
      id
      arn
      phoneNumber
      type
      countryCode
      createdAt
      updatedAt
      contactFlow {
        id
        type
        name
        description
        runRate
        callsPerMinute
        sourcePhoneNumber
        voice {
          gender
          id
          languageCode
          languageName
          name
        }
        voicemailMessage
        inbound {
          during
          after
        }
        outbound {
          before
          during
          after
        }
        website {
          before
          during
          after
        }
        invitationSms {
          templateId
          attributes
        }
        confirmationSms {
          templateId
          attributes
        }
        paused
        createdBy
        updatedBy
        deleted
        deletedAt
        deletedBy
        createdAt
        updatedAt
        callLists {
          nextToken
        }
      }
    }
  }
`;
export const createCallList = /* GraphQL */ `
  mutation CreateCallList(
    $input: CreateCallListInput!
    $condition: ModelCallListConditionInput
  ) {
    createCallList(input: $input, condition: $condition) {
      id
      csvFields
      fields {
        id
        primaryPhone
        secondaryPhone
      }
      file {
        bucket
        key
        region
      }
      filename
      contactFlowId
      processContacts
      warnings
      errors
      createdBy
      createdAt
      updatedAt
      contacts {
        items {
          id
          callListId
          contactFlowId
          evaluateAfter
          fileId
          primaryCtr
          primaryError
          primaryOutcome
          primaryPhone
          primaryStatus
          secondaryCtr
          secondaryError
          secondaryOutcome
          secondaryPhone
          secondaryStatus
          otherAttributes
          ctr
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateCallList = /* GraphQL */ `
  mutation UpdateCallList(
    $input: UpdateCallListInput!
    $condition: ModelCallListConditionInput
  ) {
    updateCallList(input: $input, condition: $condition) {
      id
      csvFields
      fields {
        id
        primaryPhone
        secondaryPhone
      }
      file {
        bucket
        key
        region
      }
      filename
      contactFlowId
      processContacts
      warnings
      errors
      createdBy
      createdAt
      updatedAt
      contacts {
        items {
          id
          callListId
          contactFlowId
          evaluateAfter
          fileId
          primaryCtr
          primaryError
          primaryOutcome
          primaryPhone
          primaryStatus
          secondaryCtr
          secondaryError
          secondaryOutcome
          secondaryPhone
          secondaryStatus
          otherAttributes
          ctr
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createContact = /* GraphQL */ `
  mutation CreateContact(
    $input: CreateContactInput!
    $condition: ModelContactConditionInput
  ) {
    createContact(input: $input, condition: $condition) {
      id
      callListId
      contactFlowId
      evaluateAfter
      fileId
      primaryCtr
      primaryError
      primaryOutcome
      primaryPhone
      primaryStatus
      primaryVoicemail {
        id
        key
        queue
        timestamp
        customerEndpoint
        createdAt
        updatedAt
      }
      secondaryCtr
      secondaryError
      secondaryOutcome
      secondaryPhone
      secondaryStatus
      secondaryVoicemail {
        id
        key
        queue
        timestamp
        customerEndpoint
        createdAt
        updatedAt
      }
      otherAttributes
      ctr
      voicemail {
        id
        key
        queue
        timestamp
        customerEndpoint
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateContact = /* GraphQL */ `
  mutation UpdateContact(
    $input: UpdateContactInput!
    $condition: ModelContactConditionInput
  ) {
    updateContact(input: $input, condition: $condition) {
      id
      callListId
      contactFlowId
      evaluateAfter
      fileId
      primaryCtr
      primaryError
      primaryOutcome
      primaryPhone
      primaryStatus
      primaryVoicemail {
        id
        key
        queue
        timestamp
        customerEndpoint
        createdAt
        updatedAt
      }
      secondaryCtr
      secondaryError
      secondaryOutcome
      secondaryPhone
      secondaryStatus
      secondaryVoicemail {
        id
        key
        queue
        timestamp
        customerEndpoint
        createdAt
        updatedAt
      }
      otherAttributes
      ctr
      voicemail {
        id
        key
        queue
        timestamp
        customerEndpoint
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPhoneNumber = /* GraphQL */ `
  mutation CreatePhoneNumber(
    $input: CreatePhoneNumberInput!
    $condition: ModelPhoneNumberConditionInput
  ) {
    createPhoneNumber(input: $input, condition: $condition) {
      id
      arn
      phoneNumber
      type
      countryCode
      createdAt
      updatedAt
      contactFlow {
        id
        type
        name
        description
        runRate
        callsPerMinute
        sourcePhoneNumber
        voice {
          gender
          id
          languageCode
          languageName
          name
        }
        voicemailMessage
        inbound {
          during
          after
        }
        outbound {
          before
          during
          after
        }
        website {
          before
          during
          after
        }
        invitationSms {
          templateId
          attributes
        }
        confirmationSms {
          templateId
          attributes
        }
        paused
        createdBy
        updatedBy
        deleted
        deletedAt
        deletedBy
        createdAt
        updatedAt
        callLists {
          nextToken
        }
      }
    }
  }
`;
export const deletePhoneNumber = /* GraphQL */ `
  mutation DeletePhoneNumber(
    $input: DeletePhoneNumberInput!
    $condition: ModelPhoneNumberConditionInput
  ) {
    deletePhoneNumber(input: $input, condition: $condition) {
      id
      arn
      phoneNumber
      type
      countryCode
      createdAt
      updatedAt
      contactFlow {
        id
        type
        name
        description
        runRate
        callsPerMinute
        sourcePhoneNumber
        voice {
          gender
          id
          languageCode
          languageName
          name
        }
        voicemailMessage
        inbound {
          during
          after
        }
        outbound {
          before
          during
          after
        }
        website {
          before
          during
          after
        }
        invitationSms {
          templateId
          attributes
        }
        confirmationSms {
          templateId
          attributes
        }
        paused
        createdBy
        updatedBy
        deleted
        deletedAt
        deletedBy
        createdAt
        updatedAt
        callLists {
          nextToken
        }
      }
    }
  }
`;
export const createVoicemail = /* GraphQL */ `
  mutation CreateVoicemail(
    $input: CreateVoicemailInput!
    $condition: ModelVoicemailConditionInput
  ) {
    createVoicemail(input: $input, condition: $condition) {
      id
      key
      queue
      timestamp
      customerEndpoint
      createdAt
      updatedAt
    }
  }
`;
