import { Button, DialogActions, Grid, MenuItem } from "@mui/material";
import { Form } from "formik";
import React, { Dispatch, SetStateAction } from "react";
import * as yup from "yup";
import { DialogContent } from "../../../../components/dialog";
import { Formik } from "../../../../components/formik";
import { SelectFormik } from "../../../../components/inputs";
import { Fields } from "../../../../types";
import { toSentenceCase } from "../../../../utils/to-sentence-case";

type SelectContactFlowProps = {
  fields: Fields;
  setFields: Dispatch<SetStateAction<Fields>>;
  csvFields: string[];
  handleBack: () => void;
  handleNext: () => void;
};

export default function SelectContactFlow({
  fields,
  setFields,
  csvFields,
  handleBack,
  handleNext,
}: SelectContactFlowProps) {
  const validationSchema = yup.object().shape({
    id: yup
      .string()
      .oneOf(csvFields, `Id must be one of ${csvFields}`)
      .required("Required"),
    primaryPhone: yup
      .string()
      .oneOf(csvFields, `Primary phone must be one of ${csvFields}`)
      .required("Required"),
    secondaryPhone: yup
      .string()
      .oneOf(csvFields, `Secondary phone must be one of ${csvFields}`),
  });

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={fields}
      onSubmit={async (values) => {
        setFields(values);
        handleNext();
      }}
    >
      <Form>
        <DialogContent>
          <Grid container spacing={2}>
            {Object.keys(fields).map((field, index) => (
              <Grid key={index} item xs={12}>
                <SelectFormik name={field} label={toSentenceCase(field)}>
                  {csvFields.map((csvField, index) => (
                    <MenuItem key={index} value={csvField}>
                      {csvField}
                    </MenuItem>
                  ))}
                </SelectFormik>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleBack}>Back</Button>
          <Button color="primary" type="submit" variant="contained">
            Next
          </Button>
        </DialogActions>
      </Form>
    </Formik>
  );
}
