import { Refresh as RefreshIcon, Send as SendIcon } from "@mui/icons-material";
import {Alert, Box, Grid, IconButton, Theme, Typography, useTheme} from "@mui/material";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Auth } from "aws-amplify";
import { format } from "date-fns";
import { orderBy } from "lodash";
import React, { FormEvent, useEffect, useRef, useState } from "react";
import { Chip } from "../../../components/chip";
import useContactCrm from "../../../hooks/useContactCrm";
import {Campaign, ICSSProperties, Upload, UploadStatuses} from "../../../types";

const useStyles = (theme: Theme): ICSSProperties => ({
  root: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(5),
  },

  uploadForm: {
    border: "1px solid #e3e3e3",
    padding: "20px 30px",
    marginTop: "20px",
    marginBottom: "20px",
    backgroundColor: "#ffffff",
    textAlign: "center",
    borderRadius: "3px",
    position: "relative",
  },

  uploadFileInput: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  chooseFileButton: {},

  uploadFileName: {
    display: "block",
    marginLeft: theme.spacing(1),
  },

  uploadButton: {
    marginTop: theme.spacing(1),
  },

  uploadFormErrors: {
    marginTop: theme.spacing(2),
  },

  uploadFormError: {
    marginBottom: theme.spacing(1),
    padding: "0 8px",
  },

  uploadFormUploading: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    backgroundColor: "#ffffff",
    fontWeight: "600",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& > div": {
      marginRight: theme.spacing(1),
    },
  },

  fileUploadOverlay: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    backgroundColor: "#ffffff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 10,
    "& > svg": {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
      fontSize: "2.2rem",
      color: "#f70",
    },
  },

  loadingUploads: {
    padding: theme.spacing(5),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& > div": {
      marginRight: theme.spacing(1),
    },
  },

  noUploads: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textAlign: "center",
    border: "1px solid #e3e3e3",
    borderRadius: "3px",
    backgroundColor: "#ffffff",
  },

  tableContainer: {
    marginBottom: "20px",
  },
});

type CampaignCustomerUploadsProps = {
  campaign: Campaign;
  triggerReloadCampaign: () => void;
};

export default function CampaignCustomerUploads({
  campaign,
  triggerReloadCampaign,
}: CampaignCustomerUploadsProps) {
  const theme = useTheme()
  const styles = useStyles(theme);

  const campaignId = campaign.id;
  const contactCrmUploadUrl =
    process.env?.REACT_APP_CONTACT_CRM_URL +
    `/campaign/${campaignId}/customers`;

  // We now have a variable number of profiles, we only check if there are values
  // the UI should block someone from saving a partial strategy config
  const uploadsEnabled = Object
      .values(campaign.strategyConfig ?? {})
      .filter((i) => !!i).length > 0;

  // CRM
  const crm = useContactCrm();

  // Local state
  const [token, setToken] = useState<string>("");
  const [loadingUploads, setLoadingUploads] = useState(true);
  const [uploads, setUploads] = useState<Upload[]>();
  const [uploadErrors, setUploadErrors] = useState<string[]>();
  const [uploadingCampaignCustomers, setUploadingCampaignCustomers] =
    useState(false);
  const [uploadFile, setUploadFile] = useState<File>();

  // Refs
  const formRef = useRef<HTMLFormElement | null>(null);

  useEffect(() => {
    (async () => {
      const currentSession = await Auth.currentSession();
      setToken(currentSession.getIdToken().getJwtToken());
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadUploads = async (campaignId: string) => {
    setLoadingUploads(true);
    const tmpUploads = await crm.listCampaignUploads(campaignId);
    const uploads = orderBy(
      Object.keys(tmpUploads).map((k) => tmpUploads[k]),
      ["createdWhen"],
      ["desc"]
    );
    setUploads(uploads);
    setLoadingUploads(false);
  };

  useEffect(() => {
    if (crm && campaignId) {
      loadUploads(campaignId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crm, campaignId]);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!uploadErrors && uploadFile) {
      setUploadingCampaignCustomers(true);
      FileUpload(uploadFile);
    }
  };

  // TODO move to the ContactCRM service, as suggested by James
  function FileUpload(file: File) {
    const reader = new FileReader();
    const xhr = new XMLHttpRequest();

    xhr.open("POST", `${contactCrmUploadUrl}?filename=${file.name || ""}`);
    xhr.setRequestHeader("Content-Type", "text/csv");
    xhr.setRequestHeader("authorization", token);

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        const response = JSON.parse(xhr.responseText);

        if (xhr.status >= 200 && xhr.status <= 299) {
          // reset the form
          formRef.current && formRef.current.reset();

          setUploadFile(undefined);
          setUploadingCampaignCustomers(false);
          triggerReloadCampaign();
          loadUploads(campaignId);
        } else {
          // reset the form
          formRef.current && formRef.current.reset();

          setUploadFile(undefined);
          setUploadingCampaignCustomers(false);

          // show errors
          const errors = [];
          errors.push(`${response.errorCode} - ${response.errorMessage}`);

          if (response?.errors?.length > 0) {
            errors.push(...response.errors.slice(0, 5));
          }

          setUploadErrors(errors);
        }
      }
    };

    reader.onload = function (evt) {
      xhr.send(evt.target?.result);
    };

    reader.readAsBinaryString(file);
  }

  const onChange = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const file = (e.target as HTMLInputElement).files?.[0];
    const errors = [];

    // if (file?.type !== 'text/csv') {
    // errors.push('File must be a CSV')
    // }

    if (file?.size && file.size > 5242880) {
      errors.push("File is too big, maximum file size is 5MB");
    }

    if (errors && errors.length > 0) {
      // reset the form
      formRef.current && formRef.current.reset();

      setUploadFile(undefined);
      setUploadErrors(errors);
    } else {
      setUploadFile(file);
      setUploadErrors(undefined);
    }
  };

  return (
    <Grid item xs={12}>
      <Typography component="h5" variant="h5">
        Customer Uploads
        <IconButton
          aria-label="refresh"
          color="primary"
          onClick={() => loadUploads(campaignId)}
          size="large"
        >
          <RefreshIcon />
        </IconButton>
      </Typography>

      <Box sx={styles.uploadForm}>
        {!uploadsEnabled && (
          <Box sx={styles.fileUploadOverlay}>
            <p>
              Please define the campaign strategy
              <br />
              before uploading customers
            </p>
          </Box>
        )}
        <form onSubmit={onSubmit} onChange={onChange} ref={formRef}>
          <Box sx={styles.uploadFileInput}>
            <input
              accept="text/csv"
              style={{ display: "none" }}
              id="upload-button-file"
              type="file"
            />
            <label htmlFor="upload-button-file">
              <Button
                color="primary"
                variant="contained"
                component="span"
                sx={styles.chooseFileButton}
                size="small"
              >
                Upload file
              </Button>
            </label>
            {uploadFile && uploadFile.name && (
              <span style={styles.uploadFileName}>{uploadFile.name}</span>
            )}
          </Box>

          {!uploadErrors && uploadFile && (
            <Box sx={styles.uploadButton}>
              <Button
                color="primary"
                variant="contained"
                component="button"
                type="submit"
                size="small"
                endIcon={<SendIcon />}
              >
                Upload
              </Button>
            </Box>
          )}
        </form>

        {uploadErrors && (
          <Box sx={styles.uploadFormErrors}>
            {uploadErrors.map((error) => (
              <Alert
                severity="error"
                sx={styles.uploadFormError}
                key={error}
              >
                <span>{error}</span>
              </Alert>
            ))}
          </Box>
        )}

        {uploadingCampaignCustomers && (
          <Box sx={styles.uploadFormUploading}>
            <CircularProgress size={20} />
            <span>Uploading ...</span>
          </Box>
        )}
      </Box>

      {loadingUploads && (
        <Box sx={styles.loadingUploads}>
          <CircularProgress size={30} />
        </Box>
      )}

      {!loadingUploads && uploads && uploads.length === 0 && (
        <Box sx={styles.noUploads}>No uploads</Box>
      )}

      {!loadingUploads && uploads && uploads.length > 0 && (
        <TableContainer component={Paper} sx={styles.tableContainer}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell>Filename</TableCell>
                <TableCell>Uploader</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {uploads.map((upload) => (
                <TableRow key={upload.uploadId}>
                  <TableCell>{upload.filename}</TableCell>
                  <TableCell>{upload.uploadedBy}</TableCell>
                  <TableCell>
                    {format(new Date(upload.createdWhen), "dd/MM/yyyy HH:mm")}
                  </TableCell>
                  <TableCell>
                    {upload.status === UploadStatuses.COMPLETED && (
                      <Chip label={"Upload completed"} severity={"success"} />
                    )}
                    {upload.status === UploadStatuses.FAILED && (
                      <Chip label={"Upload failed"} severity={"error"} />
                    )}
                    {upload.status === UploadStatuses.IN_PROGRESS && (
                      <Chip label={"Upload in progress"} severity={"info"} />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Grid>
  );
}
