import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import { ButtonGroup, Collapse, IconButton, List } from "@mui/material";
import React, { useState } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { RouteListItem } from ".";
import { Route } from "../../types";

type NestedRouteListItemProps = {
  route: Route;
  routes: Omit<Route, "listIndex">[];
  selected: boolean;
};

export default function NestedRouteListItem({
  route,
  routes,
  selected,
}: NestedRouteListItemProps) {
  const [open, setOpen] = useState(true);
  const { pathname } = useLocation();

  const handleClick = () => setOpen(!open);

  return (
    <>
      <ButtonGroup
        sx={{
          width: "100%",
        }}
        variant="text"
      >
        <RouteListItem
          route={route}
          selected={selected}
          style={{ borderRight: "none" }}
          sx={{
            height: "100%",
            textAlign: "left",
            textTransform: "none",
          }}
        />
        <IconButton
          onClick={handleClick}
          size="large"
          sx={{
            backgroundColor: selected ? "rgba(0, 0, 0, 0.08)" : undefined,
            borderRadius: 0,
          }}
        >
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </ButtonGroup>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {routes.map(({ path, ...route }, index) => (
            <RouteListItem
              key={index}
              route={route}
              selected={Boolean(matchPath(pathname, { path }))}
              sx={{
                pl: 4,
              }}
            />
          ))}
        </List>
      </Collapse>
    </>
  );
}
