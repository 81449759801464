import {
  Mediation as MediationIcon
} from '@mui/icons-material';
import React from "react";
import { Route, RouteProps, Switch } from "react-router-dom";
import StrategiesPage from "./StrategiesPage";
import StrategySettings from "./StrategySettings";

export const StrategiesConfig = {
  title: 'Strategies',
  icon: <MediationIcon />,
  path: '/strategies'
}

export default function StrategiesRoutes() {
  const routes: RouteProps[] = [
    {
      component: StrategySettings,
      path: [
        `${StrategiesConfig.path}/:strategyId`,
        `${StrategiesConfig.path}/:strategyId/settings`
      ],
    },
    {
      component: StrategiesPage,
      path: StrategiesConfig.path,
    },
  ];

  return (
    <Switch>
      {routes.map(({ component, path }, index) => (
        <Route component={component} key={index} path={path} />
      ))}
    </Switch>
  );
}

