import {
  Button,
  DialogActions,
  FormControlLabel,
  MenuItem,
  Radio,
} from "@mui/material";
import { Form } from "formik";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { sortBy } from "lodash";
import React, { Dispatch, SetStateAction, useContext } from "react";
import * as yup from "yup";
import { DialogContent } from "../../../../components/dialog";
import { Formik } from "../../../../components/formik";
import { RadioGroupFormik, SelectFormik } from "../../../../components/inputs";
import { StateContext } from "../../../../contexts";
import {
  ContactFlow,
  ContactFlowCreateMethods,
  isCallProfile,
} from "../../../../types";

const validationSchema = yup.object().shape({
  id: yup.string().required("Required"),
  createMethod: yup.string().required("Required"),
});

type SelectContactFlowProps = {
  setContactFlow: Dispatch<SetStateAction<Partial<ContactFlow>>>;
  handleBack: () => void;
  handleNext: () => void;
};

export default function SelectContactFlow({
  setContactFlow,
  handleBack,
  handleNext,
}: SelectContactFlowProps) {
  const { contactFlows } = useContext(StateContext);

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        id: "",
        createMethod: ContactFlowCreateMethods.add,
      }}
      onSubmit={async (values) => {
        setContactFlow(values);
        handleNext();
      }}
    >
      <Form>
        <DialogContent>
          <SelectFormik label="Call profile" name="id">
            {sortBy(
              contactFlows?.filter((contactFlow) => !contactFlow.deleted),
              "name"
            )?.map((contactFlow) => (
              <MenuItem key={contactFlow.id} value={contactFlow.id}>
                {contactFlow.name}
                {isCallProfile(contactFlow) &&
                  ` (${parsePhoneNumberFromString(
                    contactFlow.sourcePhoneNumber,
                    "GB"
                  )?.formatInternational()})`}
                {}
              </MenuItem>
            ))}
          </SelectFormik>
          <RadioGroupFormik name="createMethod" label="Create method">
            <FormControlLabel
              value={ContactFlowCreateMethods.add}
              control={<Radio />}
              label="Append to existing call lists"
            />
            <FormControlLabel
              value={ContactFlowCreateMethods.replace}
              control={<Radio />}
              label="Replace existing call lists"
            />
          </RadioGroupFormik>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleBack}>Back</Button>
          <Button color="primary" type="submit" variant="contained">
            Next
          </Button>
        </DialogActions>
      </Form>
    </Formik>
  );
}
