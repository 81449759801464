import { gql, useMutation } from "@apollo/client";
import {
  Delete as DeleteIcon,
  ExpandMore as ExpandMoreIcon,
  Pause as PauseIcon,
  PlayArrow as PlayArrowIcon,
} from "@mui/icons-material";
import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
  List,
  ListItemText,
} from "@mui/material";
import { format, utcToZonedTime } from "date-fns-tz";
import React, { useState } from "react";
import { matchPath, useHistory, useLocation } from "react-router-dom";
import { Chip } from "../../../components/chip";
import { ListItem } from "../../../components/data-display";
import { DAYS } from "../../../constants";
import { updateContactFlow as UPDATE_CONTACT_FLOW } from "../../../graphql/mutations";
import { ContactFlowItem, Hours, isCallProfile } from "../../../types";
import ConfirmDeleteDialog from "./ConfirmDeleteDialog";

function withinHours(hours: Hours) {
  const day = DAYS[new Date().getDay()];
  const from = hours[day]?.from;
  const to = hours[day]?.to;
  if (!(to && from)) return false;
  const timeZone = "Europe/London";
  const nowZonedTime = utcToZonedTime(new Date(), timeZone);
  const now = format(nowZonedTime, "HH:mm", { timeZone });
  return from <= now && now < to;
}

type ContactFlowCardProps = {
  contactFlow: ContactFlowItem;
};

export default function ContactFlowCard({ contactFlow }: ContactFlowCardProps) {
  const [paused, setPaused] = useState(
    isCallProfile(contactFlow) ? contactFlow.paused : undefined
  );

  const { pathname } = useLocation();
  const history = useHistory();
  const [updateContactFlow] = useMutation(gql(UPDATE_CONTACT_FLOW));
  const [expanded, setExpanded] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);

  const isCallProfilePath = matchPath(pathname, { path: "/contactFlows" })
  const isWebformProfilePath = matchPath(pathname, { path: "/webformProfiles" })

  const handleCallProfileCardClick = () => {
      history.push(`/contactFlows/${contactFlow.id}`)
  }

  const handleWebformProfileCardClick = () => {
      history.push(`/webformProfiles/${contactFlow.id}/website`);
  }

  const handlePlayPauseClick = () => {
    setPaused((paused) => !paused);
    updateContactFlow({
      variables: { input: { id: contactFlow.id, paused: !paused } },
    });
  };

  const handleExpandClick = () => setExpanded(!expanded);

  const handleOpenConfirmDeleteDialog = () => setOpenConfirmDeleteDialog(true);
  const handleCloseConfirmDeleteDialog = () =>
    setOpenConfirmDeleteDialog(false);

  return (
    <>
      <Card
        sx={{
          width: 310,
        }}
      >
        {isCallProfilePath &&
        <CardActionArea onClick={handleCallProfileCardClick}>
          <CardHeader title={contactFlow.name} subheader="Call profile"/>
        </CardActionArea>
        }

        {isWebformProfilePath &&
        <CardActionArea onClick={handleWebformProfileCardClick}>
          <CardHeader title={contactFlow.name} subheader="Web profile"/>
        </CardActionArea>
        }

        <CardContent>
          {paused ? (
            <Chip
              label="Paused"
              onClick={handlePlayPauseClick}
              severity="info"
              title="No calls being made. Click to play"
            />
          ) : (isCallProfile(contactFlow) && contactFlow.hours !== undefined && !withinHours(contactFlow?.hours)) ? (
            <Chip
              label="Not within hours"
              onClick={() =>
                history.push(`contactFlows/${contactFlow.id}/settings`)
              }
              severity="info"
              title="No calls being made. Click to update hours"
            />
          ) : (
            <Box height={32} />
          )}
        </CardContent>
        <CardActions disableSpacing>
          {isCallProfile(contactFlow) && (
            <IconButton
              aria-label={paused ? "play" : "pause"}
              onClick={handlePlayPauseClick}
              size="large"
            >
              {paused ? <PlayArrowIcon /> : <PauseIcon />}
            </IconButton>
          )}
          <IconButton
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
            size="large"
            sx={{
              transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
              marginLeft: "auto",
              transition: (theme) =>
                theme.transitions.create("transform", {
                  duration: theme.transitions.duration.shortest,
                }),
            }}
          >
            <ExpandMoreIcon />
          </IconButton>
          <IconButton
            aria-label="delete"
            onClick={handleOpenConfirmDeleteDialog}
            size="large"
          >
            <DeleteIcon />
          </IconButton>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <List>
              <ListItem>
                <ListItemText
                  primary={format(
                    new Date(contactFlow.createdAt),
                    "dd/MM/yyyy HH:mm"
                  )}
                  secondary="Created at"
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={contactFlow.createdBy}
                  secondary="Created by"
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={format(
                    new Date(contactFlow.updatedAt),
                    "dd/MM/yyyy HH:mm"
                  )}
                  secondary="Updated at"
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={contactFlow.updatedBy}
                  secondary="Updated by"
                />
              </ListItem>
            </List>
          </CardContent>
        </Collapse>
      </Card>
      <ConfirmDeleteDialog
        open={openConfirmDeleteDialog}
        contactFlowToDelete={{ id: contactFlow.id, name: contactFlow.name }}
        onClose={handleCloseConfirmDeleteDialog}
      />
    </>
  );
}
