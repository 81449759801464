import {
  SpeedDial as MuiSpeedDial,
  SpeedDialAction,
  SpeedDialIcon
} from "@mui/material";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { SmsSendersConfig } from "../../sms-senders/SmsSendersRoutes";
import { SmsTemplatesConfig } from "../../sms-templates/SmsTemplatesRoutes";

const formatTitle = (title: string) => {
  const trimmed = title.replace(/s$/, '')
  return `Create ${trimmed}`
}

export default function SpeedDial() {
  const history = useHistory();
  const [openSpeedDial, setOpenSpeedDial] = useState(false);

  const handleOpenSpeedDial = () => setOpenSpeedDial(true);
  const handleCloseSpeedDial = () => setOpenSpeedDial(false);

  const actions = [
    {
      icon: SmsTemplatesConfig.icon,
      tooltipTitle: formatTitle(SmsTemplatesConfig.title),
      to: `${SmsTemplatesConfig.path}/create`
    },
    {
      icon: SmsSendersConfig.icon,
      tooltipTitle: formatTitle(SmsSendersConfig.title),
      to: `${SmsSendersConfig.path}/create`
    },
  ];

  return (
    <>
      <MuiSpeedDial
        ariaLabel="SpeedDial"
        direction="down"
        FabProps={{
          color: "primary",
        }}
        icon={<SpeedDialIcon />}
        onClose={handleCloseSpeedDial}
        onOpen={handleOpenSpeedDial}
        open={openSpeedDial}
        sx={{
          position: "absolute",
          top: 20,
          l: 2,
        }}
      >
        {actions.map(({ icon, tooltipTitle, to }, index) => (
            <SpeedDialAction
              key={index}
              icon={icon}
              tooltipTitle={tooltipTitle}
              onClick={() => history.push(to)}
            />
        ))}
      </MuiSpeedDial>
    </>
  );
}
