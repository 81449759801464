import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress, Grid, Theme, useTheme
} from "@mui/material";
import { Form } from "formik";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, Link } from "react-router-dom";
import * as yup from "yup";
import { Alert, Formik } from "../../components/formik";
import {
  TextFieldFormik
} from "../../components/inputs";
import { useContactCrm } from "../../hooks";
import {
  ICSSProperties, SmsSender
} from "../../types";
import ConfirmDeleteDialog from "./dialogs/ConfirmDeleteDialog";
import { SmsSendersConfig } from "./SmsSendersRoutes";

const useStyles = (theme: Theme): ICSSProperties => ({
  root: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(5),
  },
  smsSendersLoading: {
    padding: theme.spacing(10),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& > div": {
      marginRight: theme.spacing(1),
    },
  },
});

const smsSenderSchema: yup.Schema<Omit<SmsSender, "id" | "createdAt">> = yup
  .object({
    type: yup.string().matches(/^internal|external$/).required(),
    name: yup.string().required('Required'),
    externalId: yup.string()
  })
  .required('Required');

const initialStatus = {
  submitted: false,
  errors: null,
};

export default function SmsSendersEdit(
  props: RouteComponentProps<{ smsSenderId: string }>
): JSX.Element {
  const theme = useTheme()
  const styles = useStyles(theme);

  const smsSenderId = props.match.params.smsSenderId;

  // CRM
  const crm = useContactCrm();
  const [loadingSender, setLoadingSender] = useState(true);
  const [loadingSenderError, setLoadingSenderError] = useState(false);
  const [smsSender, setSmsSender] = useState<SmsSender>();
  const [redirect, setRedirect] = useState<string>();

  // inital values
  const [savedValues, setSavedValues] = useState<Omit<SmsSender, "id" | "createdAt">>({
    type: smsSender?.type || 'external',
    name: smsSender?.name || '',
    externalId: smsSender?.externalId || undefined,
  });

  // delete dialog
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const handleOpenConfirmDeleteDialog = () => setOpenConfirmDeleteDialog(true);
  const handleCloseConfirmDeleteDialog = () => setOpenConfirmDeleteDialog(false);

  // load data
  useEffect(() => {
    const loadSmsSender = async () => {
      if (crm) {
        setLoadingSender(true);

        try {
          const res = await crm.getSmsSender(smsSenderId)
          setSavedValues(res);
          setSmsSender(res);
        } catch (err) {
          console.error(err)
          setLoadingSenderError(true)
        }

        setLoadingSender(false);
      }
    };

    loadSmsSender();
  }, [crm]);

  if (loadingSender) {
    return (
      <Box sx={styles.root}>
        <Box sx={styles.smsSendersLoading}>
          <CircularProgress size={20} />
          <span> Loading SMS Sender...</span>
        </Box>
      </Box>
    );
  } else if (loadingSenderError) {
    return (
      <Box sx={styles.root}>
        <p>Failed to load SMS Sender</p>
        <p>
          <Link to={SmsSendersConfig.path}>Back to senders list</Link>
        </p>
      </Box>
    );
  } else {
    return (
      <Box sx={styles.root}>
        <Formik
          validationSchema={smsSenderSchema}
          initialStatus={initialStatus}
          initialValues={savedValues}
          onSubmit={async (values, { setStatus }) => {
            setStatus(initialStatus);
            try {
              const res = await crm.updateSmsSender(
                smsSenderId,
                JSON.stringify({
                  type: values.type,
                  name: values.name,
                  externalId: values?.externalId,
                })
              );
              if (!res) {
                throw new Error("Failed to update SMS Sender")
              }
              setStatus({ submitted: true, error: null });
              setSavedValues(values);
            } catch (err) {
              console.error(err);
              setStatus({ submitted: true, error: err });
            }
          }}
        >
          {({ values }) => (
            <Card>
              <CardContent>
                <Form>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextFieldFormik label="Name" name="name" required />
                    </Grid>

                    <Grid item xs={6}>
                      <TextFieldFormik label="External ID" name="externalId" required={values.type === 'external'} />
                    </Grid>

                    <Grid item container spacing={2} justifyContent="space-between">
                      <Grid item>
                        <Button
                          color="primary"
                          type="submit"
                          variant="contained"
                          disabled={JSON.stringify(savedValues) === JSON.stringify(values)}
                        >
                          Save Sender
                        </Button>
                        <Button href={SmsSendersConfig.path}>
                          Cancel
                        </Button>
                      </Grid>

                      <Grid item>
                        <Button
                          color="error"
                          type="button"
                          variant="outlined"
                          onClick={handleOpenConfirmDeleteDialog}
                        >
                          Delete Sender
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
                <Alert
                  successMessage="SMS Sender updated successfully"
                  errorMessage="Unable to update SMS Sender"
                />
                <ConfirmDeleteDialog
                  open={openConfirmDeleteDialog}
                  senderToDelete={{ id: smsSenderId, name: savedValues.name }}
                  onClose={handleCloseConfirmDeleteDialog}
                />
              </CardContent>
            </Card>
          )}
        </Formik >
      </Box>
    );
  }
};
