import { gql, useMutation } from "@apollo/client";
import { Add as AddIcon } from "@mui/icons-material";
import { Button, Grid, Typography } from "@mui/material";
import { FieldArray, Form } from "formik";
import React, { useEffect, useState } from "react";
import { matchPath, useLocation, useParams } from "react-router-dom";
import * as yup from "yup";
import { Alert as AlertFormik, Formik } from "../../../components/formik";
import { updateContactFlow as UPDATE_CONTACT_FLOW } from "../../../graphql/mutations";
import { useAudioPlayer } from "../../../hooks";
import {
  Module,
  ModuleTypes,
  OutboundContactFlowModulesItem,
} from "../../../types";
import ContactFlowModulesFormInner from "./ContactFlowModulesFormInner";
import { conditions, intents } from "./modules";

// TODO: Correctly validate call and webform profiles.
const modules: yup.Schema<Module> = yup.lazy(() =>
  yup.object().shape({
    type: yup
      .string()
      .oneOf([
        "Say",
        "AudioPrompt",
        "Ask",
        "Decision",
        "Attribute",
        "SendSMS",
        "CallExternalApi",
        "TransferToQueue",
        "TransferToPhoneNumber",
        "TransferToVoicemail",
        "EndCall",
      ])
      .required("Required"),
    prompt: yup
      .string()
      .when("type", (type, schema) =>
        ["Say", "Ask", "TransferToVoicemail"].includes(type)
          ? schema.oneOf(["Audio", "TextToSpeech"])
          : schema
      ),
    text: yup
      .string()
      .when(["prompt", "type"], (prompt, type, schema) =>
        type === "SendSMS"
          ? schema.required("Required")
          : prompt === "TextToSpeech" &&
            ["Say", "Ask", "TransferToVoicemail"].includes(type)
          ? schema.required("Required")
          : schema
      ),
    intent: yup
      .string()
      .oneOf(intents.map(({ value }) => value))
      .when("type", (type, schema) =>
        type === "Ask" ? schema.required("Required") : schema
      ),
    check: yup
      .string()
      .oneOf(["Attribute", "HoursOfOperation"])
      .when("type", (type, schema) =>
        type === "Decision" ? schema.required("Required") : schema
      ),
    attribute: yup
      .string()
      .when(["check", "type"], (check, type, schema) =>
        type === "Ask" ||
        (check === "Attribute" && type === "Decision") ||
        type === "Attribute"
          ? schema
              .required("Required")
              .matches(
                /^[a-zA-Z0-9-_]+$/gim,
                "Attribute keys can include only alphanumeric, dash, and underscore characters."
              )
          : schema
      ),
    condition: yup
      .string()
      .when("check", (check, schema) =>
        check === "Attribute" ? schema.oneOf(conditions) : schema
      )
      .when("check", (check, schema) =>
        check === "HoursOfOperation"
          ? schema.oneOf(["InHours", "OutOfHours"])
          : schema
      )
      .when("type", (type, schema) =>
        type === "Decision" ? schema.required("Required") : schema
      ),
    value: yup
      .string()
      .when(["check", "type"], (check, type, schema) =>
        (check === "Attribute" && type === "Decision") || type === "Attribute"
          ? schema.required("Required")
          : schema
      ),
    modules: yup
      .array()
      .of(modules)
      .when("type", (type, schema) =>
        type === "Decision" ? schema.required("Required") : schema
      ),
    senderId: yup
      .string()
      .when("type", (type, schema) =>
        type === "SendSMS"
          ? schema
              .required("Required")
              .test(
                "is-11-characters-max",
                "Max 11 characters",
                (senderId: string) => senderId.length <= 11
              )
          : schema
      ),
    externalApiId: yup
      .string()
      .when("type", (type, schema) =>
        type === "CallExternalApi" ? schema.required("Required") : schema
      ),
    audioPromptArn: yup
      .string()
      .when(["prompt", "type"], (prompt, type, schema) =>
        ["Say", "Ask", "TransferToVoicemail"].includes(type) &&
        prompt === "Audio"
          ? schema
              .required("Required")
              .matches(
                /^arn:aws:connect:[a-z0-9-]+:[0-9]{10,15}:instance\/.+\/prompt\/.+$/i
              )
          : schema
      ),
    queueId: yup
      .string()
      .when("type", (type, schema) =>
        type === "TransferToQueue" ? schema.required("Required") : schema
      ),
    phoneNumber: yup
      .string()
      .when("type", (type, schema) =>
        type === "TransferToPhoneNumber" ? schema.required("Required") : schema
      ),
    emailTo: yup
      .array()
      .of(yup.string().email("Invalid email address"))
      .when("type", (type, schema) =>
        type === "TransferToVoicemail" ? schema.required("Required") : schema
      ),
    emailSubject: yup
      .string()
      .when("type", (type, schema) =>
        type === "TransferToVoicemail" ? schema.required("Required") : schema
      ),
  })
);

const validationSchema = yup.object().shape({
  before: yup.array(modules),
  during: yup.array(modules).required("Required"),
  after: yup.array(modules),
  voiceId: yup.string(),
});

type ContactFlowModulesFormProps = {
  modules: Omit<OutboundContactFlowModulesItem, "before"> & { before?: string };
  voiceId?: string;
};

export default function ContactFlowModulesForm({
  modules,
  voiceId,
}: ContactFlowModulesFormProps) {
  const { clearAudio } = useAudioPlayer();
  const { pathname } = useLocation();
  const [updateContactFlow] = useMutation(gql(UPDATE_CONTACT_FLOW));
  const { contactFlowId: id } = useParams<{ contactFlowId: string }>();

  const [savedValues, setSavedValues] = useState({
    before: JSON.parse(modules.before ?? "[]"),
    during: JSON.parse(modules.during),
    after: JSON.parse(modules.after),
    voiceId,
  });

  // Profile changed, update script
  useEffect(() => {
    setSavedValues({
      ...{
        before: JSON.parse(modules.before ?? "[]"),
        during: JSON.parse(modules.during),
        after: JSON.parse(modules.after),
        voiceId,
      },
    });
  }, [id]);

  const inbound = matchPath(pathname, {
    path: "/contactFlows/:contactFlowId/inbound",
  });

  const outbound = matchPath(pathname, {
    path: "/contactFlows/:contactFlowId/outbound",
  });

  return (
    <Formik
      enableReinitialize
      initialStatus={{
        submitted: false,
        error: false,
      }}
      initialValues={savedValues}
      onSubmit={async (values, { setStatus }) => {
        clearAudio();
        setStatus({
          submitted: false,
          error: false,
        });
        try {
          const update = {
            variables: {
              input: {
                id,
                ...{
                  [outbound ? "outbound" : inbound ? "inbound" : "website"]: {
                    ...(outbound && { before: JSON.stringify(values.before) }),
                    during: JSON.stringify(values.during),
                    after: JSON.stringify(values.after),
                  },
                },
              },
            },
          };
          await updateContactFlow(update);
          setStatus({
            submitted: true,
            error: false,
          });
          setSavedValues(values);
        } catch (err) {
          console.error(err);
          setStatus({
            submitted: true,
            error: true,
          });
        }
      }}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={validationSchema}
    >
      {({ errors, setValues, isSubmitting, values }) => {
        if (isSubmitting && Object.keys(errors).length > 0)
          console.warn(errors);

        const { before, during, after } = values;

        return (
          <>
            {outbound && (
              <>
                <Typography component="h2" gutterBottom variant="h6">
                  Before
                </Typography>
                <FieldArray
                  name="before"
                  render={({ push }) => (
                    <>
                      <ContactFlowModulesFormInner
                        modules={before}
                        moduleTypes={["CallExternalApi"]}
                        name="before"
                      />
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs="auto">
                          <Button
                            onClick={() =>
                              push({
                                id: `${(before?.length || 0) + 1}`,
                              })
                            }
                            startIcon={<AddIcon />}
                          >
                            Add New
                          </Button>
                        </Grid>
                      </Grid>
                    </>
                  )}
                />
              </>
            )}
            <Typography component="h2" gutterBottom variant="h6">
              During
            </Typography>
            <Form>
              <FieldArray
                name="during"
                render={({ insert, replace }) => (
                  <Grid container spacing={2}>
                    <ContactFlowModulesFormInner
                      allModules={during}
                      modules={during}
                      moduleTypes={[
                        ModuleTypes.Say,
                        ModuleTypes.Ask,
                        ModuleTypes.Decision,
                        ModuleTypes.Attribute,
                        ModuleTypes.End,
                        ...(matchPath(pathname, "/contactFlows")
                          ? [
                              ModuleTypes.SendSMS,
                              ModuleTypes.CallExternalApi,
                              ModuleTypes.TransferToQueue,
                              ModuleTypes.TransferToPhoneNumber,
                              ModuleTypes.TransferToVoicemail,
                            ]
                          : []),
                      ]}
                      name="during"
                      setValues={(modules) => {
                        setValues({ ...values, during: modules });
                      }}
                    />
                    <Grid item xs="auto">
                      <Button
                        onClick={() => {
                          const modulesLength = during.length;
                          insert(modulesLength - 1, {
                            id: `${modulesLength}`,
                          });
                          replace(modulesLength, {
                            id: `${modulesLength + 1}`,
                            type: "EndCall",
                          });
                        }}
                        startIcon={<AddIcon />}
                      >
                        Add New
                      </Button>
                    </Grid>
                  </Grid>
                )}
              />
              <Typography component="h2" gutterBottom variant="h6">
                After
              </Typography>
              <FieldArray
                name="after"
                render={({ push }) => (
                  <>
                    <ContactFlowModulesFormInner
                      modules={after}
                      moduleTypes={["CallExternalApi"]}
                      name="after"
                    />
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs="auto">
                        <Button
                          onClick={() =>
                            push({
                              id: `${after.length + 1}`,
                            })
                          }
                          startIcon={<AddIcon />}
                        >
                          Add New
                        </Button>
                      </Grid>
                    </Grid>
                  </>
                )}
              />
              <AlertFormik
                successMessage={`${
                  inbound || outbound ? "Call" : "Webform"
                } profile updated successfully`}
                errorMessage={`Unable to update ${
                  inbound || outbound ? "call" : "webform"
                } profile`}
              />
              <Grid container spacing={2} alignItems="center">
                {JSON.stringify(savedValues) !== JSON.stringify(values) && (
                  <Grid item xs="auto">
                    <Button color="primary" type="submit" variant="contained">
                      {`Update ${
                        inbound || outbound ? "call" : "webform"
                      } profile`}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Form>
          </>
        );
      }}
    </Formik>
  );
}
