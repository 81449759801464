export type Campaign = {
  id: string;
  deleted?: boolean;
  description: string;
  name: string;
  paused: boolean;
  updatedWhen: string;
  updatedBy: string;
  uploads: object;
  strategyId: string;
  strategyConfig: Record<string, string>;
};

/**
 * Import
 */
export type Upload = {
  uploadId: string;
  filename: string;
  uploadedBy: string;
  createdWhen: string;
  status: UploadStatus;
};

type UploadStatus = `${UploadStatuses}`;

export enum UploadStatuses {
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
  IN_PROGRESS = "IN_PROGRESS",
}
