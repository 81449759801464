import {
  FormControl,
  FormHelperText,
  FormLabel,
  RadioGroup as MuiRadioGroup,
  RadioGroupProps as MuiRadioGroupProps,
} from "@mui/material";
import { useField } from "formik";
import React from "react";

type RadioGroupProps = MuiRadioGroupProps & {
  error: boolean;
  helperText?: string;
  label: string;
};

export default function RadioGroup({
  children,
  error,
  helperText,
  label,
  ...props
}: RadioGroupProps) {
  return (
    <FormControl error={error} fullWidth variant="outlined">
      <FormLabel>{label}</FormLabel>
      <MuiRadioGroup {...props}>{children}</MuiRadioGroup>
      {helperText && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
}

type RadioGroupFormikProps = Omit<RadioGroupProps, "error"> & {
  name: string;
};

export function RadioGroupFormik(props: RadioGroupFormikProps) {
  const [field, { error }] = useField(props.name);
  return (
    <RadioGroup
      {...props}
      {...field}
      error={Boolean(error)}
      helperText={error}
      value={field.value || ""}
    />
  );
}
