import { gql, useMutation, useQuery } from "@apollo/client";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { RecordVoiceOver as RecordVoiceOverIcon } from "@mui/icons-material";
import {
  Alert as MuiAlert,
  Button, Card,
  CardContent,
  CardHeader, Divider, FormControlLabel, Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Radio,
  SelectChangeEvent,
  Tooltip
} from "@mui/material";
import { Form } from "formik";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import React, { useEffect, useMemo, useState } from "react";
import { matchPath, useLocation } from "react-router-dom";
import * as yup from "yup";
import { Alert, Formik } from "../../../components/formik";
import {
  RadioGroupFormik,
  Select,
  SelectFormik,
  TextFieldFormik
} from "../../../components/inputs";
import { DAYS } from "../../../constants";
import { updateContactFlow as UPDATE_CONTACT_FLOW } from "../../../graphql/mutations";
import { useAudioPlayer, useContactCrm } from "../../../hooks";
import { IAnyObject } from "../../../lib/Types";
import {
  CallProfileItem,
  ContactFlowItem,
  Hours,
  isCallProfile,
  isWebformProfile,
  PhoneNumber,
  RunRates,
  SmsTemplate,
  SmsTemplateInstance,
  Voice,
  WebformProfileItem
} from "../../../types";
import voices from "../../../utils/polly/voices";
import { FromTo } from "../../app-bar/tabs/create-contact-flow-dialog";
import { LIST_PHONE_NUMBERS } from "../../phone-numbers";

const hoursValidationSchema: yup.Schema<Hours> = yup.object({
  sunday: yup.object({
    from: (yup.string() as yup.StringSchema<string>).nullable(true),
    to: (yup.string() as yup.StringSchema<string>).nullable(true),
  }),
  monday: yup.object({
    from: (yup.string() as yup.StringSchema<string>).nullable(true),
    to: (yup.string() as yup.StringSchema<string>).nullable(true),
  }),
  tuesday: yup.object({
    from: (yup.string() as yup.StringSchema<string>).nullable(true),
    to: (yup.string() as yup.StringSchema<string>).nullable(true),
  }),
  wednesday: yup.object({
    from: (yup.string() as yup.StringSchema<string>).nullable(true),
    to: (yup.string() as yup.StringSchema<string>).nullable(true),
  }),
  thursday: yup.object({
    from: (yup.string() as yup.StringSchema<string>).nullable(true),
    to: (yup.string() as yup.StringSchema<string>).nullable(true),
  }),
  friday: yup.object({
    from: (yup.string() as yup.StringSchema<string>).nullable(true),
    to: (yup.string() as yup.StringSchema<string>).nullable(true),
  }),
  saturday: yup.object({
    from: (yup.string() as yup.StringSchema<string>).nullable(true),
    to: (yup.string() as yup.StringSchema<string>).nullable(true),
  }),
}).required("Required")

const callProfileValidationSchema: yup.Schema<
  Pick<
    CallProfileItem,
    | "description"
    | "hours"
    | "runRate"
    | "callsPerMinute"
    | "sourcePhoneNumber"
    | "voicemailMessage"
  > & {
    voice: Pick<Voice, "id">;
  }
> = yup.object({
  description: yup.string(),
  hours: hoursValidationSchema,
  runRate: yup
    .mixed<RunRates>()
    .oneOf(Object.values(RunRates))
    .required("Required"),
  callsPerMinute: yup.number().when("runRate", {
    is: "callsPerMinute",
    then: yup
      .number()
      .required("Required")
      .min(1, "Cannot be less than one")
      .integer("Must be an integer"),
  }),
  sourcePhoneNumber: yup
    .string()
    .required("Required")
    .test("is-valid", "Invalid phone number", (sourcePhoneNumber) =>
      Boolean(
        parsePhoneNumberFromString(sourcePhoneNumber || "", "GB")?.isValid()
      )
    ),
  voice: yup
    .object({
      id: yup.string().required("Required"),
    })
    .required("Required"),
  voicemailMessage: yup.string().required("Required"),
}).required("Required");

const smsTemplateInstanceValidationSchema: yup.Schema<SmsTemplateInstance> = yup
  .object({
    templateId: yup
      .string()
      .required("Required"),
    attributes: yup.object<SmsTemplateInstance["attributes"]>().shape({})
  })
  .required("Required");

const webformProfileValidationSchema: yup.Schema<
  Pick<
    WebformProfileItem,
    | "name"
    | "description"
    | "hours"
    | "invitationSms"
    | "confirmationSms"
  >
> = yup.object({
  name: yup.string().required(),
  description: yup.string(),
  invitationSms: smsTemplateInstanceValidationSchema,
  confirmationSms: smsTemplateInstanceValidationSchema,
  hours: hoursValidationSchema,
}).required("Required");

const initialStatus = {
  submitted: false,
  errors: null,
};

type UpdateContactFlowSettingsFormProps = {
  contactFlow: ContactFlowItem;
};

export default function UpdateContactFlowSettingsForm({
  contactFlow,
}: UpdateContactFlowSettingsFormProps) {
  const { pathname } = useLocation();
  const { loading, data } = useQuery<{
    listPhoneNumbers: { items: PhoneNumber[] };
  }>(LIST_PHONE_NUMBERS);


  const callProfile = useMemo(() => {
    return matchPath(pathname, "/contactFlows");
  }, [pathname]);

  const crm = useContactCrm();
  const [loadingSmsTemplates, setLoadingSmsTemplates] = useState(true);
  const [smsTemplates, setTemplates] = useState<SmsTemplate[]>();

  useEffect(() => {
    const loadSmsTemplates = async () => {
      if (crm) {
        setLoadingSmsTemplates(true);
        setTemplates(await crm.listSmsTemplates());
        setLoadingSmsTemplates(false);
      }
    };

    if (isWebformProfile(contactFlow)) {
      loadSmsTemplates();
    }
  }, [crm]);

  const noneSmsTemplateId = '_none'
  const stringifySmsTemplateAttributes = (templateInstance?: SmsTemplateInstance) => {
    if (!templateInstance) return templateInstance

    // remove template
    if (templateInstance.templateId === noneSmsTemplateId) {
      return null
    }

    // remove invalid attributes
    const template = smsTemplates?.find(smsTemplate => smsTemplate.id === templateInstance.templateId)
    if (template) {
      for (const attr of Object.keys(templateInstance.attributes)) {
        if (!template.attributeKeys.includes(attr)) {
          delete templateInstance.attributes[attr]
        }
      }
    }

    return {
      templateId: templateInstance.templateId,
      attributes: JSON.stringify(templateInstance.attributes)
    }
  }

  const parseSmsTemplateAttributes = (templateInstance?: IAnyObject) => {
    // no SMS template
    if (!templateInstance) {
      return { templateId: noneSmsTemplateId }
    }

    return {
      ...templateInstance,
      attributes: typeof templateInstance.attributes === "string"
        ? JSON.parse(templateInstance.attributes)
        : templateInstance.attributes
    }
  }

  const [savedValues, setSavedValues] = useState<Partial<ContactFlowItem>>({
    id: contactFlow?.id,
    name: contactFlow?.name,
    description: contactFlow?.description,
    hours: {
      sunday: {
        from: contactFlow?.hours.sunday?.from ?? null,
        to: contactFlow?.hours.sunday?.to ?? null,
      },
      monday: {
        from: contactFlow?.hours.monday?.from ?? null,
        to: contactFlow?.hours.monday?.to ?? null,
      },
      tuesday: {
        from: contactFlow?.hours.tuesday?.from ?? null,
        to: contactFlow?.hours.tuesday?.to ?? null,
      },
      wednesday: {
        from: contactFlow?.hours.wednesday?.from ?? null,
        to: contactFlow?.hours.wednesday?.to ?? null,
      },
      thursday: {
        from: contactFlow?.hours.thursday?.from ?? null,
        to: contactFlow?.hours.thursday?.to ?? null,
      },
      friday: {
        from: contactFlow?.hours.friday?.from ?? null,
        to: contactFlow?.hours.friday?.to ?? null,
      },
      saturday: {
        from: contactFlow?.hours.saturday?.from ?? null,
        to: contactFlow?.hours.saturday?.to ?? null,
      },
    },
    ...(callProfile && isCallProfile(contactFlow)
      ? {
        runRate: contactFlow?.runRate,
        callsPerMinute: contactFlow?.callsPerMinute,
        sourcePhoneNumber: contactFlow?.sourcePhoneNumber,
        voice: {
          id: contactFlow?.voice.id,
          gender: contactFlow?.voice.gender,
          languageCode: contactFlow?.voice.languageCode,
          languageName: contactFlow?.voice.languageName,
          name: contactFlow?.voice.name,
        },
        voicemailMessage: contactFlow?.voicemailMessage,
      }
      : {}),
    ...(!callProfile && isWebformProfile(contactFlow)
      ? {
        invitationSms: parseSmsTemplateAttributes(contactFlow.invitationSms),
        confirmationSms: parseSmsTemplateAttributes(contactFlow.confirmationSms),
      }
      : {}),
  });
  const [languageName, setLanguageName] = useState(
    isCallProfile(contactFlow) ? contactFlow?.voice.languageName : undefined
  );
  const [voicesByLanguageName, setVoicesByLanguageName] = useState(
    voices.filter((voice) => voice.languageName === languageName)
  );
  const { textToSpeech, clearAudio } = useAudioPlayer();
  const { user } = useAuthenticator();
  const [updateContactFlow] = useMutation(gql(UPDATE_CONTACT_FLOW));

  return (
    <Formik
      validationSchema={
        callProfile
          ? callProfileValidationSchema
          : webformProfileValidationSchema
      }
      initialStatus={initialStatus}
      initialValues={savedValues}
      onSubmit={async (values: IAnyObject, { setStatus }) => {
        clearAudio();
        setStatus(initialStatus);
        try {
          await updateContactFlow({
            variables: {
              input: {
                ...values,
                ...(callProfile && isCallProfile(values)
                  ? {
                    voice: voices.find(
                      (voice) => voice.id === values.voice.id
                    ),
                  }
                  : {}),
                updatedBy: user.attributes?.name,
                // needed because of GraphQL nonsense, and the type being AWSJSON
                ...(values.invitationSms && { invitationSms: stringifySmsTemplateAttributes(values.invitationSms) }),
                ...(values.confirmationSms && { confirmationSms: stringifySmsTemplateAttributes(values.confirmationSms) })
              },
            },
          });
          setStatus({ submitted: true, error: null });
          setSavedValues(values);
        } catch (err) {
          console.error(err);
          setStatus({ submitted: true, error: err });
        }
      }}
    >
      {({ values, setFieldValue, errors }) => {
        if (Object.keys(errors).length > 0) console.warn(errors);

        return (
          <>
            <Form>
              <Grid container spacing={2}>
                <Card sx={{ width: '100%', mb: 2 }}>
                  <CardContent>
                    <Grid item xs={12} sx={{ mb: 2 }}>
                      <TextFieldFormik
                        label="Description"
                        multiline
                        name="description"
                      />
                    </Grid>
                    <Grid container item spacing={2} xs={12}>
                      {DAYS.map((day, index) => (
                        <FromTo
                          day={day}
                          from={values.hours[day]?.from ?? null}
                          key={index}
                          to={values.hours[day]?.to ?? null}
                        />
                      ))}
                    </Grid>
                  </CardContent>
                </Card>

                {callProfile && isCallProfile(values) && (
                  <>
                    <Grid item xs={12}>
                      <RadioGroupFormik label="Run rate" name="runRate">
                        <FormControlLabel
                          control={<Radio />}
                          label="ASAP"
                          value="asap"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          label={
                            <>
                              <TextFieldFormik
                                inputProps={{
                                  min: 1,
                                }}
                                label="Calls per minute"
                                name="callsPerMinute"
                                type="number"
                              />
                            </>
                          }
                          value="callsPerMinute"
                        />
                      </RadioGroupFormik>
                    </Grid>
                    <Grid item xs={12}>
                      {loading ? (
                        <></>
                      ) : data ? (
                        <SelectFormik
                          label="Phone number"
                          name="sourcePhoneNumber"
                        >
                          {data.listPhoneNumbers.items.map(
                            ({ phoneNumber }, index) => (
                              <MenuItem key={index} value={phoneNumber}>
                                {parsePhoneNumberFromString(
                                  phoneNumber,
                                  "GB"
                                )?.formatInternational()}
                              </MenuItem>
                            )
                          )}
                        </SelectFormik>
                      ) : (
                        <MuiAlert severity="error">
                          Unable to list phone numbers.
                        </MuiAlert>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <Select
                        label="Language and Region"
                        value={languageName}
                        onChange={(event: SelectChangeEvent<unknown>) => {
                          clearAudio();
                          const languageName = event.target.value as string;
                          setLanguageName(languageName);
                          const voicesByLanguageName = voices.filter(
                            (voice) => voice.languageName === languageName
                          );
                          setVoicesByLanguageName(voicesByLanguageName);
                          setFieldValue("voice.id", voicesByLanguageName[0].id);
                        }}
                      >
                        {[
                          ...new Set(voices.map((voice) => voice.languageName)),
                        ].map((languageName, index) => (
                          <MenuItem key={index} value={languageName}>
                            {languageName}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                    <Grid item xs={6}>
                      <SelectFormik
                        label="Voice"
                        name="voice.id"
                        onChange={({ target: { value } }) => {
                          clearAudio();
                          setFieldValue("voice.id", value);
                        }}
                        startAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              edge="start"
                              onClick={() =>
                                textToSpeech(
                                  `Hi! My name is ${values?.voice.id} and I sound like this.`,
                                  values?.voice.id
                                )
                              }
                              size="large"
                            >
                              <Tooltip title="Preview audio">
                                <RecordVoiceOverIcon />
                              </Tooltip>
                            </IconButton>
                          </InputAdornment>
                        }
                      >
                        {voicesByLanguageName.map((voice, index) => (
                          <MenuItem key={index} value={voice.id}>
                            {voice.name}
                          </MenuItem>
                        ))}
                      </SelectFormik>
                    </Grid>
                    <Grid item xs={12}>
                      <TextFieldFormik
                        helperText={`This is what ${values?.voice.id} will say if they reach a contact's answering machine.`}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {values?.voicemailMessage && (
                                <IconButton
                                  edge="end"
                                  onClick={() =>
                                    textToSpeech(
                                      values?.voicemailMessage,
                                      values?.voice.id
                                    )
                                  }
                                  size="large"
                                >
                                  <Tooltip title="Preview audio">
                                    <RecordVoiceOverIcon />
                                  </Tooltip>
                                </IconButton>
                              )}
                            </InputAdornment>
                          ),
                        }}
                        label="Outbound voicemail message"
                        multiline={true}
                        name="voicemailMessage"
                      />
                    </Grid>
                  </>
                )}
                {!callProfile && !loadingSmsTemplates && !!smsTemplates && (
                  <>
                    <Card sx={{ width: '100%', mb: 2 }}>
                      <CardHeader title="Invitation SMS" />
                      <CardContent>
                        <Grid container item spacing={2} xs={12}>
                          <Grid item xs={12}>
                            <SelectFormik
                              label="Template"
                              name="invitationSms.templateId"
                              required
                            >
                              <MenuItem key="none" value={noneSmsTemplateId}>--- No SMS ---</MenuItem>
                              {smsTemplates.map((template) => (
                                <MenuItem key={template.id} value={template.id}>{template.name}</MenuItem>
                              ))}
                            </SelectFormik>
                            <Divider sx={{ my: 2 }} />
                          </Grid>
                          <Grid container item spacing={2} xs={12} direction="row-reverse">
                            {smsTemplates.find(template => template.id === (values as WebformProfileItem)?.invitationSms?.templateId)?.attributeKeys.map(attrKey => (
                              <Grid item xs={12} key={attrKey}>
                                <TextFieldFormik
                                  key={attrKey}
                                  label={attrKey}
                                  name={`invitationSms.attributes.${attrKey}`}
                                  required
                                />
                              </Grid>
                            ))}
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>

                    <Card sx={{ width: '100%' }}>
                      <CardHeader title="Confirmation SMS" />
                      <CardContent>
                        <Grid container item spacing={2} xs={12}>
                          <Grid item xs={12}>
                            <SelectFormik
                              label="Template"
                              name="confirmationSms.templateId"
                              required
                            >
                              <MenuItem key="none" value={noneSmsTemplateId}>--- No SMS ---</MenuItem>
                              {smsTemplates.map((template) => (
                                <MenuItem key={template.id} value={template.id}>{template.name}</MenuItem>
                              ))}
                            </SelectFormik>
                            <Divider sx={{ my: 2 }} />
                          </Grid>
                          <Grid container item spacing={2} xs={12} direction="row-reverse">
                            {smsTemplates.find(template => template.id === (values as WebformProfileItem)?.confirmationSms?.templateId)?.attributeKeys.map(attrKey => (
                              <Grid item xs={12} key={attrKey}>
                                <TextFieldFormik
                                  key={attrKey}
                                  label={attrKey}
                                  name={`confirmationSms.attributes.${attrKey}`}
                                  required
                                />
                              </Grid>
                            ))}
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </>
                )}
                <Grid item xs={12}>
                  {JSON.stringify(savedValues) !== JSON.stringify(values) && (
                    <Button color="primary" type="submit" variant="contained">
                      {`Save ${callProfile ? "call" : "webform"} profile`}
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Form>
            <Alert
              successMessage="Call profile updated successfully"
              errorMessage="Unable to update call profile"
            />
          </>
        );
      }}
    </Formik >
  );
}
