import {
  Button,
  DialogActions,
  DialogContentText,
  Grid,
  List,
  ListItemText,
  Typography
} from "@mui/material";
import { ParseResult } from "papaparse";
import React, { Dispatch, SetStateAction, useRef } from "react";
import { CSVReader as RpCSVReader } from "react-papaparse";
import { ListItem } from "../../../../components/data-display";
import { DialogContent } from "../../../../components/dialog";

type CSVReaderProps = {
  setFile: Dispatch<SetStateAction<any>>;
  setCsvFields: Dispatch<SetStateAction<string[]>>;
  handleNext: () => void;
};

export default function CSVReader({
  setFile,
  setCsvFields,
  handleNext,
}: CSVReaderProps) {
  const button = useRef<RpCSVReader<string> | null>(null);

  const handleFileLoad = (data: Array<ParseResult<string>>) =>
    data.length &&
    setCsvFields(data[0].meta.fields?.map((csvField) => csvField.trim()) ?? []);

  const handleError = (err: any, file: any, inputElem: any, reason: any) => {
    console.error({ err, file, inputElem, reason });
  };

  return (
    <RpCSVReader
      ref={button}
      onFileLoad={handleFileLoad}
      onError={handleError}
      noClick
      noProgressBar
      config={{
        header: true,
        skipEmptyLines: true,
      }}
    >
      {({ file }: { file: any }) => {
        if (file) {
          setFile(file);
        }
        return (
          <>
            <DialogContent>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12}>
                  <DialogContentText>
                    To start outbound calls, please upload a .csv file here. The
                    file&apos;s fields should include:
                  </DialogContentText>
                  <List dense>
                    <ListItem>
                      <ListItemText primary="Id" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="PrimaryPhone" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="SecondaryPhone (optional)" />
                    </ListItem>
                  </List>
                  <DialogContentText>
                    Each field is a single word and follows proper
                    capitalisation. If this is not the case, you have the option
                    to select the respective field in the next step.
                  </DialogContentText>
                </Grid>
                <Grid item xs="auto">
                  <Button
                    color="primary"
                    onClick={(event) => {
                      button.current?.open(event);
                    }}
                    variant="contained"
                  >
                    Choose File
                  </Button>
                </Grid>
                <Grid item xs="auto">
                  <Typography>{file ? file.name : "No file chosen"}</Typography>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button disabled={true}>Back</Button>
              <Button
                color="primary"
                disabled={file ? false : true}
                onClick={handleNext}
                variant="contained"
              >
                Next
              </Button>
            </DialogActions>
          </>
        );
      }}
    </RpCSVReader>
  );
}
