import { Toolbar } from "@mui/material";
import React, {ReactChildren} from "react";
import { Route, Switch } from "react-router-dom";
import {ContactFlowTabs, WebformProfileTabs} from ".";

type TabsProps = {
  children?: React.ReactNode
}

export default function Tabs(props: TabsProps) {
  const children = props.children ?? null;

  const routes = [
    {
      component: ContactFlowTabs,
      path: "/contactFlows/:contactFlowId",
    },
    {
      component: WebformProfileTabs,
      path: "/webformProfiles/:contactFlowId",
    },
  ];

  return (
    <Toolbar variant="dense">
      {children}

      <Switch>
        {routes.map(({ component, path }, index) => (
          <Route component={component} key={index} path={path} />
        ))}
      </Switch>
    </Toolbar>
  );
}
