import { Typography } from "@mui/material";
import React from "react";
import { Route, Switch } from "react-router-dom";
import { ExternalApisConfig } from "../../external-apis";
import { PhoneNumbersConfig } from "../../phone-numbers";
import { SmsSendersConfig } from "../../sms-senders/SmsSendersRoutes";
import { SmsTemplatesConfig } from "../../sms-templates/SmsTemplatesRoutes";
import { StrategiesConfig } from "../../strategies/StrategiesRoutes";
import ContactFlowTitle from "./ContactFlowTitle";
import ContactsByCallListByContactFlowTitle from "./ContactsByCallListByContactFlowTitle";
import ContactsByCallListTitle from "./ContactsByCallListTitle";

type RouteType = {
  path: string | string[];
  title: string | JSX.Element;
};

export default function Title() {
  const routes: RouteType[] = [
    {
      path: "/users",
      title: "Users",
    },
    {
      path: PhoneNumbersConfig.path,
      title: PhoneNumbersConfig.title,
    },
    {
      path: ExternalApisConfig.path,
      title: ExternalApisConfig.title,
    },
    {
      path: StrategiesConfig.path,
      title: StrategiesConfig.title,
    },
    {
      path: "/contactFlows/:contactFlowId/callLists/:callListId",
      title: <ContactsByCallListByContactFlowTitle />,
    },
    {
      path: [
        "/contactFlows/:contactFlowId",
        "/contactFlows/:contactFlowId/contacts",
        "/contactFlows/:contactFlowId/callLists",
        "/contactFlows/:contactFlowId/outbound",
        "/contactFlows/:contactFlowId/inbound",
        "/contactFlows/:contactFlowId/settings",
      ],
      title: <ContactFlowTitle />,
    },
    {
      path: "/contactFlows",
      title: "Call profiles",
    },
    {
      path: "/webformProfiles",
      title: "Webform profiles",
    },
    {
      path: SmsTemplatesConfig.path,
      title: SmsTemplatesConfig.title
    },
    {
      path: SmsSendersConfig.path,
      title: SmsSendersConfig.title
    },
    {
      path: "/callLists/:callListId",
      title: <ContactsByCallListTitle />,
    },
    {
      path: "/callLists",
      title: "All call lists",
    },
    {
      path: ["/", "/contacts"],
      title: "Call status",
    },
  ];

  return (
    <Switch>
      {routes.map(({ path, title }, index) => (
        <Route
          component={() => <Typography variant="h6">{title}</Typography>}
          key={index}
          path={path}
        />
      ))}
    </Switch>
  );
}
