import {
  Button,
  Dialog,
  DialogActions,
  DialogContentText, Theme, useTheme,
} from "@mui/material";
import React from "react";
import { DialogContent, DialogTitle } from "../../../components/dialog";
import {ICSSProperties} from "../../../types";

const useStyles = (theme: Theme): ICSSProperties => ({
  itemName: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    textAlign: "center",
    fontWeight: "800",
    fontSize: "1.4rem",
  },

  mainContent: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
  },

  itemAction: {
    textAlign: "center",
    fontSize: "1rem",
  },

  deactivateText: {
    color: theme.palette.error.main,
    fontWeight: "600",
  },
});

type ExportTemplateDeleteDialogProps = {
  name: string;
  clickYes: () => void;
  clickNo: () => void;
  clickClose: () => void;
};

export default function ExportTemplateDeleteDialog({
  name,
  clickYes,
  clickNo,
  clickClose,
}: ExportTemplateDeleteDialogProps) {
  const theme = useTheme()
  const styles = useStyles(theme);

  return (
    <Dialog fullWidth open={true} onClose={clickClose}>
      <DialogTitle onClose={clickClose}>Delete Export Template</DialogTitle>
      <DialogContent sx={styles.mainContent}>
        <DialogContentText sx={styles.itemAction}>
          You are about to{" "}
          <span style={styles.deactivateText}>DELETE</span> the export
          template:
        </DialogContentText>
        <DialogContentText sx={styles.itemName}>
          {name}
        </DialogContentText>
        <DialogContentText sx={styles.itemAction}>
          Are you sure you want to proceed?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={clickNo} type="button">
          No
        </Button>
        <Button
          color="primary"
          onClick={() => {
            clickYes();
            clickClose();
          }}
          type="button"
          variant="contained"
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
