import { Grid } from "@mui/material";
import React from "react";
import { TextFieldFormik } from "../../../../components/inputs";

type TransferToPhoneNumberProps = { name: string };

export default function TransferToPhoneNumber({
  name,
}: TransferToPhoneNumberProps) {
  return (
    <Grid item xs>
      <TextFieldFormik name={`${name}.phoneNumber`} label="Phone number" />
    </Grid>
  );
}
