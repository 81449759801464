import { useMutation } from "@apollo/client";
import { Button, Grid, MenuItem } from "@mui/material";
import { FieldArray, Form } from "formik";
import { gql } from "@apollo/client";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { isEqual, sortBy } from "lodash";
import React, { useState } from "react";
import * as yup from "yup";
import { Alert, Formik } from "../../components/formik";
import { SelectFormik } from "../../components/inputs";
import { updatePhoneNumber as UPDATE_PHONE_NUMBER } from "../../graphql/mutations";
import { ContactFlowItem, PhoneNumber } from "../../types";

const validationSchema = yup.object().shape({
  phoneNumbers: yup.array().of(
    yup.object().shape({
      id: yup.string().required("Required"),
      phoneNumber: yup.string().required("Required"),
      contactFlow: yup
        .object()
        .shape({
          id: yup.string().required("Required"),
        })
        .nullable(),
    })
  ),
});

const initialStatus = {
  submitted: false,
  errors: null,
};

type PhoneNumbersFormProps = {
  contactFlows: ContactFlowItem[];
  phoneNumbers: PhoneNumber[];
};

export default function PhoneNumbersForm({
  contactFlows,
  phoneNumbers,
}: PhoneNumbersFormProps) {
  const [savedValues, setSavedValues] = useState({ phoneNumbers });
  const [updatePhoneNumber] = useMutation(gql(UPDATE_PHONE_NUMBER));

  return (
    <Formik
      validationSchema={validationSchema}
      initialStatus={initialStatus}
      initialValues={savedValues}
      onSubmit={async (values, { setStatus }) => {
        setStatus(initialStatus);
        try {
          for (const valuesPhoneNumber of values.phoneNumbers) {
            const currentPhoneNumber = phoneNumbers.find(
              (phoneNumber) => phoneNumber.id === valuesPhoneNumber.id
            );
            if (!isEqual(valuesPhoneNumber, currentPhoneNumber)) {
              await updatePhoneNumber({
                variables: {
                  input: {
                    id: valuesPhoneNumber.id,
                    phoneNumberContactFlowId: valuesPhoneNumber.contactFlow?.id,
                  },
                },
              });
            }
          }
          setStatus({ submitted: true, error: null });
          setSavedValues({ phoneNumbers: values.phoneNumbers });
        } catch (err) {
          console.error(err);
          setStatus({ submitted: true, error: err });
        }
      }}
    >
      {({ values }) => (
        <>
          <FieldArray
            name="phoneNumbers"
            render={() => (
              <Form>
                <Grid container spacing={2}>
                  {phoneNumbers.map(({ phoneNumber }, index) => (
                    <Grid item key={index} xs={12}>
                      <SelectFormik
                        label={parsePhoneNumberFromString(
                          phoneNumber,
                          "GB"
                        )?.formatInternational()}
                        name={`phoneNumbers.${index}.contactFlow.id`}
                      >
                        {sortBy(contactFlows, "name").map(
                          ({ id, name }, index) => (
                            <MenuItem key={index} value={id}>
                              {name}
                            </MenuItem>
                          )
                        )}
                      </SelectFormik>
                    </Grid>
                  ))}
                  {JSON.stringify(savedValues) !== JSON.stringify(values) && (
                    <Grid item xs="auto">
                      <Button color="primary" type="submit" variant="contained">
                        Save phone numbers
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Form>
            )}
          />
          <Alert
            successMessage="Phone numbers updated successfully"
            errorMessage="Unable to update phone numbers"
          />
        </>
      )}
    </Formik>
  );
}
