import "chart.js/auto";
import { ChartData } from "chart.js/auto";
import React from "react";
import { Chart } from "react-chartjs-2";

type BarProps = {
  data: ChartData<"bar", number[], unknown>;
  title?: string;
};

export default function HorizontalBar({ data, title: text }: BarProps) {
  return (
    <Chart
      type="bar"
      data={data}
      options={{
        indexAxis: "y",
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: Boolean(text),
            text,
          },
        },
        scales: {
          x: {
            min: 0,
            ticks: {
              stepSize: 1,
            },
          },
        },
      }}
    />
  );
}
