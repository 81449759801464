import { Button, Dialog, DialogActions, Grid } from "@mui/material";
import { Form } from "formik";
import React from "react";
import * as yup from "yup";
import { DialogContent, DialogTitle } from "../../../components/dialog";
import { Alert, Formik } from "../../../components/formik";
import { TextFieldFormik } from "../../../components/inputs";
import { ExportTemplate } from "../../../types";

const validationSchema = yup.object().shape({
  name: yup.string().required("Required"),
  description: yup.string().required("Required"),
});

const initialStatus = {
  submitted: false,
  errors: null,
};

type ExportTemplateCreateEditDialogProps = {
  clickYes: (exportTemplate: Partial<ExportTemplate>) => void;
  clickNo: () => void;
  clickClose: () => void;
  mode?: string;
  name?: string;
  description?: string;
};

export default function ExportTemplateCreateEditDialog({
  clickYes,
  clickNo,
  clickClose,
  mode = "create",
  name,
  description,
}: ExportTemplateCreateEditDialogProps) {
  const initialValues = { name, description };

  return (
    <Formik
      validationSchema={validationSchema}
      initialStatus={initialStatus}
      initialValues={initialValues}
      onSubmit={async (values, { setStatus, resetForm }) => {
        setStatus(initialStatus);
        await clickYes(values);
        setStatus({ submitted: true, error: null });
        resetForm();
        clickClose();
      }}
    >
      {() => (
        <>
          <Dialog fullWidth open={true} onClose={clickClose}>
            <Form>
              <DialogTitle onClose={clickClose}>
                {mode === "create" && <>Create template</>}
                {mode === "edit" && <>Edit template</>}
              </DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextFieldFormik label="Name" name="name" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldFormik
                      label="Description"
                      name="description"
                      multiline
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={clickNo} type="button">
                  Cancel
                </Button>
                <Button color="primary" type="submit" variant="contained">
                  {mode === "create" && <>Create</>}
                  {mode === "edit" && <>Save</>}
                </Button>
              </DialogActions>
            </Form>
          </Dialog>
          <Alert
            successMessage="Action successful"
            errorMessage="An error has occurred"
          />
        </>
      )}
    </Formik>
  );
}
