import {
  AddCircle as AddCircleIcon,
  Pause as PauseIcon,
  PlayArrow as PlayArrowIcon,
  Settings as SettingsIcon,
} from "@mui/icons-material";
import {Alert, Box, Chip, Grid, IconButton, Theme, useTheme} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { format } from "date-fns";
import { orderBy } from "lodash";
import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { useContactCrm } from "../../hooks";
import {Campaign, ICSSProperties} from "../../types";
import CampaignCreateEditDialog from "./dialogs/CampaignCreateEditDialog";
import CampaignPauseStartDialog from "./dialogs/CampaignPauseStartDialog";

const useStyles = (theme: Theme): ICSSProperties => ({
  root: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(5),
  },
  campaignsLoading: {
    padding: theme.spacing(10),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& > div": {
      marginRight: theme.spacing(1),
    },
  },
  createCampaignRow: {
    display: "flex",
    justifyContent: "right",
    alignItems: "center",
    "& .MuiChip-icon": {
      fontSize: "32px",
    },
  },
  campaignLink: {
    fontWeight: 600,
    textDecoration: "none",
    color: theme.palette.text.primary,

    "& > .MuiChipRoot": {
      marginLeft: theme.spacing(1),
      fontSize: "0.7rem",
      borderRadius: "12px",
      height: "20px",

      "& > span": {
        padding: "0 6px",
      },
    },
  },
  tableCellManage: {
    "& .MuiChip-root": {
      marginRight: theme.spacing(1.5),
      "&:last-child": {
        marginRight: 0,
      },
    },
  },
  chipPaused: {
    backgroundColor: "rgb(244, 67, 54)",
    color: "#fff",
    height: '20px',
    "& > .MuiChip-label": {
      padding: '0 6px',
      fontSize: '0.7rem',
    },
    "& .MuiChip-icon": {
      color: "#fff",
      marginRight: "-7px",
    },
    "&:hover": {
      backgroundColor: "rgb(227,58,45)",
    },
  },
  chipStarted: {
    backgroundColor: "rgb(76, 175, 80)",
    color: "#fff",
    height: '20px',
    "& > .MuiChip-label": {
      padding: '0 6px',
      fontSize: '0.7rem',
    },
    "& .MuiChip-icon": {
      color: "#fff",
      marginRight: "-7px",
    },
    "&:hover": {
      backgroundColor: "rgb(68,164,72)",
    },
  },
  chipEdit: {
    backgroundColor: theme.palette.grey[800],
    color: "#fff",
    cursor: "pointer",
    "& .MuiChip-icon": {
      color: "#fff",
      marginRight: "-7px",
    },
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
    "& .MuiChip-label": {
      paddingRight: 0,
    },
  },
  campaignDeleted: {
    opacity: "0.5",
    cursor: "not-allowed",
  },

  campaignNotDeleted: {},

  deletedCampaignsContainer: {
    marginTop: theme.spacing(2),
  },
});

export default function CampaignsPage(): JSX.Element {
  const theme = useTheme()
  const styles = useStyles(theme);

  // CRM
  const crm = useContactCrm();
  const [loadingCampaigns, setLoadingCampaigns] = useState(true);
  const [campaigns, setCampaigns] = useState<Campaign[]>();
  const [openCreateCampaignDialog, setOpenCreateCampaignDialog] =
    useState(false);
  const [pauseStartCampaign, setPauseStartCampaign] = useState<Campaign>();
  const [redirect, setRedirect] = useState<string>();

  useEffect(() => {
    const loadCampaigns = async () => {
      if (crm) {
        setLoadingCampaigns(true);
        setCampaigns(await crm.listCampaigns());
        setLoadingCampaigns(false);
      }
    };

    loadCampaigns();
  }, [crm]);

  const createCampaign = async (data: Partial<Campaign>) => {
    const result = await crm.createCampaign(JSON.stringify(data));
    setRedirect(`/campaigns/${result.id}`);
  };

  const changePauseStartCampaign = async (
    campaignId: string,
    paused: boolean
  ) => {
    await crm.updateCampaign(campaignId, JSON.stringify({ paused }));
    setCampaigns(await crm.listCampaigns());
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  if (loadingCampaigns) {
    return (
      <Box sx={styles.root}>
        <Box sx={styles.campaignsLoading}>
          <CircularProgress size={20} />
          <span>Loading campaigns ...</span>
        </Box>
      </Box>
    );
  } else if (!loadingCampaigns && Array.isArray(campaigns)) {
    // data was loaded! but we might not have campaigns
    const activeCampaigns = orderBy(
      campaigns.filter((c) => !c.deleted),
      [(c) => c.name.toLowerCase()],
      ["asc"]
    );
    const deletedCampaigns = orderBy(
      campaigns.filter((c) => c.deleted),
      [(c) => c.name.toLowerCase()],
      ["asc"]
    );

    return (
      <Box sx={styles.root}>
        {openCreateCampaignDialog && (
          <CampaignCreateEditDialog
            clickYes={(data: Partial<Campaign>) => createCampaign(data)}
            clickNo={() => setOpenCreateCampaignDialog(false)}
            clickClose={() => setOpenCreateCampaignDialog(false)}
          />
        )}

        {pauseStartCampaign && (
          <CampaignPauseStartDialog
            name={pauseStartCampaign.name}
            action={pauseStartCampaign.paused ? "start" : "pause"}
            clickYes={() => {
              changePauseStartCampaign(
                pauseStartCampaign.id,
                !pauseStartCampaign.paused
              );
            }}
            clickNo={() => setPauseStartCampaign(undefined)}
            clickClose={() => setPauseStartCampaign(undefined)}
          />
        )}

        <Grid container spacing={2}>
          <Grid item xs={12} sx={styles.createCampaignRow}>
            <span>Add campaign</span>
            <IconButton
              aria-label="settings"
              color="primary"
              onClick={() => setOpenCreateCampaignDialog(true)}
              size="large"
            >
              <AddCircleIcon />
            </IconButton>
          </Grid>

          {activeCampaigns.length > 0 && (
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Updated By</TableCell>
                      <TableCell>Last Updated</TableCell>
                      <TableCell align="right">Manage</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {activeCampaigns.map((campaign) => (
                      <TableRow
                        key={campaign.id}
                        sx={styles.campaignNotDeleted}
                      >
                        <TableCell component="th" scope="row">
                          <Link
                            to={`/campaigns/${campaign.id}`}
                            style={styles.campaignLink}
                          >
                            {campaign.name}{" "}
                            {campaign.paused && (
                              <Chip
                                label={"PAUSED"}
                                size="small"
                                sx={styles.chipPaused}
                              />
                            )}
                            {!campaign.paused && (
                              <Chip
                                label={"RUNNING"}
                                size="small"
                                sx={styles.chipStarted}
                              />
                            )}
                          </Link>
                        </TableCell>
                        <TableCell>{campaign.updatedBy}</TableCell>
                        <TableCell>
                          {format(
                            new Date(campaign.updatedWhen),
                            "dd/MM/yyyy HH:mm"
                          )}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={styles.tableCellManage}
                        >
                          {campaign.paused && (
                            <Chip
                              icon={<PlayArrowIcon />}
                              label={"START"}
                              sx={styles.chipStarted}
                              onClick={() => {
                                setPauseStartCampaign(campaign);
                              }}
                            />
                          )}

                          {!campaign.paused && (
                            <Chip
                              icon={<PauseIcon />}
                              label={"PAUSE"}
                              sx={styles.chipPaused}
                              onClick={() => {
                                setPauseStartCampaign(campaign);
                              }}
                            />
                          )}

                          <Chip
                            icon={<SettingsIcon />}
                            sx={styles.chipEdit}
                            component={Link}
                            to={`/campaigns/${campaign.id}`}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}
        </Grid>

        {deletedCampaigns.length > 0 && (
          <Grid
            container
            spacing={2}
            sx={styles.deletedCampaignsContainer}
          >
            <Grid item xs={12}>
              <Typography variant="h5">Deleted campaigns</Typography>
            </Grid>

            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Updated By</TableCell>
                      <TableCell align="right">Deleted</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {deletedCampaigns.map((campaign) => (
                      <TableRow
                        key={campaign.id}
                        sx={styles[`campaignDeleted`]}
                      >
                        <TableCell component="th" scope="row">
                          {campaign.name}
                        </TableCell>
                        <TableCell>{campaign.updatedBy}</TableCell>
                        <TableCell align="right">DELETED</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        )}

        {campaigns.length === 0 && (
          <Alert severity="info">Start creating campaigns ...</Alert>
        )}
      </Box>
    );
  }

  return <></>;
}
