import { CheckCircle, Error, Info, Warning } from "@mui/icons-material";
import {
  AlertColor,
  Chip as MuiChip,
  ChipProps as MuiChipProps,
  useTheme,
} from "@mui/material";
import React from "react";

type ChipProps = MuiChipProps & {
  severity: AlertColor;
};

export default function Chip({ severity, label, ...props }: ChipProps) {
  const theme = useTheme();

  const backgroundColor = theme.palette[severity]?.main;
  const color = theme.palette[severity]?.contrastText;

  let icon = props.icon;

  switch (severity) {
    case "error":
      icon = <Error style={{ color }} />;
      break;
    case "warning":
      icon = <Warning style={{ color }} />;
      break;
    case "info":
      icon = <Info style={{ color }} />;
      break;
    case "success":
      icon = <CheckCircle style={{ color }} />;
      break;
    default:
      break;
  }

  return (
    <MuiChip
      {...props}
      label={label}
      style={{ backgroundColor, color }}
      icon={icon}
    />
  );
}
