type ApiAuthentication = `${ApiAuthentications}`;
export enum ApiAuthentications {
  API_KEY = "API_KEY",
}

type ApiKey = {
  header: string;
  apiKey: string;
};

type ApiMethod = `${ApiMethods}`;
export enum ApiMethods {
  POST = "POST",
}

export type ExternalApi = {
  id: string;
  apiKey?: ApiKey;
  authentication?: ApiAuthentication;
  method: ApiMethod;
  name: string;
  payload: object;
  url: string;
};

export type ExternalApiItem = Omit<ExternalApi, "payload"> & {
  payload: string;
};