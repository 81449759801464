import {
  Divider,
  Drawer as MuiDrawer,
  Grid,
  List,
  styled,
  Toolbar,
  Typography
} from "@mui/material";
import { sortBy } from "lodash";
import React, { Fragment } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { NestedRouteListItem, RouteListItem } from ".";
import icon from "../../assets/Icon pink400px.png";
import { isRouteVisible } from "../../helpers";
import { Route } from "../../types";

export const drawerWidth = 300;

const StyledDrawer = styled(MuiDrawer)({
  "& .MuiDrawer-paper": {
    width: drawerWidth,
  },
});

type DrawerProps = {
  routes: Route[];
};


export default function Drawer({ routes }: DrawerProps) {
  const { pathname } = useLocation();

  const renderRoute = (route: Route, index: number, routes: Route[]): JSX.Element => {
    if (!isRouteVisible(route)) return <></>
    const listIndex = routes.find((r) =>
      matchPath(pathname, { path: r.path, exact: r.exact ?? false })
    )?.listIndex;

    // nested routes
    if (route.routes?.length) {
      return <NestedRouteListItem
        key={index}
        route={route}
        routes={route.routes}
        selected={
          !routes.find(({ path }) =>
            matchPath(pathname, { path })
          ) && listIndex === index
        }
      />
    }

    // single route
    return <RouteListItem
      key={index}
      route={route}
      selected={listIndex === index}
    // sx={{ p: 0 }}
    />
  }

  return (
    <StyledDrawer
      anchor="left"
      sx={{
        flexShrink: 0,
        width: drawerWidth,
      }}
      variant="permanent"
    >
      <Toolbar variant="dense">
        <Grid alignItems="center" container spacing={2}>
          <Grid item xs="auto">
            <img
              alt="Digital Space icon."
              src={icon}
              style={{
                height: "36px",
              }}
            />
          </Grid>
          <Grid item xs="auto">
            <Typography noWrap variant="h6">
              Automated Dialler
            </Typography>
          </Grid>
        </Grid>
      </Toolbar>
      <Divider />
      <List disablePadding>
        {sortBy(routes, "listIndex").map(renderRoute)}
      </List>
    </StyledDrawer>
  );
}
