import {
  PhoneCallback as PhoneCallbackIcon
} from '@mui/icons-material'
import React from 'react';

export { default as PhoneNumbers, LIST_PHONE_NUMBERS } from "./PhoneNumbers";
export { default as PhoneNumbersForm } from "./PhoneNumbersForm";

export const PhoneNumbersConfig = {
  title: 'Inbound Numbers',
  icon: <PhoneCallbackIcon />,
  path: '/inbound-numbers'
}
