import {
  Button,
  Dialog,
  DialogActions,
  DialogContentText, Theme, useTheme,
} from "@mui/material";
import React from "react";
import { DialogContent, DialogTitle } from "../../../components/dialog";
import {ICSSProperties} from "../../../types";

const useStyles = (theme: Theme): ICSSProperties => ({
  mainContent: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
  },

  campaignAction: {
    textAlign: "center",
    fontSize: "1rem",
  },

  campaignName: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    textAlign: "center",
    fontWeight: "800",
    fontSize: "1.4rem",
  },

  startText: {
    color: theme.palette.success.main,
    fontWeight: "600",
  },

  pauseText: {
    color: theme.palette.error.main,
    fontWeight: "600",
  },

  titleActionName: {
    fontWeight: "800",
  },

  importantMessage: {
    textAlign: "center",
    fontSize: "0.8rem",
    marginTop: theme.spacing(4),
  },
});

type CampaignPauseStartDialogProps = {
  name: string;
  action: string;
  clickYes?: () => void;
  clickNo?: () => void;
  clickClose?: () => void;
};

export default function CampaignPauseStartDialog(
  props: CampaignPauseStartDialogProps
) {
  const theme = useTheme()
  const styles = useStyles(theme);

  const name = props.name;
  const action = props.action;
  const clickYes = props.clickYes || (() => {});
  const clickNo = props.clickNo || (() => {});
  const clickClose = props.clickClose || (() => {});

  return (
    <Dialog fullWidth open={true} onClose={clickClose}>
      <DialogTitle onClose={clickClose}>Edit campaign</DialogTitle>
      <DialogContent sx={styles.mainContent}>
        <DialogContentText sx={styles.campaignAction}>
          You are about to{" "}
          {action === "start" && (
            <span style={styles.startText}>START</span>
          )}
          {action === "pause" && (
            <span style={styles.pauseText}>PAUSE</span>
          )}{" "}
          the campaign:
        </DialogContentText>
        <DialogContentText sx={styles.campaignName}>
          {name}
        </DialogContentText>
        <DialogContentText sx={styles.campaignAction}>
          Are you sure you want to proceed?
        </DialogContentText>
        <DialogContentText sx={styles.importantMessage}>
          {action === "start" && (
            <>
              Once started, the system will start processing the list of
              customers. Please note that it can take up to 10 minutes for the
              change to take effect.
            </>
          )}
          {action === "pause" && (
            <>
              Pausing a campaign will remove all pending customers from the call
              scheduler. Please note that it can take up to 10 minutes for the
              change to take effect.
            </>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={clickNo} type="button">
          No
        </Button>
        <Button
          color="primary"
          onClick={() => {
            clickYes();
            clickClose();
          }}
          type="button"
          variant="contained"
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
