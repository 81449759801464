import { TextField as MuiTextField, TextFieldProps } from "@mui/material";
import { useField } from "formik";
import React from "react";

/** */
export default function TextField({ inputProps, ...props }: TextFieldProps) {
  return (
    <MuiTextField
      fullWidth
      variant="outlined"
      {...props}
      inputProps={{
        autoComplete: "off",
        ...inputProps,
      }}
    />
  );
}

type TextFieldFormikProps = TextFieldProps & {
  name: string;
};

export function TextFieldFormik({
  helperText,
  ...props
}: TextFieldFormikProps) {
  const [field, { error }] = useField(props.name);
  return (
    <TextField
      {...props}
      {...field}
      error={Boolean(error)}
      helperText={error ?? helperText}
      value={field.value ?? ""}
    />
  );
}
