import { Grid } from "@mui/material";
import React from "react";
import { TextFieldFormik } from "../../../../components/inputs";

type SendSMSProps = { name: string };

export default function SendSMS({ name }: SendSMSProps) {
  return (
    <>
      <Grid item xs={2}>
        <TextFieldFormik name={`${name}.senderId`} label="SMS Sender Name" />
      </Grid>
      <Grid item xs>
        <TextFieldFormik name={`${name}.text`} label="Message" />
      </Grid>
    </>
  );
}
