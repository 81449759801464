/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getContactsPhoneOutcomesCountByCallListId = /* GraphQL */ `
  query GetContactsPhoneOutcomesCountByCallListId($id: ID!) {
    getContactsPhoneOutcomesCountByCallListId(id: $id) {
      callerReached
      transferredToPhoneNumber
      transferredToQueue
      transferredToVoicemail
      voicemailLeft
      connected
      hungUp
      engaged
      callGuardian
      noAnswer
      invalidNumber
      error
    }
  }
`;
export const getContactsPhoneStatusesCountByCallListId = /* GraphQL */ `
  query GetContactsPhoneStatusesCountByCallListId($id: ID!) {
    getContactsPhoneStatusesCountByCallListId(id: $id) {
      callMade
      callingNow
      callPending
      invalidNumber
      error
    }
  }
`;
export const listPhoneNumbers = /* GraphQL */ `
  query ListPhoneNumbers(
    $filter: ModelPhoneNumberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPhoneNumbers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        arn
        phoneNumber
        type
        countryCode
        createdAt
        updatedAt
        contactFlow {
          id
          type
          name
          description
          runRate
          callsPerMinute
          sourcePhoneNumber
          voicemailMessage
          paused
          createdBy
          updatedBy
          deleted
          deletedAt
          deletedBy
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const listPhoneNumbersByPhoneNumber = /* GraphQL */ `
  query ListPhoneNumbersByPhoneNumber(
    $phoneNumber: AWSPhone
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPhoneNumberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPhoneNumbersByPhoneNumber(
      phoneNumber: $phoneNumber
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        arn
        phoneNumber
        type
        countryCode
        createdAt
        updatedAt
        contactFlow {
          id
          type
          name
          description
          runRate
          callsPerMinute
          sourcePhoneNumber
          voicemailMessage
          paused
          createdBy
          updatedBy
          deleted
          deletedAt
          deletedBy
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const listPrompts = /* GraphQL */ `
  query ListPrompts($limit: Int, $nextToken: String) {
    listPrompts(limit: $limit, nextToken: $nextToken) {
      items {
        id
        arn
        name
      }
      nextToken
    }
  }
`;
export const listQueues = /* GraphQL */ `
  query ListQueues(
    $filter: ModelQueueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQueues(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        arn
        name
        type
      }
      nextToken
    }
  }
`;
export const getCallList = /* GraphQL */ `
  query GetCallList($id: ID!) {
    getCallList(id: $id) {
      id
      csvFields
      fields {
        id
        primaryPhone
        secondaryPhone
      }
      file {
        bucket
        key
        region
      }
      filename
      contactFlowId
      processContacts
      warnings
      errors
      createdBy
      createdAt
      updatedAt
      contacts {
        items {
          id
          callListId
          contactFlowId
          evaluateAfter
          fileId
          primaryCtr
          primaryError
          primaryOutcome
          primaryPhone
          primaryStatus
          secondaryCtr
          secondaryError
          secondaryOutcome
          secondaryPhone
          secondaryStatus
          otherAttributes
          ctr
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listCallLists = /* GraphQL */ `
  query ListCallLists(
    $filter: ModelCallListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCallLists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        csvFields
        fields {
          id
          primaryPhone
          secondaryPhone
        }
        file {
          bucket
          key
          region
        }
        filename
        contactFlowId
        processContacts
        warnings
        errors
        createdBy
        createdAt
        updatedAt
        contacts {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listCallListsByContactFlowId = /* GraphQL */ `
  query ListCallListsByContactFlowId(
    $contactFlowId: ID
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCallListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCallListsByContactFlowId(
      contactFlowId: $contactFlowId
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        csvFields
        fields {
          id
          primaryPhone
          secondaryPhone
        }
        file {
          bucket
          key
          region
        }
        filename
        contactFlowId
        processContacts
        warnings
        errors
        createdBy
        createdAt
        updatedAt
        contacts {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listCallListsByContactFlowIdByCreatedAt = /* GraphQL */ `
  query ListCallListsByContactFlowIdByCreatedAt(
    $contactFlowId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCallListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCallListsByContactFlowIdByCreatedAt(
      contactFlowId: $contactFlowId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        csvFields
        fields {
          id
          primaryPhone
          secondaryPhone
        }
        file {
          bucket
          key
          region
        }
        filename
        contactFlowId
        processContacts
        warnings
        errors
        createdBy
        createdAt
        updatedAt
        contacts {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listContacts = /* GraphQL */ `
  query ListContacts(
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        callListId
        contactFlowId
        evaluateAfter
        fileId
        primaryCtr
        primaryError
        primaryOutcome
        primaryPhone
        primaryStatus
        primaryVoicemail {
          id
          key
          queue
          timestamp
          customerEndpoint
          createdAt
          updatedAt
        }
        secondaryCtr
        secondaryError
        secondaryOutcome
        secondaryPhone
        secondaryStatus
        secondaryVoicemail {
          id
          key
          queue
          timestamp
          customerEndpoint
          createdAt
          updatedAt
        }
        otherAttributes
        ctr
        voicemail {
          id
          key
          queue
          timestamp
          customerEndpoint
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listContactsByCallListIdByFileId = /* GraphQL */ `
  query ListContactsByCallListIdByFileId(
    $callListId: ID
    $fileId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContactsByCallListIdByFileId(
      callListId: $callListId
      fileId: $fileId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        callListId
        contactFlowId
        evaluateAfter
        fileId
        primaryCtr
        primaryError
        primaryOutcome
        primaryPhone
        primaryStatus
        primaryVoicemail {
          id
          key
          queue
          timestamp
          customerEndpoint
          createdAt
          updatedAt
        }
        secondaryCtr
        secondaryError
        secondaryOutcome
        secondaryPhone
        secondaryStatus
        secondaryVoicemail {
          id
          key
          queue
          timestamp
          customerEndpoint
          createdAt
          updatedAt
        }
        otherAttributes
        ctr
        voicemail {
          id
          key
          queue
          timestamp
          customerEndpoint
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listContactsByContactFlowId = /* GraphQL */ `
  query ListContactsByContactFlowId(
    $contactFlowId: ID
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContactsByContactFlowId(
      contactFlowId: $contactFlowId
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        callListId
        contactFlowId
        evaluateAfter
        fileId
        primaryCtr
        primaryError
        primaryOutcome
        primaryPhone
        primaryStatus
        primaryVoicemail {
          id
          key
          queue
          timestamp
          customerEndpoint
          createdAt
          updatedAt
        }
        secondaryCtr
        secondaryError
        secondaryOutcome
        secondaryPhone
        secondaryStatus
        secondaryVoicemail {
          id
          key
          queue
          timestamp
          customerEndpoint
          createdAt
          updatedAt
        }
        otherAttributes
        ctr
        voicemail {
          id
          key
          queue
          timestamp
          customerEndpoint
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getContactFlow = /* GraphQL */ `
  query GetContactFlow($id: ID!) {
    getContactFlow(id: $id) {
      id
      type
      name
      description
      hours {
        sunday {
          from
          to
        }
        monday {
          from
          to
        }
        tuesday {
          from
          to
        }
        wednesday {
          from
          to
        }
        thursday {
          from
          to
        }
        friday {
          from
          to
        }
        saturday {
          from
          to
        }
      }
      runRate
      callsPerMinute
      sourcePhoneNumber
      voice {
        gender
        id
        languageCode
        languageName
        name
      }
      voicemailMessage
      inbound {
        during
        after
      }
      outbound {
        before
        during
        after
      }
      website {
        before
        during
        after
      }
      invitationSms {
        templateId
        attributes
      }
      confirmationSms {
        templateId
        attributes
      }
      paused
      createdBy
      updatedBy
      deleted
      deletedAt
      deletedBy
      createdAt
      updatedAt
      callLists {
        items {
          id
          csvFields
          filename
          contactFlowId
          processContacts
          warnings
          errors
          createdBy
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listContactFlows = /* GraphQL */ `
  query ListContactFlows(
    $filter: ModelContactFlowFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContactFlows(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        name
        description
        hours {
          sunday {
            from
            to
          }
          monday {
            from
            to
          }
          tuesday {
            from
            to
          }
          wednesday {
            from
            to
          }
          thursday {
            from
            to
          }
          friday {
            from
            to
          }
          saturday {
            from
            to
          }
        }
        runRate
        callsPerMinute
        sourcePhoneNumber
        voice {
          gender
          id
          languageCode
          languageName
          name
        }
        voicemailMessage
        inbound {
          during
          after
        }
        outbound {
          before
          during
          after
        }
        website {
          before
          during
          after
        }
        invitationSms {
          templateId
          attributes
        }
        confirmationSms {
          templateId
          attributes
        }
        paused
        createdBy
        updatedBy
        deleted
        deletedAt
        deletedBy
        createdAt
        updatedAt
        callLists {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getExternalApi = /* GraphQL */ `
  query GetExternalApi($id: ID!) {
    getExternalApi(id: $id) {
      id
      apiKey {
        header
        apiKey
      }
      authentication
      method
      name
      payload
      url
      createdAt
      updatedAt
    }
  }
`;
export const listExternalApis = /* GraphQL */ `
  query ListExternalApis(
    $filter: ModelExternalApiFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExternalApis(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        apiKey {
          header
          apiKey
        }
        authentication
        method
        name
        payload
        url
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPhoneNumber = /* GraphQL */ `
  query GetPhoneNumber($id: ID!) {
    getPhoneNumber(id: $id) {
      id
      arn
      phoneNumber
      type
      countryCode
      createdAt
      updatedAt
      contactFlow {
        id
        type
        name
        description
        runRate
        callsPerMinute
        sourcePhoneNumber
        voice {
          gender
          id
          languageCode
          languageName
          name
        }
        voicemailMessage
        inbound {
          during
          after
        }
        outbound {
          before
          during
          after
        }
        website {
          before
          during
          after
        }
        invitationSms {
          templateId
          attributes
        }
        confirmationSms {
          templateId
          attributes
        }
        paused
        createdBy
        updatedBy
        deleted
        deletedAt
        deletedBy
        createdAt
        updatedAt
        callLists {
          nextToken
        }
      }
    }
  }
`;
