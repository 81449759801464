import { gql, useMutation } from "@apollo/client";
import { Button, Dialog, DialogActions, Grid, MenuItem } from "@mui/material";
import { Form } from "formik";
import React from "react";
import * as yup from "yup";
import {
  createExternalApi as CREATE_EXTERNAL_API,
  updateExternalApi as UPDATE_EXTERNAL_API,
} from "../graphql/mutations";
import {
  ApiAuthentications,
  ApiMethods,
  ExternalApi,
  ExternalApiItem,
} from "../types";
import { DialogContent, DialogTitle } from "./dialog";
import { Alert, Formik } from "./formik";
import { JsonFormik, SelectFormik, TextFieldFormik } from "./inputs";

const validationSchema: yup.Schema<Omit<ExternalApi, "id">> = yup
  .object({
    apiKey: yup
      .object()
      .shape({
        header: yup.string(),
        apiKey: yup.string(),
      })
      .when("authentication", {
        is: ApiAuthentications.API_KEY,
        then: yup
          .object()
          .shape({
            header: yup.string(),
            apiKey: yup.string(),
          })
          .required("Required"),
      })
      .required("Required"),
    authentication: yup
      .string()
      .oneOf([ApiAuthentications.API_KEY])
      .required("Required"),
    method: yup.string().required("Required").oneOf([ApiMethods.POST]),
    name: yup.string().required("Required"),
    payload: yup.object().required("Required"),
    url: yup
      .string()
      .required("Required")
      .url("Invalid URL")
      .test(
        "is-https",
        "Must be https",
        (url) => url?.startsWith("https://") ?? false
      ),
  })
  .required("Required");

const initialStatus = {
  submitted: false,
  error: null,
};

type CreateUpdateExternalApiDialogProps = {
  externalApi?: ExternalApiItem;
  onClose: () => void;
  open: boolean;
};

export default function CreateUpdateExternalApiDialog({
  externalApi,
  onClose,
  open,
}: CreateUpdateExternalApiDialogProps) {
  const [createExternalApi] = useMutation(gql(CREATE_EXTERNAL_API));
  const [updateExternalApi] = useMutation(gql(UPDATE_EXTERNAL_API));

  return (
    <Formik
      validationSchema={validationSchema}
      initialStatus={initialStatus}
      initialValues={
        externalApi
          ? {
              id: externalApi?.id,
              apiKey: {
                header: externalApi?.apiKey?.header,
                apiKey: externalApi?.apiKey?.apiKey,
              },
              authentication: externalApi?.authentication,
              method: externalApi?.method,
              name: externalApi?.name,
              payload: externalApi?.payload,
              url: externalApi?.url,
            }
          : {
              authentication: "API_KEY",
              method: "POST",
            }
      }
      onSubmit={async (values, { setStatus, resetForm }) => {
        setStatus(initialStatus);
        try {
          const createUpdateExternalApiParams = {
            variables: {
              input: values,
            },
          };

          if (externalApi) {
            await updateExternalApi(createUpdateExternalApiParams);
          } else {
            await createExternalApi(createUpdateExternalApiParams);
          }

          setStatus({ submitted: true, error: null });
          resetForm();
          onClose();
        } catch (err) {
          console.error(err);
          setStatus({ submitted: true, error: err });
        }
      }}
    >
      {({ handleReset, values }) => {
        const handleClose = () => {
          handleReset();
          onClose();
        };
        return (
          <>
            <Dialog onClose={handleClose} open={open}>
              <Form>
                <DialogTitle onClose={handleClose}>
                  {externalApi ? "Update" : "Create"} external API
                </DialogTitle>
                <DialogContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextFieldFormik label="Name" name="name" required />
                    </Grid>
                    <Grid item xs={12}>
                      <TextFieldFormik label="URL" name="url" required />
                    </Grid>
                    <Grid container item spacing={2} xs={12}>
                      <Grid item xs>
                        <SelectFormik
                          label="Authentication"
                          name="authentication"
                          required
                        >
                          <MenuItem value="API_KEY">API key</MenuItem>
                        </SelectFormik>
                      </Grid>
                      {values.authentication === "API_KEY" && (
                        <>
                          <Grid item xs>
                            <TextFieldFormik
                              label="Header"
                              name="apiKey.header"
                              required={values.authentication === "API_KEY"}
                            />
                          </Grid>
                          <Grid item xs>
                            <TextFieldFormik
                              label="API key"
                              name="apiKey.apiKey"
                              required={values.authentication === "API_KEY"}
                            />
                          </Grid>
                        </>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <SelectFormik label="Method" name="method" required>
                        <MenuItem value="POST">POST</MenuItem>
                      </SelectFormik>
                    </Grid>
                    <Grid item xs={12}>
                      <JsonFormik
                        label="Payload"
                        name="payload"
                        // htmlElementProps={{ style: { height: 300 } }}
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} type="button">
                    Cancel
                  </Button>
                  <Button color="primary" type="submit" variant="contained">
                    {externalApi ? "Update" : "Create"} external API
                  </Button>
                </DialogActions>
              </Form>
            </Dialog>
            <Alert
              successMessage={`External API ${
                externalApi ? "updated" : "created"
              } successfully`}
              errorMessage={`Unable to  ${
                externalApi ? "update" : "create"
              } external API`}
            />
          </>
        );
      }}
    </Formik>
  );
}
