import { gql, useMutation } from "@apollo/client";
import { useAuthenticator } from "@aws-amplify/ui-react";
import {
  Alert,
  AlertColor,
  Button,
  CircularProgress,
  DialogActions,
  Grid,
} from "@mui/material";
import { Storage } from "aws-amplify";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { v4 } from "uuid";
import awsconfig from "../../../../aws-exports";
import { DialogContent } from "../../../../components/dialog";
import { client, StateContext } from "../../../../contexts";
import {
  createCallList as CREATE_CALL_LIST,
  updateCallList as UPDATE_CALL_LIST,
} from "../../../../graphql/mutations";
import { ContactFlow, Fields } from "../../../../types";

type Alert = {
  severity: AlertColor;
  message: string;
};

type CreateCallListProps = {
  csvFields: string[];
  fields: Fields;
  file: any;
  contactFlow?: Partial<ContactFlow>;
  onClose: () => void;
};

export default function CreateCallList({
  csvFields,
  fields,
  file,
  contactFlow,
  onClose: handleClose,
}: CreateCallListProps) {
  const { callLists } = useContext(StateContext);
  const history = useHistory();
  const [alert, setAlert] = useState<Alert>();
  const { user } = useAuthenticator();
  const [updateCallList, { loading: updateCallListLoading }] = useMutation(
    gql(UPDATE_CALL_LIST)
  );
  const [callListId, setCallListId] = useState(null);

  useEffect(() => {
    if (user && !callListId)
      (async () => {
        const { name, type: contentType } = file;

        const regExSearchResults = /([^.]+)(\.(\w+))?$/.exec(name);

        if (regExSearchResults !== null) {
          const [, , , extension] = regExSearchResults;

          const level = "public";

          const putResult = await Storage.put(
            `${v4()}${extension && "."}${extension}`,
            file,
            { contentType, level }
          );

          const {
            data: {
              createCallList: { id, contactFlowId },
            },
          } = await client.mutate({
            mutation: gql(CREATE_CALL_LIST),
            variables: {
              input: {
                csvFields,
                fields,
                file: {
                  bucket: awsconfig.aws_user_files_s3_bucket,
                  key: `${level}/${putResult.key}`,
                  region: awsconfig.aws_user_files_s3_bucket_region,
                },
                filename: name,
                contactFlowId: contactFlow?.id,
                processContacts: false,
                warnings: "[]",
                errors: "[]",
                createdBy: user.attributes?.name,
              },
            },
          });

          setCallListId(id);

          if (id) {
            setAlert({
              severity: "success",
              message: "Successfully created call list.",
            });
            if (contactFlow?.createMethod === "replace") {
              for (const { id: existingCallListId } of callLists.filter(
                (callList) => callList.contactFlowId === contactFlow.id
              )) {
                await updateCallList({
                  variables: {
                    input: { id: existingCallListId, processContacts: false },
                  },
                });
              }
            }
            handleClose();
            history.push(`/contactFlows/${contactFlowId}/callLists/${id}`);
          } else {
            setAlert({
              severity: "error",
              message: "Failed to create call list.",
            });
          }
        }
      })();
  }, [
    callListId,
    callLists,
    contactFlow,
    csvFields,
    fields,
    file,
    handleClose,
    history,
    updateCallList,
    user,
  ]);

  if (updateCallListLoading) return <CircularProgress />;
  else {
    return (
      <>
        <DialogContent>
          {alert?.severity && alert?.message && (
            <Grid item xs={12}>
              <Alert severity={alert.severity || "error"}>
                {alert.message}
              </Alert>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClose} variant="contained">
            Finish
          </Button>
        </DialogActions>
      </>
    );
  }
}
