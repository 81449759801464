export default function toSentenceCase(str: string): string {
  const strArr = str.split(/(?=[A-Z])/);

  strArr.forEach((item, index) => {
    if (index === 0) strArr[index] = item[0].toUpperCase() + item.substr(1);
    else strArr[index] = item.toLowerCase();
  });

  return strArr.join(" ");
}
