import { Formik as FormikFormik, FormikConfig, FormikValues } from "formik";
import React from "react";

export default function Formik<
  Values extends FormikValues = FormikValues,
  ExtraProps = Record<string, unknown>
>(props: FormikConfig<Values> & ExtraProps) {
  return (
    <FormikFormik {...props} validateOnChange={false} validateOnBlur={false} />
  );
}
