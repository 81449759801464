import {
  Check as CheckIcon,
  DeleteForever as DeleteForeverIcon,
  Error as ErrorIcon,
  Settings as SettingsIcon,
} from "@mui/icons-material";
import {Box, Button, Chip, Grid, Theme, useTheme} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import useContactCrm from "../../../hooks/useContactCrm";
import ContactCrm from "../../../lib/ContactCrm";
import {ExportTemplate, ICSSProperties} from "../../../types";
import ExportTemplateCreateEditDialog from "../dialogs/ExportTemplateCreateEditDialog";
import ExportTemplateDeleteDialog from "../dialogs/ExportTemplateDeleteDialog";

const useStyles = (theme: Theme): ICSSProperties => ({
  root: {
    padding: `0 ${theme.spacing(3)}`,
  },

  noExportTemplateSelected: {
    backgroundColor: "#fff",
    padding: "20px",
    border: "1px solid #e0e0e0",
  },

  exportTemplateLoading: {
    padding: theme.spacing(10),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& > div": {
      marginRight: theme.spacing(1),
    },
  },

  templateConfig: {
    backgroundColor: "#fff",
    padding: "20px",
    border: "1px solid #e0e0e0",
  },
  exportTemplateName: {
    margin: 0,
    fontSize: "1.5em",
    display: "flex",
  },
  exportTemplateDescription: {
    margin: 0,
    marginBottom: "20px",
  },
  templateConfigHeader: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1.5),
  },

  resultIconCheck: {
    marginLeft: theme.spacing(1),
    color: theme.palette.success.main,
  },

  resultIconError: {
    marginLeft: theme.spacing(1),
    color: theme.palette.error.main,
  },

  templateConfigDetails: {},

  templateConfigActions: {
    textAlign: "right",

    "& .MuiButtonBase-root": {
      marginRight: theme.spacing(1.5),
      "&:last-child": {
        marginRight: 0,
      },
    },
  },

  chipDelete: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    "& .MuiChip-icon": {
      color: "#fff",
      marginRight: "-7px",
    },
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
    "& .MuiChip-label": {
      paddingRight: 0,
    },
  },

  chipEdit: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    "& .MuiChip-icon": {
      color: "#fff",
      marginRight: "-7px",
    },
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
    "& .MuiChip-label": {
      paddingRight: 0,
    },
  },

  validConfigStatusMessage: {
    textAlign: "right",
    marginTop: "12px",
    fontSize: "0.8rem",
    display: "inline-block",
    width: "100%",

    "& span": {
      padding: "3px 8px",
      borderRadius: "6px",
    },
  },
  validMessage: {
    backgroundColor: theme.palette.success.dark,
    color: "white",
  },
  invalidMessage: {
    backgroundColor: theme.palette.error.dark,
    color: "white",
  },

  templateConfigTextarea: {
    "& .MuiInputBase-multiline": {
      padding: "12px",
      backgroundColor: "rgba(0, 0, 0, 0.09)",

      "& textarea.MuiInputBase-input": {
        whiteSpace: "pre-wrap",
        fontFamily:
          'Consolas, "Lucida Console", Monaco, "Courier New", Courier, monospace',
      },
    },
  },
});

type ExportTemplateProps = {
  exportTemplateId: string;
  setExportTemplateId: Dispatch<SetStateAction<string>>;
};

export default function ExportTemplates({
  exportTemplateId,
  setExportTemplateId,
}: ExportTemplateProps): JSX.Element {
  const theme = useTheme()
  const styles = useStyles(theme);

  // CRM
  const crm = useContactCrm();

  const [exportTemplate, setExportTemplate] = useState<ExportTemplate>();
  const [exportTemplateTextarea, setExportTemplateTextarea] = useState<string|null>(null);
  const [loadingExportTemplate, setLoadingExportTemplate] = useState(true);
  const [templateSaved, setTemplateSaved] = useState<boolean>();

  const [openEditExportTemplateDialog, setOpenEditExportTemplateDialog] =
    useState(false);
  const [openDeleteExportTemplateDialog, setOpenDeleteExportTemplateDialog] =
    useState(false);

  const getExportTemplate = async (
    crm: ContactCrm,
    exportTemplateId: string
  ) => {
    if (!exportTemplateId) {
      return;
    }

    setLoadingExportTemplate(true);
    const et = await crm.getExportTemplate(exportTemplateId);

    if (et?.config) {
      try {
        if (typeof et.config === "string") {
          et.config = JSON.stringify(JSON.parse(et.config), null, 2);
        } else {
          et.config = JSON.stringify(et.config, null, 2);
        }
      } catch (err) {
        console.log(err);
      }
    }

    setExportTemplate(et);
    setLoadingExportTemplate(false);
  };

  useEffect(() => {
    if (crm && exportTemplateId) {
      getExportTemplate(crm, exportTemplateId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crm, exportTemplateId]);

  const handleTextareaChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setExportTemplateTextarea(e.target.value);
  };

  // Update template CONFIG
  const handleSave = async () => {
    try {
      setLoadingExportTemplate(true);
      const config = JSON.stringify(JSON.parse(exportTemplateTextarea ?? ''));
      const updated = await crm.updateExportTemplate(
        exportTemplateId,
        JSON.stringify({ config })
      );
      await getExportTemplate(crm, exportTemplateId);
      setTemplateSaved(updated);
      // remove the templateSaved flag after 10 seconds
      setTimeout(() => setTemplateSaved(undefined), 10 * 1000);
      setLoadingExportTemplate(false);
    } catch (err) {
      console.log(err);
      alert("An error while parsing the template config");
    }
  };

  // Update template TITLE and DESCRIPTION
  const editExportTemplate = async (data: Partial<ExportTemplate>) => {
    setLoadingExportTemplate(true);

    const updated = await crm.updateExportTemplate(
      exportTemplateId,
      JSON.stringify({
        name: data.name,
        description: data.description,
      })
    );

    await getExportTemplate(crm, exportTemplateId);
    setTemplateSaved(updated);
    setTimeout(() => setTemplateSaved(undefined), 10 * 1000);
    setLoadingExportTemplate(false);
  };

  const deleteExportTemplate = async (exportTemplateId: string) => {
    setLoadingExportTemplate(true);
    await crm.deleteExportTemplate(exportTemplateId);
    setExportTemplateId("");
    setLoadingExportTemplate(false);
  };

  let configValid = true;
  let configChanged = false;
  try {
    if (exportTemplateTextarea && exportTemplateTextarea.length > 0) {
      const tmp1 = JSON.stringify(JSON.parse(exportTemplate?.config || "[]"));
      const tmp2 = JSON.stringify(JSON.parse(exportTemplateTextarea || "[]"));
      configChanged = tmp1 !== tmp2;
      configValid = true;
    }
  } catch (err) {
    configValid = false;
  }

  if (!exportTemplateId) {
    return (
      <Box sx={styles.root}>
        <Box sx={styles.noExportTemplateSelected}>
          Select a template from the dropdown ...
        </Box>
      </Box>
    );
  }

  if (loadingExportTemplate) {
    return (
      <Box sx={styles.root}>
        <Box sx={styles.exportTemplateLoading}>
          <CircularProgress size={20} />
          <span>Loading ...</span>
        </Box>
      </Box>
    );
  } else if (!loadingExportTemplate && exportTemplate) {
    return (
      <Box sx={styles.root}>
        {openEditExportTemplateDialog && (
          <ExportTemplateCreateEditDialog
            name={exportTemplate.name}
            description={exportTemplate.description}
            mode="edit"
            clickYes={(data: Partial<ExportTemplate>) =>
              editExportTemplate(data)
            }
            clickNo={() => setOpenEditExportTemplateDialog(false)}
            clickClose={() => setOpenEditExportTemplateDialog(false)}
          />
        )}

        {openDeleteExportTemplateDialog && (
          <ExportTemplateDeleteDialog
            name={exportTemplate.name}
            clickYes={() =>
              deleteExportTemplate(exportTemplate.exportTemplateId)
            }
            clickNo={() => setOpenDeleteExportTemplateDialog(false)}
            clickClose={() => setOpenDeleteExportTemplateDialog(false)}
          />
        )}

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box sx={styles.templateConfig}>
              <Box sx={styles.templateConfigHeader}>
                <Box sx={styles.templateConfigDetails}>
                  {exportTemplate && (
                    <h3 style={styles.exportTemplateName}>
                      {exportTemplate.name}

                      {templateSaved === true && (
                        <span style={styles.resultIconCheck}>
                          <CheckIcon />
                        </span>
                      )}
                      {templateSaved === false && (
                        <span style={styles.resultIconError}>
                          <ErrorIcon />
                        </span>
                      )}
                    </h3>
                  )}
                  {exportTemplate && (
                    <p style={styles.exportTemplateDescription}>
                      {exportTemplate.description}
                    </p>
                  )}
                </Box>
                <Box>
                  <Box sx={styles.templateConfigActions}>
                    <Chip
                      icon={<DeleteForeverIcon />}
                      sx={styles.chipDelete}
                      onClick={() => {
                        setOpenDeleteExportTemplateDialog(true);
                      }}
                    />

                    <Chip
                      icon={<SettingsIcon />}
                      sx={styles.chipEdit}
                      onClick={() => {
                        setOpenEditExportTemplateDialog(true);
                      }}
                    />

                    <Button
                      color="primary"
                      variant="contained"
                      onClick={handleSave}
                      disabled={configChanged !== true}
                    >
                      SAVE
                    </Button>
                  </Box>
                  <Box sx={styles.validConfigStatusMessage}>
                    {configValid && (
                      <span style={styles.validMessage}>Valid JSON config</span>
                    )}

                    {!configValid && (
                      <span style={styles.invalidMessage}>
                        There is an error in your JSON config
                      </span>
                    )}
                  </Box>
                </Box>
              </Box>
              <Box sx={styles.templateConfigTextarea}>
                <TextField
                  fullWidth
                  multiline
                  hiddenLabel
                  minRows={20}
                  maxRows={30}
                  placeholder="Place your template here ..."
                  value={exportTemplateTextarea ?? exportTemplate?.config ?? ""}
                  onChange={handleTextareaChange}
                  variant="outlined"
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  }

  return <></>;
}
