import { gql, useMutation, useQuery } from "@apollo/client";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { RecordVoiceOver as RecordVoiceOverIcon } from "@mui/icons-material";
import {
  Alert as MuiAlert,
  Button,
  Dialog,
  DialogActions,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Radio,
  SelectChangeEvent,
  Tooltip,
} from "@mui/material";
import { Form } from "formik";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { LIST_CONTACT_FLOW_NAMES } from "../../..";
import { DialogContent, DialogTitle } from "../../../../components/dialog";
import { Alert, Formik } from "../../../../components/formik";
import {
  RadioGroupFormik,
  Select,
  SelectFormik,
  TextFieldFormik,
} from "../../../../components/inputs";
import { DAYS } from "../../../../constants";
import { client } from "../../../../contexts";
import { createContactFlow as CREATE_CONTACT_FLOW } from "../../../../graphql/mutations";
import { useAudioPlayer } from "../../../../hooks";
import { PhoneNumber } from "../../../../types";
import voices from "../../../../utils/polly/voices";
import { LIST_PHONE_NUMBERS } from "../../../phone-numbers";
import FromTo from "../create-contact-flow-dialog/FromTo";

export const LIST_QUEUES = gql`
  query ListQueues($limit: Int, $nextToken: String) {
    listQueues(
      filter: { QueueTypes: { eq: ["STANDARD"] } }
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
      }
      nextToken
    }
  }
`;

export const LIST_CONTACT_FLOW_BY_NAME = gql`
  query ListContactFlowsByName(
    $name: String!
    $limit: Int
    $nextToken: String
  ) {
    listContactFlows(
      filter: { name: { eq: $name }, deleted: { eq: false } }
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        name
      }
      nextToken
    }
  }
`;

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .required("Required")
    .test("is-unique", "Must be unique", async (name) => {
      if (!name) return false;
      const {
        data: {
          listContactFlows: { items },
        },
      } = await client.query({
        query: LIST_CONTACT_FLOW_BY_NAME,
        variables: { name },
      });
      return items.length === 0;
    }),
  description: yup.string(),
  hours: yup.object().shape({
    sunday: yup.object().shape({
      from: yup.string().nullable(),
      to: yup.string().nullable(),
    }),
    monday: yup.object().shape({
      from: yup.string().nullable(),
      to: yup.string().nullable(),
    }),
    tuesday: yup.object().shape({
      from: yup.string().nullable(),
      to: yup.string().nullable(),
    }),
    wednesday: yup.object().shape({
      from: yup.string().nullable(),
      to: yup.string().nullable(),
    }),
    thursday: yup.object().shape({
      from: yup.string().nullable(),
      to: yup.string().nullable(),
    }),
    friday: yup.object().shape({
      from: yup.string().nullable(),
      to: yup.string().nullable(),
    }),
    saturday: yup.object().shape({
      from: yup.string().nullable(),
      to: yup.string().nullable(),
    }),
  }),
  runRate: yup.string().required("Required").oneOf(["asap", "callsPerMinute"]),
  callsPerMinute: yup.number().when("runRate", {
    is: "callsPerMinute",
    then: yup
      .number()
      .required("Required")
      .min(1, "Cannot be less than one")
      .integer("Must be an integer"),
  }),
  sourcePhoneNumber: yup
    .string()
    .required("Required")
    .test("is-valid", "Invalid phone number", (sourcePhoneNumber) =>
      Boolean(
        parsePhoneNumberFromString(sourcePhoneNumber || "", "GB")?.isValid()
      )
    ),
  voice: yup.object().shape({
    id: yup.string().required("Required"),
  }),
  voicemailMessage: yup.string().required("Required"),
});

const initialStatus = {
  submitted: false,
  error: null,
};

type CreateContactFlowDialogProps = {
  onClose: () => void;
  open: boolean;
};

export default function CreateContactFlowDialog({
  open,
  onClose,
}: CreateContactFlowDialogProps) {
  const { loading, data } = useQuery<{
    listPhoneNumbers: { items: PhoneNumber[] };
  }>(LIST_PHONE_NUMBERS);
  const [languageName, setLanguageName] = useState("English, British");
  const [voicesByLanguageName, setVoicesByLanguageName] = useState(
    voices.filter((voice) => voice.languageName === languageName)
  );
  const { textToSpeech, clearAudio } = useAudioPlayer();
  const { user } = useAuthenticator();
  const [createContactFlow] = useMutation(gql(CREATE_CONTACT_FLOW), {
    refetchQueries: [LIST_CONTACT_FLOW_NAMES],
    awaitRefetchQueries: true,
  });
  const history = useHistory();

  return (
    <Formik
      validationSchema={validationSchema}
      initialStatus={initialStatus}
      initialValues={{
        name: "",
        description: "",
        hours: {
          sunday: {
            from: null,
            to: null,
          },
          monday: {
            from: "09:00",
            to: "17:00",
          },
          tuesday: {
            from: "09:00",
            to: "17:00",
          },
          wednesday: {
            from: "09:00",
            to: "17:00",
          },
          thursday: {
            from: "09:00",
            to: "17:00",
          },
          friday: {
            from: "09:00",
            to: "17:00",
          },
          saturday: {
            from: null,
            to: null,
          },
        },
        runRate: "asap",
        callsPerMinute: 60,
        sourcePhoneNumber: "",
        voice: {
          id: "Amy",
        },
        voicemailMessage: "",
      }}
      onSubmit={async (values, { setStatus, resetForm }) => {
        clearAudio();
        setStatus(initialStatus);
        try {
          const {
            data: {
              createContactFlow: { id },
            },
          } = await createContactFlow({
            variables: {
              input: {
                ...values,
                voice: voices.find((voice) => voice.id === values.voice.id),
                outbound: {
                  before: "[]",
                  during: JSON.stringify([
                    {
                      id: "1",
                      type: "EndCall",
                    },
                  ]),
                  after: "[]",
                },
                inbound: {
                  during: JSON.stringify([
                    {
                      id: "1",
                      type: "EndCall",
                    },
                  ]),
                  after: "[]",
                },
                createdBy: user.attributes?.name,
                updatedBy: user.attributes?.name,
                deleted: false,
                paused: false,
              },
            },
          });
          setStatus({ submitted: true, error: null });
          if (id) history.push(`/contactFlows/${id}`);
        } catch (err) {
          console.error(err);
          setStatus({ submitted: true, error: err });
        }
        resetForm();
        onClose();
      }}
    >
      {({ values, handleReset, setFieldValue }) => {
        const handleClose = () => {
          handleReset();
          onClose();
        };

        return (
          <>
            <Dialog fullWidth maxWidth="md" onClose={handleClose} open={open}>
              <Form>
                <DialogTitle onClose={handleClose}>
                  Add call profile
                </DialogTitle>
                <DialogContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextFieldFormik label="Name" name="name" />
                    </Grid>
                    <Grid item xs={12}>
                      <TextFieldFormik
                        label="Description"
                        multiline
                        name="description"
                      />
                    </Grid>
                    <Grid container item spacing={2} xs={12}>
                      {DAYS.map((day, index) => (
                        <FromTo
                          day={day}
                          from={values.hours[day]?.from}
                          key={index}
                          to={values.hours[day]?.to}
                        />
                      ))}
                    </Grid>
                    <Grid item xs={12}>
                      <RadioGroupFormik label="Run rate" name="runRate">
                        <FormControlLabel
                          control={<Radio />}
                          label="ASAP"
                          value="asap"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          label={
                            <>
                              <TextFieldFormik
                                inputProps={{
                                  min: 1,
                                }}
                                label="Calls per minute"
                                name="callsPerMinute"
                                type="number"
                              />
                            </>
                          }
                          value="callsPerMinute"
                        />
                      </RadioGroupFormik>
                    </Grid>
                    <Grid item xs={12}>
                      {loading ? (
                        <></>
                      ) : data ? (
                        <SelectFormik
                          label="Phone number"
                          name="sourcePhoneNumber"
                        >
                          {data.listPhoneNumbers.items.map(
                            ({ phoneNumber }, index) => (
                              <MenuItem key={index} value={phoneNumber}>
                                {parsePhoneNumberFromString(
                                  phoneNumber,
                                  "GB"
                                )?.formatInternational()}
                              </MenuItem>
                            )
                          )}
                        </SelectFormik>
                      ) : (
                        <MuiAlert severity="error">
                          Unable to list phone numbers.
                        </MuiAlert>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <Select
                        label="Language and Region"
                        value={languageName}
                        onChange={(event: SelectChangeEvent<unknown>) => {
                          clearAudio();
                          const languageName = event.target.value as string;
                          setLanguageName(languageName);
                          const voicesByLanguageName = voices.filter(
                            (voice) => voice.languageName === languageName
                          );
                          setVoicesByLanguageName(voicesByLanguageName);
                          setFieldValue("voice.id", voicesByLanguageName[0].id);
                        }}
                      >
                        {[
                          ...new Set(voices.map((voice) => voice.languageName)),
                        ].map((languageName, index) => (
                          <MenuItem key={index} value={languageName}>
                            {languageName}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                    <Grid item xs={6}>
                      <SelectFormik
                        label="Voice"
                        name="voice.id"
                        onChange={({ target: { value } }) => {
                          clearAudio();
                          setFieldValue("voice.id", value);
                        }}
                        startAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              edge="start"
                              onClick={() =>
                                textToSpeech(
                                  `Hi! My name is ${values.voice.id} and I sound like this.`,
                                  values.voice.id
                                )
                              }
                              size="large"
                            >
                              <Tooltip title="Preview audio">
                                <RecordVoiceOverIcon />
                              </Tooltip>
                            </IconButton>
                          </InputAdornment>
                        }
                      >
                        {voicesByLanguageName.map((voice, index) => (
                          <MenuItem key={index} value={voice.id}>
                            {voice.name}
                          </MenuItem>
                        ))}
                      </SelectFormik>
                    </Grid>
                    <Grid item xs={12}>
                      <TextFieldFormik
                        helperText={`This is what ${values.voice.id} will say if they reach a contact's answering machine.`}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {values.voicemailMessage && (
                                <IconButton
                                  edge="end"
                                  onClick={() =>
                                    textToSpeech(
                                      values.voicemailMessage,
                                      values.voice.id
                                    )
                                  }
                                  size="large"
                                >
                                  <Tooltip title="Preview audio">
                                    <RecordVoiceOverIcon />
                                  </Tooltip>
                                </IconButton>
                              )}
                            </InputAdornment>
                          ),
                        }}
                        label="Outbound voicemail message"
                        multiline
                        name="voicemailMessage"
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} type="button">
                    Cancel
                  </Button>
                  <Button color="primary" type="submit" variant="contained">
                    Create call profile
                  </Button>
                </DialogActions>
              </Form>
            </Dialog>
            <Alert
              successMessage="Call profile created successfully"
              errorMessage="Unable to create call profile"
            />
          </>
        );
      }}
    </Formik>
  );
}
