import React from "react";
import { Route, RouteProps, Switch } from "react-router-dom";
import CampaignsPage from "./CampaignsPage";
import CampaignSettings from "./CampaignSettings";

export default function CampaignsRoutes() {
  const routes: RouteProps[] = [
    {
      component: CampaignSettings,
      path: ["/campaigns/:campaignId", "/campaigns/:campaignId/settings"],
    },
    {
      component: CampaignsPage,
      path: "/campaigns",
    },
  ];

  return (
    <Switch>
      {routes.map(({ component, path }, index) => (
        <Route component={component} key={index} path={path} />
      ))}
    </Switch>
  );
}
