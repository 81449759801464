import React, { createContext } from "react";
import ContactCrm from "../lib/ContactCrm";
import ContactCrmService from "../services/ContactCrmService";

const contactCrm = new ContactCrm(new ContactCrmService());

const ContactCrmContext = createContext<ContactCrm>(contactCrm);

const ContactCrmProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <ContactCrmContext.Provider value={contactCrm}>
      {children}
    </ContactCrmContext.Provider>
  );
};

export { ContactCrmContext, ContactCrmProvider as default };
