import {
  ListItemButton,
  ListItemIcon,
  ListItemProps,
  ListItemText,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { ListItem } from "../../components/data-display";
import { Route } from "../../types";

type RouteListItemProps = ListItemProps & {
  route: Omit<Route, "listIndex">;
};

export default function RouteListItem({
  route: { href, icon, text },
  ...props
}: RouteListItemProps) {
  return (
    <ListItem {...props} sx={{ p: 0, ...props.sx }}>
      <ListItemButton component={Link} to={href}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={text} />
      </ListItemButton>
    </ListItem>
  );
}
