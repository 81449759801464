import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React from "react";

interface MetricsTableProps {
  metrics?: {
    manualQueue?: {
      reserved: number;
      total: number;
    };
  };
}

export const CallMetricsTable = ({ metrics }: MetricsTableProps) => {
  return (
    <Table
      aria-label="simple table"
      sx={{
        width: "100%",
      }}
    >
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          <TableCell
            align="right"
            sx={{
              fontWeight: "bold",
            }}
          >
            Total
          </TableCell>
          <TableCell
            align="right"
            sx={{
              fontWeight: "bold",
            }}
          >
            Reserved
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell component="th" scope="row">
            Manual Calls
          </TableCell>
          <TableCell align="right">{metrics?.manualQueue?.total}</TableCell>
          <TableCell align="right">{metrics?.manualQueue?.reserved}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
