import { gql, useQuery } from "@apollo/client";
import { RecordVoiceOver as RecordVoiceOverIcon } from "@mui/icons-material";
import {
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { useFormikContext } from "formik";
import React, { useMemo } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { getFieldValue } from "../../../../components/formik";
import { SelectFormik, TextFieldFormik } from "../../../../components/inputs";
import { listPrompts } from "../../../../graphql/queries";
import { useAudioPlayer } from "../../../../hooks";
import { OutboundContactFlowModulesItem, Prompt } from "../../../../types";

type SayProps = {
  audioPromptArn: string;
  name: string;
  text: string;
};

export default function Say({ audioPromptArn, name, text }: SayProps) {
  const { textToSpeech } = useAudioPlayer();
  const { values } = useFormikContext<
    Omit<OutboundContactFlowModulesItem, "before"> & {
      before?: string;
      voiceId?: string;
    }
  >();
  const { pathname } = useLocation();
  const { data, error } = useQuery<{ listPrompts: { items: Prompt[] } }>(
    gql(listPrompts)
  );

  if (error) console.error(error);

  const callProfile = useMemo(
    () => matchPath(pathname, "/contactFlows"),
    [pathname]
  );

  const prompt = useMemo(
    () =>
      callProfile ? getFieldValue(`${name}.prompt`, values) : "TextToSpeech",
    [callProfile, name, values]
  );

  return (
    <>
      {callProfile && (
        <Grid item xs="auto">
          <SelectFormik label="Prompt" name={`${name}.prompt`}>
            <MenuItem value="Audio">Audio</MenuItem>
            <MenuItem value="TextToSpeech">Text to speech</MenuItem>
          </SelectFormik>
        </Grid>
      )}
      {prompt === "Audio" ? (
        <>
          <Grid item xs>
            <SelectFormik
              name={`${name}.audioPromptArn`}
              label="Audio Prompt"
              value={audioPromptArn}
            >
              {data?.listPrompts?.items?.map((i) => (
                <MenuItem key={i.arn} value={i.arn}>
                  {i.name}
                </MenuItem>
              ))}
            </SelectFormik>
          </Grid>
        </>
      ) : prompt === "TextToSpeech" ? (
        <>
          <Grid item xs>
            <TextFieldFormik
              name={`${name}.text`}
              label={callProfile ? "Text to be spoken" : "Message"}
              InputProps={{
                ...(callProfile
                  ? {
                      endAdornment: (
                        <InputAdornment position="end">
                          {text && (
                            <IconButton
                              edge="end"
                              onClick={() => textToSpeech(text, values.voiceId)}
                              size="large"
                            >
                              <Tooltip title="Preview audio">
                                <RecordVoiceOverIcon />
                              </Tooltip>
                            </IconButton>
                          )}
                        </InputAdornment>
                      ),
                    }
                  : {}),
              }}
            />
          </Grid>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
