import { Grid } from "@mui/material";
import React from "react";
import { TextFieldFormik } from "../../../../components/inputs";

type AttributeProps = {
  name: string;
};

export default function Attribute({ name }: AttributeProps) {
  return (
    <>
      <Grid item xs>
        <TextFieldFormik name={`${name}.attribute`} label="Attribute" />
      </Grid>
      <Grid item xs>
        <TextFieldFormik name={`${name}.value`} label="Value" />
      </Grid>
    </>
  );
}
