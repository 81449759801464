import { Grid, Typography } from "@mui/material";
import { format } from "date-fns";
import { useFormikContext } from "formik";
import React, { useState } from "react";
import { Checkbox, TimePickerFormik } from "../../../../components/inputs";
import { FromTo as FromToType, Hours } from "../../../../types";
import { toSentenceCase } from "../../../../utils/to-sentence-case";

type FromToProps = FromToType & {
  day: keyof Hours;
  from: string | null;
  to: string | null;
};

export default function FromTo({ day, from, to }: FromToProps) {
  const { setFieldValue } = useFormikContext();
  const [open, setOpen] = useState<boolean>(Boolean(from && to));

  const handleChangeDays = () => {
    setFieldValue(`hours.${day}.from`, open ? null : "09:00");
    setFieldValue(`hours.${day}.to`, open ? null : "17:00");
    setOpen((open) => !open);
  };

  return (
    <Grid container item spacing={2} xs={12}>
      <Grid item xs={3}>
        <Typography>{toSentenceCase(day)}</Typography>
      </Grid>
      <Grid item xs={3}>
        <Checkbox checked={!open} label="Closed" onChange={handleChangeDays} />
      </Grid>
      {open && (
        <>
          <Grid item xs>
            <TimePickerFormik
              label="From"
              name={`hours.${day}.from`}
              onChange={(value: Date | null) =>
                setFieldValue(
                  `hours.${day}.from`,
                  value ? format(new Date(value), "HH:mm") : null
                )
              }
            />
          </Grid>
          <Grid item xs>
            <TimePickerFormik
              label="To"
              name={`hours.${day}.to`}
              onChange={(value: Date | null) =>
                setFieldValue(
                  `hours.${day}.to`,
                  value ? format(value, "HH:mm") : null
                )
              }
            />
          </Grid>
        </>
      )}
    </Grid>
  );
}
