import {
  Dialog,
  Step,
  StepLabel,
  StepLabelProps,
  Stepper,
  StepProps,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { DialogTitle } from "../../../../components/dialog";
import { ContactFlow, Fields } from "../../../../types";
import CreateCallList from "./CreateCallList";
import CSVReader from "./CSVReader";
import SelectContactFlow from "./SelectContactFlow";
import SelectFields from "./SelectFields";

const PREFIX = "CreateCallListDialog";

const classes = {
  root: `${PREFIX}-root`,
  instructions: `${PREFIX}-instructions`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.root}`]: {
    width: "100%",
  },

  [`& .${classes.instructions}`]: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

type CreateCallListDialogProps = {
  onClose: () => void;
  open: boolean;
};

export default function CreateCallListDialog({
  open,
  onClose,
}: CreateCallListDialogProps) {
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [file, setFile] = useState<any>();
  const [csvFields, setCsvFields] = useState<string[]>([]);
  const [fields, setFields] = useState<Fields>({
    id: "",
    primaryPhone: "",
    secondaryPhone: "",
  });
  const [contactFlow, setContactFlow] = useState<Partial<ContactFlow>>({});

  useEffect(() => {
    setFields((fields) => ({
      ...fields,
      id: csvFields.includes("Id") ? "Id" : "",
      primaryPhone: csvFields.includes("PrimaryPhone") ? "PrimaryPhone" : "",
      secondaryPhone: csvFields.includes("SecondaryPhone")
        ? "SecondaryPhone"
        : "",
    }));
  }, [csvFields]);

  const steps = [
    "Choose file",
    "Select fields",
    "Select call profile",
    "Create call list",
  ];

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <CSVReader
            setFile={setFile}
            setCsvFields={setCsvFields}
            handleNext={handleNext}
          />
        );
      case 1:
        return (
          <SelectFields
            fields={fields}
            setFields={setFields}
            csvFields={csvFields}
            handleBack={handleBack}
            handleNext={handleNext}
          />
        );
      case 2:
        return (
          <SelectContactFlow
            setContactFlow={setContactFlow}
            handleBack={handleBack}
            handleNext={handleNext}
          />
        );
      case 3:
        return (
          <CreateCallList
            csvFields={csvFields}
            fields={fields}
            file={file}
            contactFlow={contactFlow}
            onClose={handleClose}
          />
        );
      default:
        return (
          <Typography className={classes.instructions}>Unknown step</Typography>
        );
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleClose = () => {
    onClose();
    setActiveStep(0);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const isStepOptional = (step: number) => step === 1;

  const isStepSkipped = (step: number) => skipped.has(step);

  return (
    <StyledDialog maxWidth="md" open={open} onClose={handleClose}>
      <DialogTitle onClose={handleClose} sx={{ pt: 2 }}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps: StepProps = {};
            const labelProps: StepLabelProps = {};
            if (isStepOptional(index)) {
              labelProps.optional = (
                <Typography variant="caption">Optional</Typography>
              );
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </DialogTitle>
      <>{getStepContent(activeStep)}</>
    </StyledDialog>
  );
}
