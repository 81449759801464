import {
  AddCircle as AddCircleIcon,
  RemoveCircle as RemoveCircleIcon
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormLabel,
  Grid,
  IconButton, List,
  ListItem,
  ListItemText, MenuItem, Theme,
  useTheme
} from "@mui/material";
import { FieldArray, Form } from "formik";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, Link } from "react-router-dom";
import * as yup from "yup";
import { Alert, Formik } from "../../components/formik";
import {
  SelectFormik,
  TextFieldFormik
} from "../../components/inputs";
import { useContactCrm } from "../../hooks";
import {
  ICSSProperties, SmsSender, SmsTemplate
} from "../../types";
import ConfirmDeleteDialog from "./dialogs/ConfirmDeleteDialog";
import { SmsTemplatesConfig } from "./SmsTemplatesRoutes";

const useStyles = (theme: Theme): ICSSProperties => ({
  root: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(5),
  },
  smsTemplatesLoading: {
    padding: theme.spacing(10),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& > div": {
      marginRight: theme.spacing(1),
    },
  },
});

const smsTemplateSchema: yup.Schema<Omit<SmsTemplate, "id" | "createdAt">> = yup
  .object({
    type: yup.string().matches(/^internal|external$/).required(),
    name: yup.string().required('Required'),
    senderId: yup.string().required('Required'),
    attributeKeys: yup.array(yup.string().required()).required('Required'),
    externalId: yup.string(),
  })
  .required('Required');

const initialStatus = {
  submitted: false,
  errors: null,
};

export default function SmsTemplateEdit(
  props: RouteComponentProps<{ smsTemplateId: string }>
): JSX.Element {
  const theme = useTheme()
  const styles = useStyles(theme);

  const smsTemplateId = props.match.params.smsTemplateId;

  // CRM
  const crm = useContactCrm();
  const [loadingTemplate, setLoadingTemplate] = useState(true);
  const [loadingTemplateError, setLoadingTemplateError] = useState(false);
  const [loadingSenders, setLoadingSenders] = useState(true);
  const [loadingSendersError, setLoadingSendersError] = useState(false);
  const [smsTemplate, setSmsTemplate] = useState<SmsTemplate>();
  const [smsSenders, setSmsSenders] = useState<SmsSender[]>();
  const [redirect, setRedirect] = useState<string>();

  // delete dialog
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const handleOpenConfirmDeleteDialog = () => setOpenConfirmDeleteDialog(true);
  const handleCloseConfirmDeleteDialog = () => setOpenConfirmDeleteDialog(false);

  const [savedValues, setSavedValues] = useState<SmsTemplate>({
    id: smsTemplate?.id || "",
    type: smsTemplate?.type || "external",
    name: smsTemplate?.name || "",
    senderId: smsTemplate?.senderId || "",
    attributeKeys: smsTemplate?.attributeKeys || [],
    body: undefined,
    externalId: undefined,
    createdAt: smsTemplate?.createdAt || "",
  });

  useEffect(() => {
    const loadSmsTemplate = async () => {
      if (crm) {
        setLoadingTemplate(true);

        try {
          const res = await crm.getSmsTemplate(smsTemplateId)
          setSavedValues(res);
          setSmsTemplate(res);
        } catch (err) {
          console.error(err)
          setLoadingTemplateError(true)
        }

        setLoadingTemplate(false);
      }
    };

    loadSmsTemplate();
  }, [crm]);

  useEffect(() => {
    const loadSenders = async () => {
      if (crm) {
        setLoadingSenders(true);

        try {
          const res = await crm.listSmsSenders();
          setSmsSenders(res);
        } catch (err) {
          console.error(err)
          setLoadingSendersError(true)
        }

        setLoadingSenders(false);
      }
    };

    loadSenders();
  }, [crm]);

  if (loadingTemplate || loadingSenders) {
    return (
      <Box sx={styles.root}>
        <Box sx={styles.smsTemplatesLoading}>
          <CircularProgress size={20} />
          <span> Loading SMS Template...</span>
        </Box>
      </Box>
    );
  } else if (loadingTemplateError || loadingSendersError) {
    return (
      <Box sx={styles.root}>
        <p>Failed to load data</p>
        <p>
          <Link to={SmsTemplatesConfig.path}>Back to templates list</Link>
        </p>
      </Box>
    );
  } else {
    return (
      <Box sx={styles.root}>
        <Formik
          validationSchema={smsTemplateSchema}
          initialStatus={initialStatus}
          initialValues={savedValues}
          onSubmit={async (values, { setStatus }) => {
            setStatus(initialStatus);
            try {
              const res = await crm.updateSmsTemplate(
                smsTemplateId,
                JSON.stringify({
                  name: values.name,
                  type: 'external',
                  senderId: values.senderId,
                  externalId: values?.externalId,
                  attributeKeys: values?.attributeKeys
                })
              );
              if (!res) {
                throw new Error("Failed to update SMS Template")
              }
              setStatus({ submitted: true, error: null });
              setSavedValues(values);
            } catch (err) {
              console.error(err);
              setStatus({ submitted: true, error: err });
            }
          }}
        >
          {({ values }) => (
            <Card>
              <CardContent>
                <Form>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <SelectFormik
                        label="Sender"
                        name="senderId"
                        required
                      >
                        {smsSenders!.filter(smsSender => smsSender.type === values.type).map((smsSender) => (
                          <MenuItem key={smsSender.id} value={smsSender.id}>{smsSender.name}</MenuItem>
                        ))}
                      </SelectFormik>
                    </Grid>

                    <Grid item xs={8}>
                      <TextFieldFormik label="External ID" name="externalId" required={values.type === 'external'} />
                    </Grid>

                    <Grid item xs={12}>
                      <TextFieldFormik label="Name" name="name" required />
                    </Grid>

                    <Grid item xs={12}>
                      <FieldArray name="attributeKeys">
                        {({ insert, remove, push }) => (
                          <>
                            <Grid item xs={12}>
                              <FormLabel>Attributes</FormLabel>
                              <IconButton
                                onClick={() => push("")}
                                size="large"
                                color="primary"
                              >
                                <AddCircleIcon />
                              </IconButton>
                            </Grid>
                            <Grid item xs={12}>
                              <List dense={true}>
                                {values.attributeKeys.length > 0 && values.attributeKeys.map((attrKey, index) => (
                                  <ListItem key={index} secondaryAction={
                                    <IconButton edge="end" aria-label="delete"
                                      onClick={() => remove(index)}
                                    >
                                      <RemoveCircleIcon color="error" />
                                    </IconButton>
                                  }>
                                    <ListItemText>
                                      <TextFieldFormik name={`attributeKeys.${index}`} margin="dense" />
                                    </ListItemText>
                                  </ListItem>
                                ))}
                              </List>
                            </Grid>
                          </>
                        )}
                      </FieldArray>
                    </Grid>


                    <Grid item container spacing={2} justifyContent="space-between">
                      <Grid item>
                        <Button
                          color="primary"
                          type="submit"
                          variant="contained"
                          disabled={JSON.stringify(savedValues) === JSON.stringify(values)}
                        >
                          Save Template
                        </Button>
                        <Button href={SmsTemplatesConfig.path}>
                          Cancel
                        </Button>
                      </Grid>

                      <Grid item>
                        <Button
                          color="error"
                          type="button"
                          variant="outlined"
                          onClick={handleOpenConfirmDeleteDialog}
                        >
                          Delete Template
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
                <Alert
                  successMessage="SMS Template updated successfully"
                  errorMessage="Unable to update SMS Template"
                />
                <ConfirmDeleteDialog
                  open={openConfirmDeleteDialog}
                  templateToDelete={{ id: smsTemplateId, name: savedValues.name }}
                  onClose={handleCloseConfirmDeleteDialog}
                />
              </CardContent>
            </Card>
          )}
        </Formik >
      </Box>
    );
  }
};
