import { AlertColor, Tooltip } from "@mui/material";
import React from "react";
import { PhoneOutcome as PhoneOutcomeType, PhoneOutcomes } from "../../types";
import { toSentenceCase } from "../../utils/to-sentence-case";
import { Chip } from "../chip";

type PhoneOutcomeProps = {
  error?: string;
  outcome?: PhoneOutcomeType;
};

export default function PhoneOutcome({
  error,
  outcome,
}: PhoneOutcomeProps): JSX.Element {
  const label = outcome && toSentenceCase(outcome);
  let severity: AlertColor;

  switch (outcome) {
    case PhoneOutcomes.callerReached:
    case PhoneOutcomes.transferredToPhoneNumber:
    case PhoneOutcomes.transferredToQueue:
    case PhoneOutcomes.transferredToVoicemail:
      severity = "success";
      break;
    case PhoneOutcomes.voicemailLeft:
    case PhoneOutcomes.connected:
    case PhoneOutcomes.hungUp:
      severity = "info";
      break;
    case PhoneOutcomes.engaged:
    case PhoneOutcomes.callGuardian:
    case PhoneOutcomes.noAnswer:
      severity = "warning";
      break;
    case PhoneOutcomes.invalidNumber:
    case PhoneOutcomes.error:
      severity = "error";
      break;
    default:
      return <>{label}</>;
  }

  return (
    // Tooltip will only show if there's a title prop
    <Tooltip title={error ?? ""}>
      <Chip label={label} severity={severity} />
    </Tooltip>
  );
}
