import {
  gql,
  LazyQueryResult,
  useLazyQuery,
  useSubscription,
} from "@apollo/client";
import React, { createContext, ReactNode, useEffect, useState } from "react";
import {
  listCallLists as LIST_CALL_LISTS,
  listContactFlows as LIST_CONTACT_FLOWS,
} from "../graphql/queries";
import {
  onCreateCallList as ON_CREATE_CALL_LIST,
  onCreateContactFlow as ON_CREATE_CONTACT_FLOW,
  onUpdateCallList as ON_UPDATE_CALL_LIST,
  onUpdateContactFlow as ON_UPDATE_CONTACT_FLOW,
} from "../graphql/subscriptions";
import { CallListItem, ContactFlowItem } from "../types";

export const StateContext = createContext<{
  callLists: CallListItem[];
  contactFlows: ContactFlowItem[];
}>({
  callLists: [],
  contactFlows: [],
});

type StateProviderProps = {
  children: ReactNode;
};

export default function StateProvider({ children }: StateProviderProps) {
  const [listCallLists] = useLazyQuery<{
    listCallLists: { items: CallListItem[]; nextToken?: string };
  }>(gql(LIST_CALL_LISTS));
  const [listContactFlows] = useLazyQuery<{
    listContactFlows: { items: ContactFlowItem[]; nextToken?: string };
  }>(gql(LIST_CONTACT_FLOWS));
  const [callLists, setCallLists] = useState<CallListItem[]>([]);
  const [contactFlows, setContactFlows] = useState<ContactFlowItem[]>([]);

  useEffect(() => {
    (async () => {
      let callLists: CallListItem[] = [],
        nextToken;

      do {
        const result: LazyQueryResult<
          {
            listCallLists: { items: CallListItem[]; nextToken?: string };
          },
          { nextToken?: string }
        > = await listCallLists({
          variables: { nextToken },
        });

        callLists = [...callLists, ...(result.data?.listCallLists.items ?? [])];
        nextToken = result.data?.listCallLists.nextToken;
      } while (nextToken);

      setCallLists(callLists);
    })();
  }, [listCallLists]);

  useEffect(() => {
    (async () => {
      let contactFlows: ContactFlowItem[] = [],
        nextToken;

      do {
        const result: LazyQueryResult<
          {
            listContactFlows: { items: ContactFlowItem[]; nextToken?: string };
          },
          { nextToken?: string }
        > = await listContactFlows({
          variables: { nextToken },
        });

        contactFlows = [
          ...contactFlows,
          ...(result.data?.listContactFlows.items ?? []),
        ];
        nextToken = result.data?.listContactFlows.nextToken;
      } while (nextToken);

      setContactFlows(contactFlows);
    })();
  }, [listContactFlows]);

  useSubscription<{ onCreateCallList: CallListItem }>(
    gql(ON_CREATE_CALL_LIST),
    {
      onSubscriptionData: (options) => {
        if (options.subscriptionData.error)
          console.error(options.subscriptionData.error);

        setCallLists((callLists) =>
          options.subscriptionData.data?.onCreateCallList
            ? [...callLists, options.subscriptionData.data?.onCreateCallList]
            : callLists
        );
      },
      shouldResubscribe: true,
    }
  );

  useSubscription<{ onCreateContactFlow: ContactFlowItem }>(
    gql(ON_CREATE_CONTACT_FLOW),
    {
      onSubscriptionData: (options) => {
        if (options.subscriptionData.error)
          console.error(options.subscriptionData.error);

        setContactFlows((contactFlows) =>
          options.subscriptionData.data?.onCreateContactFlow
            ? [
                ...contactFlows,
                options.subscriptionData.data?.onCreateContactFlow,
              ]
            : contactFlows
        );
      },
      shouldResubscribe: true,
    }
  );

  useSubscription<{ onUpdateCallList: CallListItem }>(
    gql(ON_UPDATE_CALL_LIST),
    {
      onSubscriptionData: (options) => {
        options.subscriptionData.data;

        if (options.subscriptionData.error)
          console.error(options.subscriptionData.error);

        setCallLists((callLists) => {
          return callLists.map((callList) => {
            return callList.id ===
              options.subscriptionData.data?.onUpdateCallList.id
              ? options.subscriptionData.data?.onUpdateCallList
              : callList;
          });
        });
      },
      shouldResubscribe: true,
    }
  );

  useSubscription<{ onUpdateContactFlow: ContactFlowItem }>(
    gql(ON_UPDATE_CONTACT_FLOW),
    {
      onSubscriptionData: (options) => {
        options.subscriptionData.data;

        if (options.subscriptionData.error)
          console.error(options.subscriptionData.error);

        setContactFlows((contactFlows) => {
          return contactFlows.map((contactFlow) => {
            return contactFlow.id ===
              options.subscriptionData.data?.onUpdateContactFlow.id
              ? options.subscriptionData.data?.onUpdateContactFlow
              : contactFlow;
          });
        });
      },
      shouldResubscribe: true,
    }
  );

  return (
    <StateContext.Provider
      value={{
        callLists,
        contactFlows,
      }}
    >
      {children}
    </StateContext.Provider>
  );
}
