import { Alert, AlertProps, Snackbar } from "@mui/material";
import React from "react";

type SnackbarAlertProps = Omit<AlertProps, "onClose"> & {
  onClose: () => void;
  open: boolean;
};

export default function SnackbarAlert({
  onClose: handleClose,
  open,
  ...props
}: SnackbarAlertProps) {
  return (
    <Snackbar autoHideDuration={6000} onClose={handleClose} open={open}>
      <Alert {...props} onClose={handleClose} />
    </Snackbar>
  );
}
