import { useQuery } from "@apollo/client";
import { format } from "date-fns";
import { gql } from "@apollo/client";
import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { StateContext } from "../../../contexts";

export const GET_CALL_LIST_CREATED_AT = gql`
  query GetCallList($callListId: ID!) {
    getCallList(id: $callListId) {
      id
      createdAt
    }
  }
`;

export default function ContactsByCallListByContactFlowTitle() {
  const { contactFlowId, callListId } = useParams<{
    contactFlowId: string;
    callListId: string;
  }>();
  const { contactFlows } = useContext(StateContext);
  const {
    error: getCallListCreatedAtError,
    loading: getCallListCreatedAtLoading,
    data: getCallListCreatedAtData,
  } = useQuery(GET_CALL_LIST_CREATED_AT, {
    variables: { callListId },
  });

  const contactFlow = contactFlows.find((contactFlow) => {
    return contactFlow.id === contactFlowId;
  });

  // TODO: Loading state
  if (getCallListCreatedAtLoading) return <></>;
  else if (contactFlow && getCallListCreatedAtData)
    return (
      <>
        {`${contactFlow.name} > ${format(
          new Date(getCallListCreatedAtData?.getCallList.createdAt || null),
          "dd/MM/yyyy HH:mm"
        )}`}
      </>
    );
  else {
    console.error(getCallListCreatedAtError);
    return <></>;
  }
}
