import { Autocomplete, TextField, TextFieldProps } from "@mui/material";
import { useField } from "formik";
import React from "react";

type ChipFieldProps = TextFieldProps;

export default function ChipField(props: ChipFieldProps) {
  return (
    <Autocomplete
      freeSolo
      multiple
      options={[]}
      renderInput={(params) => (
        <TextField variant="outlined" {...props} {...params} />
      )}
    />
  );
}

type ChipFieldFormikProps = ChipFieldProps & { name: string };

export function ChipFieldFormik(props: ChipFieldFormikProps) {
  const [field, meta, { setValue }] = useField(props.name);
  const error = meta.touched ? meta.error : undefined;

  return (
    <ChipField
      {...props}
      {...field}
      error={Boolean(error)}
      helperText={error}
      onChange={setValue}
    />
  );
}
