import {
  CallSplit as CallSplitIcon,
  DynamicForm as DynamicFormIcon,
  Settings as SettingsIcon
} from "@mui/icons-material";
import {
  Chip,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
  Typography,
  useTheme
} from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { useContactCrm } from "../../hooks";
import ContactCrm from "../../lib/ContactCrm";
import {ICSSProperties, Strategy, StrategyPayloadConfigTypes} from "../../types";
import StrategyEditDialog from "./dialogs/StrategyEditDialog";

const useStyles = (theme: Theme): ICSSProperties => ({
  root: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(5),
  },

  strategyHeader: {
    marginBottom: theme.spacing(3),
  },

  strategyHeaderTitle: {
    display: "flex",
    alignItems: "center",

    "& > .MuiChipRoot": {
      marginLeft: theme.spacing(2),
    },
  },

  strategyHeaderSubtitle: {
    marginBottom: theme.spacing(1.5),
  },

  strategyHeaderCaption: {
    fontStyle: "italic",
  },

  headerButtons: {
    "& .MuiButtonBase-root": {
      marginRight: theme.spacing(1.5),
      "&:last-child": {
        marginRight: 0,
      },
    },
  },

  chipEdit: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    "& .MuiChip-icon": {
      color: "#fff",
      marginRight: "-7px",
    },
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
    "& .MuiChip-label": {
      paddingRight: 0,
    },
  },

  strategyLoading: {
    padding: theme.spacing(10),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& > div": {
      marginRight: theme.spacing(1),
    },
  },

  scripts: {
    border: "1px solid #e3e3e3",
    padding: "20px 30px",
    marginTop: "20px",
    marginBottom: "20px",
    backgroundColor: "#ffffff",
    borderRadius: "3px",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

export default function StrategySettings(
  props: RouteComponentProps<{ strategyId: string }>
): JSX.Element {
  const theme = useTheme()
  const styles = useStyles(theme);

  const strategyId = props.match.params.strategyId;

  // CRM
  const crm = useContactCrm();

  // Redirect
  const [redirect, setRedirect] = useState<string>();

  const [loadingStrategy, setLoadingStrategy] = useState(true);
  const [strategy, setStrategy] = useState<Strategy>();
  const [openEditStrategyDialog, setOpenEditStrategyDialog] = useState(false);

  const getStrategy = async (crm: ContactCrm, strategyId: string) => {
    return crm.getStrategy(strategyId);
  };

  const loadStrategy = async (crm: ContactCrm, strategyId: string) => {
    setLoadingStrategy(true);
    const strategy = await getStrategy(crm, strategyId);
    setStrategy(strategy);
    setLoadingStrategy(false);
  };

  useEffect(() => {
    if (crm && strategyId) {
      loadStrategy(crm, strategyId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crm, strategyId]);

  const editStrategy = async (data: Partial<Strategy>) => {
    setLoadingStrategy(true);

    await crm.updateStrategy(
      strategyId,
      JSON.stringify({
        name: data.name,
        description: data.description,
      })
    );

    const strategy = await getStrategy(crm, strategyId);
    setStrategy(strategy);
    setLoadingStrategy(false);
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  if (loadingStrategy) {
    return (
      <Box sx={styles.strategyLoading}>
        <CircularProgress size={20} />
        <span>Loading strategy...</span>
      </Box>
    );
  } else if (!loadingStrategy && strategy) {
    return (
      <Box sx={styles.root}>
        {openEditStrategyDialog && (
          <StrategyEditDialog
            name={strategy.name}
            description={strategy.description}
            mode="edit"
            clickYes={(data) => editStrategy(data)}
            clickNo={() => setOpenEditStrategyDialog(false)}
            clickClose={() => setOpenEditStrategyDialog(false)}
          />
        )}

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box sx={styles.strategyHeader}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box sx={styles.headerButtons}>
                  <Chip
                    icon={<SettingsIcon />}
                    sx={styles.chipEdit}
                    onClick={() => {
                      setOpenEditStrategyDialog(true);
                    }}
                  />
                </Box>
              </Box>

              {strategy.description && (
                <Typography
                  component="p"
                  variant="body1"
                  sx={styles.strategyHeaderSubtitle}
                >
                  {strategy.description}
                </Typography>
              )}
              <Typography
                component="p"
                variant="caption"
                sx={styles.strategyHeaderCaption}
              >
                Last updated{" "}
                {format(new Date(strategy.updatedWhen), "dd/MM/yyyy HH:mm")} by{" "}
                {strategy.updatedBy}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography component="h5" variant="h5">
              Strategy Scripts
            </Typography>

            <Box sx={styles.scripts}>
              <Grid container direction="column" spacing={2}>
                <Grid item xs={12}>
                  <List>
                    {strategy.payload.config.map((payloadConfig) => (
                      <ListItem key={payloadConfig.name}>
                        <ListItemIcon>
                          {payloadConfig.type === StrategyPayloadConfigTypes.CallProfile && <CallSplitIcon />}
                          {payloadConfig.type === StrategyPayloadConfigTypes.WebProfile && <DynamicFormIcon />}
                        </ListItemIcon>
                        <ListItemText
                          primary={payloadConfig.label}
                          secondary={payloadConfig.type}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  }

  return <></>;
}
