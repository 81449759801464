import { Textsms as TextSmsIcon } from "@mui/icons-material";
import React from "react";
import { Route, RouteProps, Switch } from "react-router-dom";
import SmsTemplateCreate from "./SmsTemplatesCreate";
import SmsTemplateEdit from "./SmsTemplatesEdit";
import SmsTemplatesPage from "./SmsTemplatesPage";

export const SmsTemplatesConfig = {
  title: 'Sms Templates',
  icon: <TextSmsIcon />,
  path: '/sms-templates'
}

export const SmsTemplatesRoutes = () => {
  const routes: RouteProps[] = [
    {
      component: SmsTemplateCreate,
      path: [`${SmsTemplatesConfig.path}/create`],
    },
    {
      component: SmsTemplateEdit,
      path: [`${SmsTemplatesConfig.path}/:smsTemplateId`],
    },
    {
      component: SmsTemplatesPage,
      path: SmsTemplatesConfig.path,
    },
  ];

  return (
    <Switch>
      {routes.map(({ component, path }, index) => (
        <Route component={component} key={index} path={path} />
      ))}
    </Switch>
  );
}
