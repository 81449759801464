import { useAuthenticator } from "@aws-amplify/ui-react";
import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import userAuthorised from "../../utils/auth/userAuthorised";

interface RequiresAuthorisationProps {
  children: JSX.Element;
  groups: string[];
}

export default function RequiresAuthorisation(
  props: RequiresAuthorisationProps
) {
  const { signOut, user } = useAuthenticator();

  if (userAuthorised(user, props.groups)) return props.children;

  return (
    <Grid
      alignItems="center"
      container
      justifyContent="center"
      sx={{
        height: "100vh",
        overFlowY: "hidden",
        width: "100vw",
      }}
    >
      <Grid container item justifyContent="center" spacing={2}>
        <Grid item xs={12}>
          <Typography align="center">
            You are not authorised to access this content. Please contact your
            administrator.
          </Typography>
        </Grid>
        <Grid item>
          <Button color="primary" onClick={signOut} variant="contained">
            Sign out
          </Button>
        </Grid>
        {
          // Includes the currently loaded page
          window.history.length > 1 && (
            <Grid item>
              <Button
                color="primary"
                onClick={() => window.history.back()}
                variant="outlined"
              >
                Go back
              </Button>
            </Grid>
          )
        }
      </Grid>
    </Grid>
  );
}
