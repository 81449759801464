import { gql, useQuery } from "@apollo/client";
import { Alert, useTheme } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { toSentenceCase } from "../../../utils/to-sentence-case";
import { HorizontalBar } from "../../charts";

const GET_CONTACT_PHONE_OUTCOMES_COUNT_BY_CALL_LIST_ID = gql`
  query GetContactsPhoneOutcomesCountByCallListId($id: ID!) {
    getContactsPhoneOutcomesCountByCallListId(id: $id) {
      callerReached
      transferredToPhoneNumber
      transferredToQueue
      transferredToVoicemail
      voicemailLeft
      connected
      hungUp
      engaged
      callGuardian
      noAnswer
      invalidNumber
      error
    }
  }
`;

export default function ContactsPhoneOutcomesCountByCallListIdChart() {
  const { callListId } = useParams<{ callListId: string }>();
  const { error, loading, data } = useQuery(
    GET_CONTACT_PHONE_OUTCOMES_COUNT_BY_CALL_LIST_ID,
    {
      variables: { id: callListId },
    }
  );
  const theme = useTheme();

  // TODO: Loading state
  if (loading) return <></>;
  else if (data) {
    const phoneOutcomes = Object.keys(
      data.getContactsPhoneOutcomesCountByCallListId
    ).filter((phoneOutcome) => phoneOutcome !== "__typename");
    return (
      <HorizontalBar
        data={{
          datasets: [
            {
              data: phoneOutcomes.map(
                (phoneOutcome) =>
                  data?.getContactsPhoneOutcomesCountByCallListId[
                    phoneOutcome
                  ] || 0
              ),
              backgroundColor: phoneOutcomes.map((phoneOutcome) => {
                switch (phoneOutcome) {
                  case "callerReached":
                  case "transferredToPhoneNumber":
                  case "transferredToQueue":
                  case "transferredToVoicemail":
                    return theme.palette.success.main;
                  case "voicemailLeft":
                  case "connected":
                  case "hungUp":
                    return theme.palette.info.main;
                  case "engaged":
                  case "callGuardian":
                  case "noAnswer":
                    return theme.palette.warning.main;
                  case "invalidNumber":
                  case "error":
                    return theme.palette.error.main;
                  default:
                    return undefined;
                }
              }),
            },
          ],
          labels: phoneOutcomes.map((phoneOutcome) =>
            toSentenceCase(phoneOutcome)
          ),
        }}
        title="Outcome"
      />
    );
  } else {
    console.error(error);
    return (
      <Alert severity="error">Unable to load contacts phone outcomes</Alert>
    );
  }
}
