import { Tab, Tabs } from "@mui/material";
import { sortBy } from "lodash";
import React from "react";
import { Link, matchPath, useLocation, useParams } from "react-router-dom";
import { Route } from "../../../types";

export default function ContactFlowTabs() {
  const { contactFlowId } = useParams<{ contactFlowId: string }>();
  const { pathname } = useLocation();

  const routes: Route[] = [
    {
      href: `/contactFlows/${contactFlowId}/settings`,
      listIndex: 4,
      path: "/contactFlows/:contactFlowId/settings",
      text: "Settings",
    },
    {
      href: `/contactFlows/${contactFlowId}/inbound`,
      listIndex: 3,
      path: "/contactFlows/:contactFlowId/inbound",
      text: "Inbound",
    },
    {
      href: `/contactFlows/${contactFlowId}/outbound`,
      listIndex: 2,
      path: "/contactFlows/:contactFlowId/outbound",
      text: "Outbound",
    },
    {
      href: `/contactFlows/${contactFlowId}/callLists`,
      listIndex: 1,
      path: [
        "/contactFlows/:contactFlowId/callLists",
        "/contactFlows/:contactFlowId/callLists/:callListId?",
      ],
      text: "Call Lists",
    },
    {
      href: `/contactFlows/${contactFlowId}/contacts`,
      listIndex: 0,
      path: [
        "/contactFlows/:contactFlowId",
        "/contactFlows/:contactFlowId/contacts",
      ],
      text: "Call Status",
    },
  ];

  const matchedPath = routes.find(({ path }) => matchPath(pathname, { path }));

  return (
    <Tabs
      aria-label="call profile tabs"
      sx={{
        marginLeft: "56px",
      }}
      textColor="inherit"
      value={matchedPath?.listIndex}
    >
      {sortBy(routes, "listIndex").map(({ text, href }, index) => (
        <Tab component={Link} key={index} label={text} to={href} />
      ))}
    </Tabs>
  );
}
