import {
  AddCircle as AddCircleIcon,
  RemoveCircle as RemoveCircleIcon
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Theme,
  useTheme
} from "@mui/material";
import { FieldArray, Form } from "formik";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { Alert, Formik } from "../../components/formik";
import {
  SelectFormik,
  TextFieldFormik
} from "../../components/inputs";
import { useContactCrm } from "../../hooks";
import {
  ICSSProperties, SmsSender, SmsTemplate
} from "../../types";
import { SmsTemplatesConfig } from "./SmsTemplatesRoutes";

const useStyles = (theme: Theme): ICSSProperties => ({
  root: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(5),
  },
  smsTemplatesLoading: {
    padding: theme.spacing(10),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& > div": {
      marginRight: theme.spacing(1),
    },
  },
});

const smsTemplateSchema: yup.Schema<Omit<SmsTemplate, "id" | "createdAt">> = yup
  .object({
    type: yup.string().matches(/^internal|external$/).required(),
    name: yup.string().required('Required'),
    senderId: yup.string().required('Required'),
    attributeKeys: yup.array(yup.string().required()).required('Required'),
    externalId: yup.string(),
  })
  .required('Required');

const initialStatus = {
  submitted: false,
  errors: null,
};

export default function SmsTemplateCreate(): JSX.Element {
  const theme = useTheme();
  const history = useHistory();
  const styles = useStyles(theme);

  // CRM
  const crm = useContactCrm();
  const [loadingSenders, setLoadingSenders] = useState(true);
  const [smsSenders, setSmsSenders] = useState<SmsSender[]>();
  const [redirect, setRedirect] = useState<string>();

  const [savedValues, setSavedValues] = useState<Omit<SmsTemplate, "id" | "createdAt">>({
    type: 'external',
    name: '',
    senderId: '',
    attributeKeys: [],
    body: undefined,
    externalId: undefined,
  });

  useEffect(() => {
    const loadSenders = async () => {
      if (crm) {
        setLoadingSenders(true);
        const res = await crm.listSmsSenders();
        setSmsSenders(res);
        setLoadingSenders(false);
      }
    };

    loadSenders();
  }, [crm]);

  if (loadingSenders) {
    return (
      <Box sx={styles.root}>
        <Box sx={styles.smsTemplatesLoading}>
          <CircularProgress size={20} />
          <span> Loading SMS Senders...</span>
        </Box>
      </Box>
    );
  } else {
    return (
      <Box sx={styles.root}>
        <Formik
          validationSchema={smsTemplateSchema}
          initialStatus={initialStatus}
          initialValues={savedValues}
          onSubmit={async (values, { setStatus }) => {
            setStatus(initialStatus);
            try {
              const res = await crm.createSmsTemplate(JSON.stringify({
                name: values.name,
                type: values.type,
                senderId: values.senderId,
                externalId: values?.externalId,
                attributeKeys: values?.attributeKeys
              }));
              if (!res) {
                throw new Error("Failed to create SMS Template")
              }
              setStatus({ submitted: true, error: null });
              setSavedValues(values);
              history.push(SmsTemplatesConfig.path);
            } catch (err) {
              console.error(err);
              setStatus({ submitted: true, error: err });
            }
          }}
        >
          {({ values }) => (
            <Card>
              <CardContent>
                <Form>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <SelectFormik
                        label="Sender"
                        name="senderId"
                        required
                      >
                        {smsSenders!.filter(smsSender => smsSender.type === values.type).map((smsSender) => (
                          <MenuItem key={smsSender.id} value={smsSender.id}>{smsSender.name}</MenuItem>
                        ))}
                      </SelectFormik>
                    </Grid>

                    <Grid item xs={8}>
                      <TextFieldFormik label="External ID" name="externalId" required={values.type === 'external'} />
                    </Grid>

                    <Grid item xs={12}>
                      <TextFieldFormik label="Name" name="name" required />
                    </Grid>

                    <Grid item xs={12}>
                      <FieldArray name="attributeKeys">
                        {({ insert, remove, push }) => (
                          <>
                            <Grid item xs={12}>
                              <FormLabel>Attributes</FormLabel>
                              <IconButton
                                onClick={() => push("")}
                                size="large"
                                color="primary"
                              >
                                <AddCircleIcon />
                              </IconButton>
                            </Grid>
                            <Grid item xs={12}>
                              <List dense={true}>
                                {values.attributeKeys.length > 0 && values.attributeKeys.map((attrKey, index) => (
                                  <ListItem key={index} secondaryAction={
                                    <IconButton edge="end" aria-label="delete"
                                      onClick={() => remove(index)}
                                    >
                                      <RemoveCircleIcon color="error" />
                                    </IconButton>
                                  }>
                                    <ListItemText>
                                      <TextFieldFormik name={`attributeKeys.${index}`} margin="dense" />
                                    </ListItemText>
                                  </ListItem>
                                ))}
                              </List>
                            </Grid>
                          </>
                        )}
                      </FieldArray>
                    </Grid>


                    <Grid item xs={12}>
                      <Button color="primary" type="submit" variant="contained">
                        Create Template
                      </Button>
                      <Button href={SmsTemplatesConfig.path}>
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
                <Alert
                  successMessage="SMS Template creates successfully"
                  errorMessage="Unable to create SMS Template"
                />
              </CardContent>
            </Card>
          )}
        </Formik >
      </Box>
    );
  }
};
