import React, { useState } from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";
import ExportTemplateDetails from "./sections/ExportTemplateDetails";
import ExportTemplatePicker from "./sections/ExportTemplatePicker";
import {Box, Theme, useTheme} from "@mui/material";
import {ICSSProperties} from "../../types";

const useStyles = (theme: Theme): ICSSProperties => ({
  root: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(5),
  },
});

export default function ExportTemplates(
  props: RouteComponentProps<{ exportTemplateId: string }>
) {
  const theme = useTheme()
  const styles = useStyles(theme);

  const paramExportTemplateId = props.match.params.exportTemplateId;
  const [exportTemplateId, setExportTemplateId] = useState(
    paramExportTemplateId
  );

  if (paramExportTemplateId !== exportTemplateId) {
    return <Redirect to={`/export-templates/${exportTemplateId}`} />;
  }

  return (
    <Box sx={styles.root}>
      <ExportTemplatePicker
        exportTemplateId={exportTemplateId}
        setExportTemplateId={setExportTemplateId}
      />
      <ExportTemplateDetails
        exportTemplateId={exportTemplateId}
        setExportTemplateId={setExportTemplateId}
      />
    </Box>
  );
}
