import { styled } from "@mui/material/styles";
import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { UpdateContactFlowSettingsForm } from ".";
import { StateContext } from "../../../contexts";

const PREFIX = "UpdateContactFlowSettings";

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(5),
  },
}));

export default function UpdateContactFlowSettings() {
  const { contactFlowId } = useParams<{ contactFlowId: string }>();
  const { contactFlows } = useContext(StateContext);

  const contactFlow = contactFlows?.find(
    (contactFlow) => contactFlow.id === contactFlowId
  );

  return (
    <Root className={classes.root}>
      {contactFlow ? (
        <UpdateContactFlowSettingsForm contactFlow={contactFlow} />
      ) : (
        <></>
      )}
    </Root>
  );
}
