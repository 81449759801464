import { AddCircle as AddCircleIcon } from "@mui/icons-material";
import {Box, Grid, IconButton, MenuItem, Theme, useTheme} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { orderBy } from "lodash";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import useContactCrm from "../../../hooks/useContactCrm";
import ContactCrm from "../../../lib/ContactCrm";
import {ExportTemplate, ICSSProperties} from "../../../types";
import ExportTemplateCreateEditDialog from "../dialogs/ExportTemplateCreateEditDialog";

const useStyles = (theme: Theme): ICSSProperties => ({
  root: {
    padding: `0 ${theme.spacing(3)} ${theme.spacing(2)}`,
  },

  exportTemplatesLoading: {
    padding: theme.spacing(10),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& > div": {
      marginRight: theme.spacing(1),
    },
  },

  topControl: {
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#fff",
    padding: "10px 20px",
    border: "1px solid #e0e0e0",
  },

  exportControls: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
  },

  exportTemplateDropdown: {
    width: "400px",
    marginRight: "30px",

    "& .MuiSelect-select": {
      textAlign: "left",
    },
  },

  createExportTemplateRow: {
    "& .MuiChip-icon": {
      fontSize: "1.8rem",
    },
  },
});

type ExportTemplatePickerProps = {
  exportTemplateId: string;
  setExportTemplateId: Dispatch<SetStateAction<string>>;
};

export default function ExportTemplatePicker({
  exportTemplateId,
  setExportTemplateId,
}: ExportTemplatePickerProps): JSX.Element {
  const theme = useTheme()
  const styles = useStyles(theme);

  // CRM
  const crm = useContactCrm();
  const [loadingExportTemplates, setLoadingExportTemplates] = useState(true);
  const [openCreateExportTemplateDialog, setOpenCreateExportTemplateDialog] =
    useState(false);

  //
  const [exportTemplates, setExportTemplates] = useState<ExportTemplate[]>([]);

  const getAllExportTemplates = async (crm: ContactCrm) => {
    const tmpExportTemplates = await crm.listExportTemplates();
    const exportTemplates = orderBy(tmpExportTemplates, ["name"], ["asc"]);
    setExportTemplates(exportTemplates);
  };

  useEffect(() => {
    if (crm) {
      setLoadingExportTemplates(true);
      getAllExportTemplates(crm);
      setLoadingExportTemplates(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crm, exportTemplateId]);

  const createExportTemplate = async (data: Partial<ExportTemplate>) => {
    const et = await crm.createExportTemplate(JSON.stringify(data));

    if (et.exportTemplateId) {
      setExportTemplateId(et.exportTemplateId);
    }
  };

  const handleExportTemplateChange = async (e: SelectChangeEvent<unknown>) => {
    setExportTemplateId(e.target.value as string);
  };

  if (loadingExportTemplates) {
    return (
      <Box sx={styles.root}>
        <Box sx={styles.exportTemplatesLoading}>
          <CircularProgress size={20} />
          <span>Loading ...</span>
        </Box>
      </Box>
    );
  } else if (!loadingExportTemplates && Array.isArray(exportTemplates)) {
    return (
      <Box sx={styles.root}>
        {openCreateExportTemplateDialog && (
          <ExportTemplateCreateEditDialog
            clickYes={(data: Partial<ExportTemplate>) =>
              createExportTemplate(data)
            }
            clickNo={() => setOpenCreateExportTemplateDialog(false)}
            clickClose={() => setOpenCreateExportTemplateDialog(false)}
          />
        )}

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box sx={styles.topControl}>
              <Box sx={styles.exportControls}>
                <FormControl size={"small"} variant="outlined">
                  <InputLabel>Export Template</InputLabel>
                  <Select
                    label="Select a template"
                    sx={styles.exportTemplateDropdown}
                    onChange={handleExportTemplateChange}
                    value={exportTemplateId}
                  >
                    {loadingExportTemplates && (
                      <MenuItem value="" disabled>
                        Loading ...
                      </MenuItem>
                    )}
                    {!loadingExportTemplates &&
                      exportTemplates &&
                      exportTemplates.map((i) => (
                        <MenuItem
                          value={i.exportTemplateId}
                          key={i.exportTemplateId}
                        >
                          {i.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
              <Box sx={styles.createExportTemplateRow}>
                <span>Add template</span>
                <IconButton
                  aria-label="create"
                  color="primary"
                  onClick={() => setOpenCreateExportTemplateDialog(true)}
                  size="large"
                >
                  <AddCircleIcon />
                </IconButton>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  }

  return <></>;
}
