import {
  CallSplit as CallSplitIcon,
  CloudUpload as CloudUploadIcon,
  DynamicForm as DynamicFormIcon,
} from "@mui/icons-material";
import {
  SpeedDial as MuiSpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
} from "@mui/material";
import React, { useState } from "react";
import { CreateUpdateExternalApiDialog } from "../../../components";
import { useFeatures } from "../../../hooks";
import { CreateCallListDialog } from "./create-callList-dialog";
import { CreateContactFlowDialog } from "./create-contact-flow-dialog";
import { CreateWebformProfileDialog } from "./create-webform-profile-dialog";

export default function SpeedDial() {
  const features = useFeatures();
  const [openSpeedDial, setOpenSpeedDial] = useState(false);
  const [openCreateCallListDialog, setOpenCreateCallListDialog] =
    useState(false);
  const [openCreateCallProfileDialog, setOpenCreateCallProfileDialog] =
    useState(false);
  const [openCreateWebformProfileDialog, setOpenCreateWebformProfileDialog] =
    useState(false);
  const [openCreateExternalApiDialog, setOpenCreateUpdateExternalApiDialog] =
    useState(false);

  const handleOpenSpeedDial = () => setOpenSpeedDial(true);
  const handleCloseSpeedDial = () => setOpenSpeedDial(false);

  const handleOpenCreateCallListDialog = () =>
    setOpenCreateCallListDialog(true);
  const handleCloseCreateCallListDialog = () =>
    setOpenCreateCallListDialog(false);

  const handleOpenCreateCallProfileDialog = () =>
    setOpenCreateCallProfileDialog(true);
  const handleCloseCreateCallProfileDialog = () =>
    setOpenCreateCallProfileDialog(false);

  const handleOpenCreateWebformProfileDialog = () =>
    setOpenCreateWebformProfileDialog(true);
  const handleCloseCreateWebformProfileDialog = () =>
    setOpenCreateWebformProfileDialog(false);

  const handleOpenCreateUpdateExternalApiDialog = () =>
    setOpenCreateUpdateExternalApiDialog(true);
  const handleCloseCreateUpdateExternalApiDialog = () =>
    setOpenCreateUpdateExternalApiDialog(false);

  const actions = [
    {
      icon: <CloudUploadIcon />,
      tooltipTitle: "Call list",
      onClick: handleOpenCreateCallListDialog,
    },
    {
      icon: <CallSplitIcon />,
      tooltipTitle: "Call profile",
      onClick: handleOpenCreateCallProfileDialog,
    },
    ...(features.webForms
      ? [
          {
            icon: <DynamicFormIcon />,
            tooltipTitle: "Web profile",
            onClick: handleOpenCreateWebformProfileDialog,
          },
        ]
      : []),
    {
      icon: <span className="material-icons">api</span>,
      tooltipTitle: "External API",
      onClick: handleOpenCreateUpdateExternalApiDialog,
    },
  ];

  return (
    <>
      <MuiSpeedDial
        ariaLabel="SpeedDial"
        direction="down"
        FabProps={{
          color: "primary",
        }}
        icon={<SpeedDialIcon />}
        onClose={handleCloseSpeedDial}
        onOpen={handleOpenSpeedDial}
        open={openSpeedDial}
        sx={{
          position: "absolute",
          top: 20,
          l: 2,
        }}
      >
        {actions.map(({ icon, tooltipTitle, onClick }, index) => (
          <SpeedDialAction
            key={index}
            icon={icon}
            tooltipTitle={tooltipTitle}
            onClick={onClick}
          />
        ))}
      </MuiSpeedDial>
      <CreateCallListDialog
        open={openCreateCallListDialog}
        onClose={handleCloseCreateCallListDialog}
      />
      <CreateContactFlowDialog
        open={openCreateCallProfileDialog}
        onClose={handleCloseCreateCallProfileDialog}
      />
      <CreateWebformProfileDialog
        open={openCreateWebformProfileDialog}
        onClose={handleCloseCreateWebformProfileDialog}
      />
      <CreateUpdateExternalApiDialog
        open={openCreateExternalApiDialog}
        onClose={handleCloseCreateUpdateExternalApiDialog}
      />
    </>
  );
}
