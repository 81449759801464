import { useFormikContext } from "formik";
import React from "react";
import SnackbarAlert from "../alerts/SnackbarAlert";

type AlertProps = {
  errorMessage: string;
  successMessage: string;
};

export default function Alert({ errorMessage, successMessage }: AlertProps) {
  const { status, setStatus } = useFormikContext();

  return (
    <>
      <SnackbarAlert
        open={Boolean(status && status.submitted && !status.error)}
        onClose={() => setStatus({ ...status, submitted: false, error: null })}
        severity="success"
      >
        {successMessage}
      </SnackbarAlert>
      <SnackbarAlert
        open={Boolean(status && status.submitted && status.error)}
        onClose={() => setStatus({ ...status, submitted: false, error: null })}
        severity="error"
      >
        {errorMessage}
      </SnackbarAlert>
    </>
  );
}
