import {
  TimePicker as MuiTimePicker,
  TimePickerProps as MuiTimePickerProps,
} from "@mui/x-date-pickers/TimePicker";
import { parse } from "date-fns";
import { useField } from "formik";
import React from "react";
import { TextField } from ".";

type TimePickerProps = Omit<MuiTimePickerProps<Date, Date>, "renderInput"> & {
  helperText?: string;
  label: string;
};

export default function TimePicker({ helperText, ...props }: TimePickerProps) {
  return (
    <MuiTimePicker
      {...props}
      renderInput={(params) => (
        <TextField {...params} helperText={helperText} variant="outlined" />
      )}
    />
  );
}

type TimePickerFormikProps = Omit<TimePickerProps, "renderInput" | "value"> & {
  name: string;
};

export function TimePickerFormik({
  helperText,
  ...props
}: TimePickerFormikProps) {
  const [field, { error }] = useField(props.name);

  return (
    <TimePicker
      {...field}
      {...props}
      inputFormat="HH:mm"
      mask="__:__"
      helperText={error ?? helperText}
      value={field.value ? parse(field.value, "HH:mm", new Date()) : null}
    />
  );
}
