import {
  Authenticator,
  Image,
  Text,
  useTheme,
  View,
} from "@aws-amplify/ui-react";
import { AuthenticatorProps } from "@aws-amplify/ui-react/dist/types/components/Authenticator/Authenticator";
import { DefaultComponents } from "@aws-amplify/ui-react/dist/types/components/Authenticator/hooks/useCustomComponents/defaultComponents";
import { AuthFormFields } from "@aws-amplify/ui/dist/types/types/authenticator";
import React from "react";
import digitalSpaceIcon from "../assets/Icon pink400px.png";

const components: DefaultComponents = {
  ConfirmResetPassword: {
    Footer: PasswordPolicy,
  },
  ForceNewPassword: {
    FormFields: () => {
      return (
        <>
          <Authenticator.ForceNewPassword.FormFields />
          <PasswordPolicy />
        </>
      );
    },
  },
  Header() {
    const { tokens } = useTheme();

    return (
      <View padding={tokens.space.large} textAlign="center">
        <Image alt="Digital Space icon" height="94px" src={digitalSpaceIcon} />
      </View>
    );
  },
};

const formFields: AuthFormFields = {
  forceNewPassword: {
    name: {
      isRequired: true,
      order: 1,
      placeholder: "Full name",
    },
  },
  signIn: {
    username: {
      placeholder: "Email address",
    },
  },
};

export default function AuthProvider({
  children,
  ...props
}: Omit<AuthenticatorProps, "children"> & { children: JSX.Element }) {
  return (
    <Authenticator
      components={components}
      formFields={formFields}
      hideSignUp
      variation="modal"
      {...props}
    >
      {() => children}
    </Authenticator>
  );
}

function PasswordPolicy() {
  return (
    <>
      <Text>Password Requirements:</Text>
      <ul style={{ marginTop: "0" }}>
        <li>
          <Text>12-character minimum length</Text>
        </li>
        <li>
          <Text>Contains at least 1 number</Text>
        </li>
        <li>
          <Text>
            Contains at least 1 special character{" "}
            {
              "(^ $ * . [ ] { } ( ) ? - \" ! @ # % & /  , > < ' : ; | _ ~ ` + = )"
            }
          </Text>
        </li>
        <li>
          <Text>Contains at least 1 lowercase letter</Text>
        </li>
        <li>
          <Text>Contains at least 1 uppercase letter</Text>
        </li>
      </ul>
    </>
  );
}
