import { Check as CheckIcon, Error as ErrorIcon } from "@mui/icons-material";
import {
  Button,
  Divider,
  Grid,
  MenuItem,
  SelectChangeEvent,
  Theme,
  Typography,
  useTheme
} from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { Form } from "formik";
import { isEqual, sortBy } from "lodash";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import * as yup from "yup";
import { Formik } from "../../../components/formik";
import { Select, SelectFormik } from "../../../components/inputs";
import { StateContext } from "../../../contexts";
import useContactCrm from "../../../hooks/useContactCrm";
import {
  Campaign,
  ContactFlowItem,
  ICSSProperties,
  isCallProfile,
  isWebformProfile,
  Strategy,
  StrategyPayloadConfigTypes
} from "../../../types";

const useStyles = (theme: Theme): ICSSProperties => ({
  root: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(5),
  },

  strategyForm: {
    border: "1px solid #e3e3e3",
    padding: "20px 30px",
    marginTop: "20px",
    marginBottom: "20px",
    backgroundColor: "#ffffff",
    borderRadius: "3px",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  loading: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    backgroundColor: "#ffffff",
    fontWeight: "600",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 10,
    "& > div": {
      marginRight: theme.spacing(1),
    },
  },

  formContainer: {
    width: "70%",
    maxWidth: "700px",
    minWidth: "400px",
  },

  strategyDropdown: {
    "& > .MuiSelect-select": {
      display: "flex",
      alignItems: "center",

      "& > svg": {
        marginRight: theme.spacing(1),
      },
    },
  },

  strategyDropdownPopUp: {
    "& .MuiList-root > .MuiMenuItem-root svg": {
      marginRight: theme.spacing(1),
    },
  },

  strategyStatusHint: {
    opacity: "0.6",
    marginLeft: "6px",
    fontSize: "0.8rem",
    textTransform: "uppercase",
  },

  resultIconCheck: {
    marginLeft: theme.spacing(1),
    color: theme.palette.success.main,
  },

  resultIconError: {
    marginLeft: theme.spacing(1),
    color: theme.palette.error.main,
  },
});

type CampaignStrategyProps = {
  campaign: Campaign;
  triggerReloadCampaign: () => void;
};

export default function CampaignStrategy({
  campaign,
  triggerReloadCampaign,
}: CampaignStrategyProps) {
  const crm = useContactCrm();
  const { contactFlows } = useContext(StateContext);
  const [campaignStrategyResult, setCampaignStrategyResult] =
    useState<boolean>();
  const [strategies, setStrategies] = useState<Strategy[]>([]);
  const [strategy, setStrategy] = useState<Strategy>();
  const [updatingStrategy, setUpdatingStrategy] = useState(false);

  const theme = useTheme();
  const styles = useStyles(theme);

  useEffect(() => {
    (async () => {
      const strategies = await crm.listStrategies();
      setStrategies(strategies);
      setStrategy(
        strategies.find((strategy) => strategy.id === campaign.strategyId)
      );
    })();
  }, [campaign.strategyId, crm]);

  // Existing campaign data
  const initialValues = useMemo(
    () =>
      strategy?.payload.config.reduce<Record<string, string>>(
        (accumulator, currentValue) => {
          return {
            ...accumulator,
            [currentValue.name]:
              campaign?.strategyConfig?.[currentValue.name] ?? "",
          };
        },
        {}
      ),
    [campaign?.strategyConfig, strategy]
  );

  const validationSchema = useMemo(
    () =>
      yup.object(
        strategy?.payload.config.reduce(
          (accumulator, currentValue) => ({
            ...accumulator,
            [currentValue.name]: yup
              .string()
              .uuid("Invalid value")
              .required("Required"),
          }),
          {}
        )
      ),
    [strategy]
  );

  const handleChangeStrategy = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      setStrategy(
        strategies.find(
          (strategy) => strategy.id === (event.target.value as string)
        )
      );
    },
    [strategies]
  );

  const saveCampaignStrategy = useCallback(
    async (strategyConfig: Record<string, string>) => {
      return await crm.updateCampaign(
        campaign.id,
        JSON.stringify({
          strategyId: strategy?.id,
          strategyConfig,
        })
      );
    },
    [campaign.id, crm, strategy?.id]
  );

  if (!campaign) {
    return <></>;
  }

  const filteredContactFlows = contactFlows.filter((cf) => !cf.deleted);
  const sortedContactFlows = sortBy(filteredContactFlows, "name");
  const matchContactFlow = (strategyType: string) => (contactFlow: ContactFlowItem) => {
    const isWebStrategy = strategyType === StrategyPayloadConfigTypes.WebProfile
    const isCallStrategy = strategyType === StrategyPayloadConfigTypes.CallProfile

    // sms script
    if (isWebStrategy && isCallProfile(contactFlow)) {
      return false
    }

    // call script
    if (isCallStrategy && isWebformProfile(contactFlow)) {
      return false
    }

    // default
    return true
  }

  return (
    <Grid item xs={12}>
      <Typography component="h5" variant="h5">
        Campaign Strategy
      </Typography>

      <Box sx={styles.strategyForm}>
        {updatingStrategy && (
          <Box sx={styles.loading}>
            <CircularProgress size={20} />
            <span>Updating strategy ...</span>
          </Box>
        )}

        <Box sx={styles.formContainer}>
          <Grid container direction="column" spacing={2}>
            <Grid item xs="auto">
              <Select
                label="Strategy"
                onChange={handleChangeStrategy}
                disabled={!!campaign?.strategyId}
                size="small"
                value={strategy?.id ?? ""}
                helperText={campaign?.strategyId ? 'Campaign strategy cannot be updated once set' : undefined}
              >
                {strategies.map((strategy) => (
                  <MenuItem key={strategy.id} value={strategy.id}>
                    {strategy.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            {initialValues && strategy && validationSchema && (
              <Grid item xs="auto">
                <Formik
                  validationSchema={validationSchema}
                  initialValues={initialValues}
                  onSubmit={async (values, { resetForm }) => {
                    setUpdatingStrategy(true);
                    const saveCampaignStrategyResult =
                      await saveCampaignStrategy(values);
                    setCampaignStrategyResult(saveCampaignStrategyResult);
                    resetForm({ values, touched: {} });
                    setUpdatingStrategy(false);
                    await triggerReloadCampaign();
                  }}
                >
                  {(formikProps) => {
                    const formikInitialValues = formikProps.initialValues;
                    const formikValues = formikProps.values;
                    const formUpdated = !isEqual(
                      formikInitialValues,
                      formikValues
                    );

                    return (
                      <Form>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                          {strategy.payload.config.map((cnfg) => (
                            <Grid item key={cnfg.label} xs={12}>
                              <SelectFormik
                                label={cnfg.label}
                                name={cnfg.name}
                                size="small"
                                sx={styles.strategyDropdown}
                                MenuProps={{ sx: styles.strategyDropdownPopUp }}
                              >
                                {sortedContactFlows.filter(matchContactFlow(cnfg.type)).map((contactFlow) => {
                                  return (
                                    <MenuItem
                                      value={contactFlow.id}
                                      key={contactFlow.id}
                                    >
                                      {contactFlow.name}
                                      {isCallProfile(contactFlow) && (
                                        <>
                                          {" "}
                                          <span
                                            style={styles.strategyStatusHint}
                                          >
                                            {contactFlow.paused
                                              ? "(paused)"
                                              : "(running)"}
                                          </span>
                                        </>
                                      )}
                                    </MenuItem>
                                  );
                                })}
                              </SelectFormik>
                            </Grid>
                          ))}
                          <Grid item xs={12}>
                            <Box
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Button
                                color={formUpdated ? "primary" : undefined}
                                type="submit"
                                variant="contained"
                                disabled={!formUpdated}
                              >
                                Update
                              </Button>

                              {!formUpdated &&
                                typeof campaignStrategyResult === "boolean" && (
                                  <>
                                    {campaignStrategyResult && (
                                      <span style={styles.resultIconCheck}>
                                        <CheckIcon />
                                      </span>
                                    )}
                                    {!campaignStrategyResult && (
                                      <span style={styles.resultIconError}>
                                        <ErrorIcon />
                                      </span>
                                    )}
                                  </>
                                )}
                            </Box>
                          </Grid>
                        </Grid>
                      </Form>
                    );
                  }}
                </Formik>
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
    </Grid>
  );
}
