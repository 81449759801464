import { gql, useMutation } from "@apollo/client";
import { useAuthenticator } from "@aws-amplify/ui-react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
} from "@mui/material";
import React from "react";
import { DialogContent, DialogTitle } from "../../../components/dialog";
import { updateContactFlow as UPDATE_CONTACT_FLOW } from "../../../graphql/mutations";
import { ContactFlow } from "../../../types";

type ConfirmDeleteDialogProps = {
  open: boolean;
  contactFlowToDelete: Pick<ContactFlow, "id" | "name">;
  onClose: () => void;
};

export default function ConfirmDeleteDialog({
  open,
  contactFlowToDelete: { id, name },
  onClose,
}: ConfirmDeleteDialogProps) {
  const { user } = useAuthenticator();
  const [updateContactFlow] = useMutation(gql(UPDATE_CONTACT_FLOW));

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle onClose={onClose}>
        Are you sure you want to delete the following call profile?
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{name}</DialogContentText>
        <DialogContentText>
          Please note that pending contacts will not be processed.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} type="button">
          No
        </Button>
        <Button
          color="primary"
          onClick={() => {
            updateContactFlow({
              variables: {
                input: {
                  id,
                  deleted: true,
                  deletedAt: new Date().toISOString(),
                  deletedBy: user.attributes?.name,
                },
              },
            });
            onClose();
          }}
          type="button"
          variant="contained"
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
