import {
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
} from "@mui/material";
import { useField } from "formik";
import React, { ChangeEvent, ChangeEventHandler, useState } from "react";

type TextFieldProps = Omit<MuiTextFieldProps, "onChange"> & {
  onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
};

export default function Json({
  helperText,
  inputProps,
  onChange,
  ...props
}: TextFieldProps) {
  const [error, setError] = useState<string>();

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    try {
      JSON.parse(event.target.value);
      setError(undefined);
    } catch (e) {
      setError("Invalid JSON.");
    }

    onChange(event);
  };

  return (
    <MuiTextField
      fullWidth
      helperText={error ?? helperText}
      multiline
      variant="outlined"
      {...props}
      inputProps={{
        autoComplete: "off",
        ...inputProps,
      }}
      onChange={handleChange}
    />
  );
}

type TextFieldFormikProps = Omit<TextFieldProps, "onChange"> & {
  name: string;
};

export function JsonFormik({ helperText, ...props }: TextFieldFormikProps) {
  const [field, { error }] = useField(props.name);
  return (
    <Json
      {...props}
      {...field}
      error={Boolean(error)}
      helperText={error ?? helperText}
      value={field.value ?? ""}
    />
  );
}
