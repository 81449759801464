export type Prompt = {
  id: string;
  arn: string;
  name: string;
};

/**
 * Intents
 */
type Intent = `${Intents}`;

export enum Intents {
  Date = "Date",
  Number = "Number",
  OpenEnded = "OpenEnded",
  YesNo = "YesNo",
}

/**
 * Attributes
 */
export type AttributeCondition = `${AttributeConditions}`;

export enum AttributeConditions {
  DoesNotEqual = "DoesNotEqual",
  Equals = "Equals",
  IsLessThan = "IsLessThan",
  IsLessOrEqualTo = "IsLessOrEqualTo",
  IsGreaterThan = "IsGreaterThan",
  IsGreaterOrEqualTo = "IsGreaterOrEqualTo",
  StartsWith = "StartsWith",
  EndsWith = "EndsWith",
  Contains = "Contains",
}

/**
 * Modules
 */
export enum ModuleTypes {
  Say = "Say",
  Ask = "Ask",
  Decision = "Decision",
  Attribute = "Attribute",
  SendSMS = "SendSMS",
  CallExternalApi = "CallExternalApi",
  TransferToQueue = "TransferToQueue",
  TransferToPhoneNumber = "TransferToPhoneNumber",
  TransferToVoicemail = "TransferToVoicemail",
  End = "EndCall",
}

export type ModuleType = `${ModuleTypes}`;

export type Module =
  | SayModule
  | AskModule
  | DecisionModule
  | AttributeModule
  | SendSMSModule
  | CallExternalApiModule
  | TransferToPhoneNumberModule
  | TransferToQueueModule
  | TransferToVoicemailModule
  | EndCallModule;


export type AskModule = {
  id: string;
  audioPromptArn: string;
  type: ModuleTypes.Ask;
  attribute: string;
  intent: Intent;
  text: string;
};

export type AttributeModule = {
  id: string;
  type: ModuleTypes.Attribute;
  attribute: string;
  value: string;
};

export type CallExternalApiModule = {
  id: string;
  type: ModuleTypes.CallExternalApi;
};

export type EndCallModule = {
  id: string;
  type: ModuleTypes.End;
};

export type SayModule = {
  id: string;
  audioPromptArn: string;
  type: ModuleTypes.Say;
  text: string;
};

export type SendSMSModule = {
  id: string;
  type: ModuleTypes.SendSMS;
};

export type TransferToPhoneNumberModule = {
  id: string;
  type: ModuleTypes.TransferToPhoneNumber;
};

export type TransferToQueueModule = {
  id: string;
  type: ModuleTypes.TransferToQueue;
};

export type TransferToVoicemailModule = {
  id: string;
  audioPromptArn: string;
  type: ModuleTypes.TransferToVoicemail;
  text: string;
};

type AttributeDecisionModule = {
  id: string;
  type: ModuleTypes.Decision;
  attribute: string;
  check: "Attribute";
  condition: AttributeCondition;
  modules: Module[];
  value: string;
};

export type HoursOfOperationCondition = `${HoursOfOperationConditions}`;
export enum HoursOfOperationConditions {
  InHours = "InHours",
  OutOfHours = "OutOfHours",
}

type HoursOfOperationDecisionModule = {
  id: string;
  type: ModuleTypes.Decision;
  attribute: string;
  check: "HoursOfOperation";
  condition: HoursOfOperationCondition;
  modules: Module[];
  value: string;
};

export type DecisionModule =
  | AttributeDecisionModule
  | HoursOfOperationDecisionModule;

/**
 * Helpers
 */
export function isAskModule(module: Module): module is AskModule {
  return module.type === ModuleTypes.Ask;
}

export function isAttributeModule(module: Module): module is AttributeModule {
  return module.type === ModuleTypes.Attribute;
}

export function isCallExternalApiModule(
  module: Module
): module is CallExternalApiModule {
  return module.type === ModuleTypes.CallExternalApi;
}

export function isDecisionModule(module: Module): module is DecisionModule {
  return module.type === ModuleTypes.Decision;
}

export function isEndCallModule(module: Module): module is EndCallModule {
  return module.type === ModuleTypes.End;
}

export function isSayModule(module: Module): module is SayModule {
  return module.type === ModuleTypes.Say;
}

export function isSendSMSModule(module: Module): module is SendSMSModule {
  return module.type === ModuleTypes.SendSMS;
}

export function isTransferToPhoneNumberModule(
  module: Module
): module is TransferToPhoneNumberModule {
  return module.type === ModuleTypes.TransferToPhoneNumber;
}

export function isTransferToQueueModule(
  module: Module
): module is TransferToQueueModule {
  return module.type === ModuleTypes.TransferToQueue;
}

export function isTransferToVoicemailModule(
  module: Module
): module is TransferToVoicemailModule {
  return module.type === ModuleTypes.TransferToVoicemail;
}