import { AmazonAIPredictionsProvider } from "@aws-amplify/predictions";
import {
  AmplifyProvider as AmplifyAmplifyProvider,
  Theme,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { Amplify, API, Auth, Predictions } from "aws-amplify";
import React, { ReactNode } from "react";
import awsExports from "../aws-exports";
// Required to prevent Amplify components having serif font family when built.
import "./amplify.css";

const amplifyConfig = {
  ...awsExports,
  aws_cognito_identity_pool_id:
    process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  // aws_cognito_region: "eu-west-2",
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id:
    process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  // oauth: "{}",
  // aws_cognito_login_mechanisms: JSON.parse('["Email"]'),
  // aws_cognito_signup_attributes: JSON.parse('["Email","Name"]'),
  // aws_cognito_mfa_configuration: "OFF",
  // aws_cognito_mfa_types: JSON.parse('["SMS"]'),
  // aws_cognito_password_protection_settings: JSON.parse('{"passwordPolicyMinLength": 8,"passwordPolicyCharacters": []}'),
  // aws_cognito_verification_mechanisms: JSON.parse('["Email"]'),
};

Amplify.configure(amplifyConfig);
Amplify.addPluggable(new AmazonAIPredictionsProvider());
API.configure(amplifyConfig);
Auth.configure(amplifyConfig);
Predictions.configure(amplifyConfig);

const theme: Theme = {
  name: "mui-theme",
  tokens: {
    colors: {
      brand: {
        primary: {
          10: { value: "#b3e5fd" },
          20: { value: "#80d4fc" },
          40: { value: "#24b6f9" },
          60: { value: "#009ae8" },
          80: { value: "#0077C0" },
          90: { value: "#02569e" },
        },
        secondary: {
          40: { value: "#fafafa" },
          60: { value: "#f5f5f5" },
          80: { value: "#e0e0e0" },
          90: { value: "#bdbdbd" },
          100: { value: "#9e9e9e" },
        },
      },
      overlay: {
        50: { value: "#f5f6f8" },
      },
    },
    fonts: {
      default: {
        static: { value: "Nunito, Arial, sans-serif" },
        variable: { value: "Nunito, Arial, sans-serif" },
      },
    },
  },
};

export default function AmplifyProvider({ children }: { children: ReactNode }) {
  return (
    <AmplifyAmplifyProvider theme={theme}>{children}</AmplifyAmplifyProvider>
  );
}
