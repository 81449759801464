import { Alert, Dialog, Typography } from "@mui/material";
import React from "react";
import { DialogContent, DialogTitle } from "../dialog";

type ErrorsDialogProps = {
  errors: string[];
  onClose: () => void;
  open: boolean;
};

export default function ErrorsDialog({
  errors,
  onClose,
  open,
}: ErrorsDialogProps) {
  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle onClose={onClose}>
        <Typography variant="h6" gutterBottom component="div">
          Errors
        </Typography>
      </DialogTitle>
      <DialogContent>
        {errors.map((error, index) => (
          <Alert key={index} severity="error">
            {error}
          </Alert>
        ))}
      </DialogContent>
    </Dialog>
  );
}
