import { StorageAccessLevel } from "@aws-amplify/storage";
import {
  Check as CheckIcon,
  Error as ErrorIcon,
  PhoneForwarded as PhoneForwardedIcon,
  SaveAlt as SaveAltIcon,
  Warning as WarningIcon,
} from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { DataGrid, GridSortModel } from "@mui/x-data-grid";
import { Storage } from "aws-amplify";
import { format } from "date-fns";
import { orderBy } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ErrorsDialog, WarningsDialog } from ".";
import { StateContext } from "../../contexts";
import { CallListItem } from "../../types";

export default function CallListsTable() {
  const { callLists } = useContext(StateContext);
  const history = useHistory();
  const { contactFlowId } = useParams<{ contactFlowId: string }>();
  const [errors, setErrors] = useState([]);
  const [items, setItems] = useState<CallListItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [openErrorsDialog, setOpenErrorsDialog] = useState(false);
  const [openWarningsDialog, setOpenWarningsDialog] = useState(false);
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: "createdAt", sort: "desc" },
  ]);
  const [warnings, setWarnings] = useState([]);

  useEffect(() => {
    (async () => {
      setLoading(true);

      if (contactFlowId) {
        setItems(
          orderBy(
            callLists.filter(
              (callList) => callList.contactFlowId === contactFlowId
            ),
            sortModel[0].field,
            sortModel[0].sort as "asc" | "desc"
          )
        );
      } else {
        setItems(callLists);
      }

      setLoading(false);
    })();
  }, [callLists, contactFlowId, sortModel]);

  const handleCloseWarningsDialog = () => {
    setWarnings([]);
    setOpenWarningsDialog(false);
  };

  const handleCloseErrorsDialog = () => {
    setErrors([]);
    setOpenErrorsDialog(false);
  };

  const handleOpenWarningsDialog = (warnings: string) => {
    setWarnings(JSON.parse(warnings));
    setOpenWarningsDialog(true);
  };

  const handleOpenErrorsDialog = (errors: string) => {
    setErrors(JSON.parse(errors));
    setOpenErrorsDialog(true);
  };

  const handleSortModelChange = (newSortModel: GridSortModel) => {
    if (JSON.stringify(newSortModel) !== JSON.stringify(sortModel)) {
      setSortModel(newSortModel);
    }
  };

  return (
    <>
      <DataGrid
        autoPageSize
        columns={[
          {
            field: "createdAt",
            headerName: "Created at",
            renderCell: ({ value }) =>
              format(new Date(value), "dd/MM/yyyy HH:mm"),
            width: 156,
          },
          {
            field: "filename",
            flex: 1,
            headerName: "Filename",
          },
          {
            field: "createdBy",
            flex: 1,
            headerName: "Created by",
          },
          {
            field: "processContacts",
            headerName: "Processing calls",
            renderCell: ({ value }) => value && <CheckIcon />,
            width: 148,
          },
          {
            field: "actions",
            headerName: "Actions",
            renderCell: ({ row: { errors, file, id, warnings } }) => (
              <>
                <Tooltip title="View calls">
                  <IconButton
                    onClick={() =>
                      contactFlowId
                        ? history.push(
                            `/contactFlows/${contactFlowId}/callLists/${id}`
                          )
                        : history.push(`/callLists/${id}`)
                    }
                    size="large"
                  >
                    <PhoneForwardedIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Download original file">
                  <IconButton
                    onClick={() => {
                      const key = file.key.split("/");
                      Storage.get(key[2], {
                        level: key[0] as StorageAccessLevel,
                        identityId: key[1],
                      })
                        .then((result) => window.open(result as string))
                        .catch((error) => console.error(error)); // TODO: Display error to user.
                    }}
                    size="large"
                  >
                    <SaveAltIcon />
                  </IconButton>
                </Tooltip>
                {warnings !== "[]" && (
                  <Tooltip title="View warnings">
                    <IconButton
                      onClick={() => handleOpenWarningsDialog(warnings)}
                      size="large"
                    >
                      <WarningIcon />
                    </IconButton>
                  </Tooltip>
                )}
                {errors !== "[]" && (
                  <Tooltip title="View errors">
                    <IconButton
                      onClick={() => handleOpenErrorsDialog(errors)}
                      size="large"
                    >
                      <ErrorIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            ),
            sortable: false,
            width: 238,
          },
        ]}
        disableSelectionOnClick
        loading={loading}
        onSortModelChange={handleSortModelChange}
        pagination
        rows={items}
        rowsPerPageOptions={[25, 50, 100]}
        sortingOrder={["asc", "desc"]}
        sortModel={sortModel}
      />
      <WarningsDialog
        warnings={warnings}
        onClose={handleCloseWarningsDialog}
        open={openWarningsDialog}
      />
      <ErrorsDialog
        errors={errors}
        onClose={handleCloseErrorsDialog}
        open={openErrorsDialog}
      />
    </>
  );
}
