import { Alert, Box, Grid } from "@mui/material";
import { sortBy } from "lodash";
import React, { useContext, useMemo } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { StateContext } from "../../contexts";
import { isCallProfile, isWebformProfile } from "../../types";
import { ContactFlowCard } from "./contact-flow-card";

export default function ContactFlows() {
  const { contactFlows } = useContext(StateContext);
  const { pathname } = useLocation();

  const profiles = useMemo(() => {
    return sortBy(
      contactFlows.filter(
        (contactFlow) =>
          !contactFlow.deleted &&
          (matchPath(pathname, "/contactFlows")
            ? isCallProfile(contactFlow)
            : isWebformProfile(contactFlow))
      ),
      "name"
    );
  }, [contactFlows, pathname]);

  return (
    <Box sx={{ p: 3, pt: 5 }}>
      {profiles.length ? (
        <Grid container spacing={2}>
          {profiles?.map((contactFlow, index) => (
            <Grid item key={index} xs="auto">
              <ContactFlowCard contactFlow={contactFlow} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Alert severity="info">
          A profile defines the customer experience with your contact center
          from start to finish. To create custom profiles for your specific
          scenario, click the button in the top left of the screen.
        </Alert>
      )}
    </Box>
  );
}
