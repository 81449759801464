import { gql, useMutation } from "@apollo/client";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Button, Dialog, DialogActions, Grid } from "@mui/material";
import { Form } from "formik";
import React from "react";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { LIST_CONTACT_FLOW_NAMES } from "../../..";
import { DialogContent, DialogTitle } from "../../../../components/dialog";
import { Alert, Formik } from "../../../../components/formik";
import { TextFieldFormik } from "../../../../components/inputs";
import { DAYS } from "../../../../constants";
import { client } from "../../../../contexts";
import { createContactFlow as CREATE_CONTACT_FLOW } from "../../../../graphql/mutations";
import {
  ContactFlowTypes,
  ModuleTypes,
  WebformProfileItem
} from "../../../../types";
import FromTo from "../create-contact-flow-dialog/FromTo";

export const LIST_CONTACT_FLOW_BY_NAME = gql`
  query ListContactFlowsByName(
    $name: String!
    $limit: Int
    $nextToken: String
  ) {
    listContactFlows(
      filter: {
        deleted: { eq: false }
        name: { eq: $name }
        type: { eq: WEBFORM }
      }
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        name
      }
      nextToken
    }
  }
`;

const validationSchema: yup.Schema<
  Pick<WebformProfileItem, "name" | "description">
> = yup
  .object({
    name: yup
      .string()
      .required("Required")
      .test("is-unique", "Must be unique", async (name) => {
        if (!name) return false;
        const {
          data: {
            listContactFlows: { items },
          },
        } = await client.query({
          query: LIST_CONTACT_FLOW_BY_NAME,
          variables: { name },
        });
        return items.length === 0;
      }),
    description: yup.string(),
    hours: yup.object().shape({
      sunday: yup.object().shape({
        from: yup.string().nullable(),
        to: yup.string().nullable(),
      }),
      monday: yup.object().shape({
        from: yup.string().nullable(),
        to: yup.string().nullable(),
      }),
      tuesday: yup.object().shape({
        from: yup.string().nullable(),
        to: yup.string().nullable(),
      }),
      wednesday: yup.object().shape({
        from: yup.string().nullable(),
        to: yup.string().nullable(),
      }),
      thursday: yup.object().shape({
        from: yup.string().nullable(),
        to: yup.string().nullable(),
      }),
      friday: yup.object().shape({
        from: yup.string().nullable(),
        to: yup.string().nullable(),
      }),
      saturday: yup.object().shape({
        from: yup.string().nullable(),
        to: yup.string().nullable(),
      }),
    }),
  })
  .required("Required");

const initialStatus = {
  submitted: false,
  error: null,
};

type CreateContactFlowDialogProps = {
  onClose: () => void;
  open: boolean;
};

export default function CreateWebformDialog({
  open,
  onClose,
}: CreateContactFlowDialogProps) {
  const { user } = useAuthenticator();
  const [createContactFlow] = useMutation(gql(CREATE_CONTACT_FLOW), {
    refetchQueries: [LIST_CONTACT_FLOW_NAMES],
    awaitRefetchQueries: true,
  });
  const history = useHistory();

  return (
    <Formik
      validationSchema={validationSchema}
      initialStatus={initialStatus}
      initialValues={{
        name: "",
        description: "",
        hours: {
          sunday: {
            from: null,
            to: null,
          },
          monday: {
            from: "09:00",
            to: "17:00",
          },
          tuesday: {
            from: "09:00",
            to: "17:00",
          },
          wednesday: {
            from: "09:00",
            to: "17:00",
          },
          thursday: {
            from: "09:00",
            to: "17:00",
          },
          friday: {
            from: "09:00",
            to: "17:00",
          },
          saturday: {
            from: null,
            to: null,
          },
        },
      }}
      onSubmit={async (values, { setStatus, resetForm }) => {
        setStatus(initialStatus);
        try {
          const {
            data: {
              createContactFlow: { id },
            },
          } = await createContactFlow({
            variables: {
              input: {
                ...values,
                type: ContactFlowTypes.WEBFORM,
                website: {
                  before: "[]",
                  during: JSON.stringify([
                    {
                      id: "1",
                      type: ModuleTypes.End,
                    },
                  ]),
                  after: "[]",
                },
                paused: false,
                createdBy: user.attributes?.name,
                updatedBy: user.attributes?.name,
                deleted: false,
              },
            },
          });
          setStatus({ submitted: true, error: null });
          if (id) history.push(`/webformProfiles/${id}`);
        } catch (err) {
          console.error(err);
          setStatus({ submitted: true, error: err });
        }
        resetForm();
        onClose();
      }}
    >
      {({ values, handleReset, setFieldValue }) => {
        const handleClose = () => {
          handleReset();
          onClose();
        };

        return (
          <>
            <Dialog fullWidth maxWidth="sm" onClose={handleClose} open={open}>
              <Form>
                <DialogTitle onClose={handleClose}>
                  Add webform profile
                </DialogTitle>
                <DialogContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextFieldFormik label="Name" name="name" />
                    </Grid>
                    <Grid item xs={12}>
                      <TextFieldFormik
                        label="Description"
                        multiline
                        name="description"
                      />
                    </Grid>
                    <Grid container item spacing={2} xs={12}>
                      {DAYS.map((day, index) => (
                        <FromTo
                          key={index}
                          day={day}
                          from={values.hours[day]?.from}
                          to={values.hours[day]?.to}
                        />
                      ))}
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} type="button">
                    Cancel
                  </Button>
                  <Button color="primary" type="submit" variant="contained">
                    Create webform profile
                  </Button>
                </DialogActions>
              </Form>
            </Dialog>
            <Alert
              successMessage="Webform profile created successfully"
              errorMessage="Unable to create webform profile"
            />
          </>
        );
      }}
    </Formik>
  );
}
