import { Box } from "@mui/material";
import React, { useContext, useMemo } from "react";
import { matchPath, useLocation, useParams } from "react-router-dom";
import { ContactFlowModulesForm } from ".";
import { StateContext } from "../../../contexts";
import { isCallProfile } from "../../../types";

export default function ContactFlowModules(): JSX.Element {
  const { pathname } = useLocation();
  const { contactFlowId } = useParams<{ contactFlowId: string }>();
  const { contactFlows } = useContext(StateContext);

  const contactFlow = useMemo(
    () => {
        return contactFlows.find((contactFlow) => {
            return contactFlow.id === contactFlowId;
        })
    },
    [contactFlowId, contactFlows]
  );

  if (contactFlow && isCallProfile(contactFlow)) {
    return (
      <Box sx={{ p: 3, pt: 5 }}>
        <ContactFlowModulesForm
          modules={
            matchPath(pathname, {
              path: "/contactFlows/:contactFlowId/inbound",
            })
              ? contactFlow.inbound
              : contactFlow.outbound
          }
          voiceId={contactFlow.voice.id}
        />
      </Box>
    );
  } else if (contactFlow) {
    return (
      <Box sx={{ p: 3, pt: 5 }}>
        <ContactFlowModulesForm modules={contactFlow.website} />
      </Box>
    );
  }

  return <></>;
}
