import { Hub } from "@aws-amplify/core";
import { useAuthenticator } from "@aws-amplify/ui-react";
import MuiAvatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Auth } from "aws-amplify";
import React, { useCallback, useEffect, useState } from "react";

const UI_AUTH_CHANNEL = "UI Auth";
const AUTH_STATE_CHANGE_EVENT = "AuthStateChange";

const dispatchAuthStateChangeEvent = (nextAuthState: string) => {
  Hub.dispatch(UI_AUTH_CHANNEL, {
    event: AUTH_STATE_CHANGE_EVENT,
    message: nextAuthState,
  });
};

const Avatar = () => {
  const { signOut } = useAuthenticator();
  const [anchorEl, setAnchorEl] = useState(null);
  const [name, setName] = useState();

  const handleClick = useCallback(
    (event) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleSignOutClick = async () => {
    await signOut();
    dispatchAuthStateChangeEvent("signedout");
  };

  useEffect(() => {
    const fetchName = async () => {
      const info = await Auth.currentUserInfo();
      if (info?.attributes?.name) {
        setName(info?.attributes?.name);
      }
    };

    fetchName();
  }, []);

  return (
    <>
      {name && <span>{name}</span>}
      <IconButton size="small" onClick={handleClick}>
        <MuiAvatar />
      </IconButton>
      <Menu
        open={anchorEl !== null}
        anchorEl={anchorEl}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleSignOutClick}>Sign out</MenuItem>
      </Menu>
    </>
  );
};

export default Avatar;
