import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
  SelectChangeEvent,
} from "@mui/material";
import { useField } from "formik";
import React from "react";
import { Intents } from "../../types";

type SelectProps = MuiSelectProps & {
  helperText?: string;
};

export default function Select({
  error,
  label,
  children,
  helperText,
  ...props
}: SelectProps) {
  return (
    <FormControl error={error} size={props.size} fullWidth variant="outlined">
      <InputLabel>{label}</InputLabel>
      <MuiSelect label={label} {...props}>
        {children}
      </MuiSelect>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

type SelectFormikProps = SelectProps & {
  name: string;
  setHasEditValidation?: React.Dispatch<React.SetStateAction<boolean>>;
};

export function SelectFormik(props: SelectFormikProps) {
  const [field, { error }] = useField(props.name);
  const { setHasEditValidation, ...restProps } = props;

  React.useEffect(() => {
    if (setHasEditValidation) {
      setHasEditValidation(field.value === Intents.OpenEnded);
    }
  }, [setHasEditValidation, field.value]);

  const handleSelectChange = (event: SelectChangeEvent<unknown>) => {
    const value = event.target.value;
    if (setHasEditValidation) {
      setHasEditValidation(value === Intents.OpenEnded);
    }
    props.onChange?.(event, field);
    field.onChange(event);
  };
  return (
    <Select
      {...restProps}
      {...field}
      error={Boolean(error)}
      helperText={error}
      label={props.required ? `${props.label} *` : props.label}
      onChange={handleSelectChange}
      value={field.value ?? ""}
    />
  );
}
