import { CellTower as CellTowerIcon } from "@mui/icons-material";
import React from "react";
import { Route, RouteProps, Switch } from "react-router-dom";
import SmsSendersCreate from "./SmsSendersCreate";
import SmsSendersEdit from "./SmsSendersEdit";
import SmsSendersPage from "./SmsSendersPage";

export const SmsSendersConfig = {
  title: 'Sms Senders',
  icon: <CellTowerIcon />,
  path: '/sms-senders'
}

export const SmsSendersRoutes = () => {
  const routes: RouteProps[] = [
    {
      component: SmsSendersCreate,
      path: [`${SmsSendersConfig.path}/create`],
    },
    {
      component: SmsSendersEdit,
      path: [`${SmsSendersConfig.path}/:smsSenderId`],
    },
    {
      component: SmsSendersPage,
      path: SmsSendersConfig.path,
    },
  ];

  return (
    <Switch>
      {routes.map(({ component, path }, index) => (
        <Route component={component} key={index} path={path} />
      ))}
    </Switch>
  );
}
