import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useContext } from "react";
import { StateContext } from "../../../contexts";

interface MetricsTableProps {
  metrics?: {
    sms?: {
      [contactFlowId: string]: {
        pending: number;
        sent: number;
        sentCustomers: number;
        responded: number;
        failed: number;
      }
    };
  };
}

export const SmsMetricsTable = ({ metrics }: MetricsTableProps) => {
  const { contactFlows } = useContext(StateContext);
  const findContactFlow = (id: string) => contactFlows.find((contactFlow) => {
    return contactFlow.id === id;
  });

  return (
    <Table aria-label="SMS Metrics" sx={{ width: "100%" }}>
      <TableHead>
        <TableRow>
          <TableCell align="left" sx={{ fontWeight: "bold" }}>WebProfile</TableCell>
          <TableCell align="right" sx={{ fontWeight: "bold" }}>Sent Customers</TableCell>
          <TableCell align="right" sx={{ fontWeight: "bold" }}>Pending</TableCell>
          <TableCell align="right" sx={{ fontWeight: "bold" }}>Sent</TableCell>
          <TableCell align="right" sx={{ fontWeight: "bold" }}>Responded</TableCell>
          <TableCell align="right" sx={{ fontWeight: "bold" }}>Failed</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {metrics?.sms && Object.keys(metrics?.sms).map((contactFlowId) => (
          <TableRow key={contactFlowId}>
            <TableCell component="th" scope="row">
              <a href={`/webformProfiles/${contactFlowId}`}>
                {findContactFlow(contactFlowId)?.name || 'UNKNOWN'}
              </a>
            </TableCell>
            <TableCell align="right">{metrics.sms![contactFlowId]?.sentCustomers}</TableCell>
            <TableCell align="right">{metrics.sms![contactFlowId]?.pending}</TableCell>
            <TableCell align="right">{metrics.sms![contactFlowId]?.sent}</TableCell>
            <TableCell align="right">{metrics.sms![contactFlowId]?.responded}</TableCell>
            <TableCell align="right">{metrics.sms![contactFlowId]?.failed}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
