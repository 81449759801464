import React from "react";
import { BrowserRouter } from "react-router-dom";
import { RequiresAuthorisation } from "./components/auth";
import {
  AudioPlayerProvider,
  AuthProvider,
  AWSAppSyncClientProvider,
  ContactCrmProvider,
  MUIProvider,
  StateProvider,
} from "./contexts";
import AmplifyProvider from "./contexts/AmplifyProvider";
import { View } from "./view";

export default function App() {
  return (
    <BrowserRouter>
      <MUIProvider>
        <AmplifyProvider>
          <AuthProvider>
            <RequiresAuthorisation groups={["Autonomous_Surveys"]}>
              <AudioPlayerProvider>
                <AWSAppSyncClientProvider>
                  <StateProvider>
                    <ContactCrmProvider>
                      <View />
                    </ContactCrmProvider>
                  </StateProvider>
                </AWSAppSyncClientProvider>
              </AudioPlayerProvider>
            </RequiresAuthorisation>
          </AuthProvider>
        </AmplifyProvider>
      </MUIProvider>
    </BrowserRouter>
  );
}
